import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter, Outlet, Navigate } from "react-router-dom";

//Modulos
import Modulos from "../modules";

//Suprimentos
import CentralTarefas from "../modules/suprimentos/pages/CentralTarefas";
import Usuarios from "../modules/suprimentos/pages/Usuarios";
import Login from "../modules/suprimentos/pages/Login";
import RecuperarSenha from "../modules/suprimentos/pages/RecuperarSenha";
import FluxoAprovacao from "../modules/suprimentos/pages/FluxoAprovacao";
import Solicitacao from "../modules/suprimentos/pages/Solicitacao";
import Compras from "../modules/suprimentos/pages/Compras";
import AprovacaoCompras from "../modules/suprimentos/pages/AprovacaoCompras";
import Workflow from "../modules/suprimentos/pages/Workflow";
import AcompanhamentoItens from "../modules/suprimentos/pages/AcompanhamentoItens";
import LeadTime from "../modules/suprimentos/pages/LeadTime";
import CentralContratos from "../modules/suprimentos/pages/CentralContratos";
import ContratoOC from "../modules/suprimentos/pages/ContratoOC";
import LaudoReformaPneu from "../modules/suprimentos/pages/relatoriosChecagem/laudoReformaPneu";
import LaudoAvariaMotor from "../modules/suprimentos/pages/relatoriosChecagem/laudoAvariaMotor";
import InspecaoMaterialRodante from "../modules/suprimentos/pages/relatoriosChecagem/inspecaoMaterialRodante";
//Diligenciamento
import Painel from "../modules/diligenciamento/pages/painel";
import Dashboard from "../modules/diligenciamento/pages/dashboard";

//follow Up
import CadastroFollowUp from "../modules/followUp/pages/cadastroFollowUp";
import FollowUp from "../modules/followUp/pages/followUp";
import TemposDiligenciamento from "../modules/followUp/pages/temposDiligenciamento";

//cmi
import FormularioProcesso from "../modules/cmi/formularioProcesso";

import { api } from "../services/api";
import { toast } from "react-toastify";
import CentralComprasFinanceiro from "../modules/suprimentos/pages/CentralComprasFinanceiro";
import CentralTransferenciaMaterial from "../modules/suprimentos/pages/CentralTransferenciaMaterial";
import AprovacaoTransferenciaMaterial from "../modules/suprimentos/pages/AprovacaoTransferenciaMaterial";

const PrivateRoutes = () => {
    const [auth, setAuth] = useState({ token: null, loading: true });

    useEffect(() => {
        verificarToken();
    }, []);

    const verificarToken = async () => {
        try {
            const sessao = JSON.parse(localStorage.getItem('usuario'));
            const response = await api.get('/check', {
                headers: {
                    'Authorization': 'Bearer ' + sessao.token
                }
            });
            setAuth({ token: response.data.status == 200, loading: false });
            return response.data.status == 200;
        } catch (error) {
            setAuth({ token: null, loading: false });
        }
    };

    if (auth.loading) {
        toast.loading("Validando sua sessão...", { toastId: "toast" });
        return;
    }

    toast.dismiss();
    setTimeout(() => {
        if (auth.token) {
            toast.success("Sessão validada com sucesso", { toastId: "toast2" });
        }
        else {
            toast.warning("Sessão inválida ou expirada, faça login novamente", { toastId: "toast2" });
        }
    }, 800);

    return (
        auth.token ? <Outlet /> : <Navigate to="/" />
    );
}

const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>

                {/* Rotas Públicas */}

                {/* Modulos */}
                <Route path="/modulos" element={<Modulos />} exact />

                {/* Suprimentos */}
                <Route path="/login" element={<Login />} exact />
                <Route path="/alterarSenha/:id_param" element={<RecuperarSenha />} exact />
                <Route path="/*" element={<Login />} />
                <Route path="/" element={<Login />} exact />

                {/* Rotas privadas */}
                <Route element={<PrivateRoutes />}>

                    {/* Suprimentos */}
                    <Route path="/centralTarefas" element={<CentralTarefas />} exact />
                    <Route path="/usuarios" element={<Usuarios />} exact />
                    <Route path="/fluxoAprovacao" element={<FluxoAprovacao />} exact />
                    <Route path="/solicitacao" element={<Solicitacao />} exact />
                    <Route path="/solicitacao/:id_param" element={<Solicitacao />} exact />
                    <Route path="/compras" element={<Compras />} exact />
                    <Route path="/aprovacaoCompras" element={<AprovacaoCompras />} exact />
                    <Route path="/aprovacaoCompras/:id_param" element={<AprovacaoCompras />} exact />
                    <Route path="/workflow" element={<Workflow />} exact />
                    <Route path="/acompanhamentoItens/:processo" element={<AcompanhamentoItens />} exact />
                    <Route path="/leadTime" element={<LeadTime />} exact />
                    <Route path="/centralContratos" element={<CentralContratos />} exact />
                    <Route path="/contratos" element={<ContratoOC />} exact />
                    <Route path="/contratos/:id_param" element={<ContratoOC />} exact />
                    <Route path="/comprasFinanceiro" element={<CentralComprasFinanceiro />} exact />
                    <Route path="/centralTransferencia" element={<CentralTransferenciaMaterial />} exact />
                    <Route path="/aprovacaoTransferencia" element={<AprovacaoTransferenciaMaterial />} exact />
                    <Route path="/aprovacaoTransferencia/:id_param" element={<AprovacaoTransferenciaMaterial />} exact />
                    <Route path="/laudoReformaPneu" element={<LaudoReformaPneu />} exact />
                    <Route path="/laudoAvariaMotor" element={<LaudoAvariaMotor />} exact />
                    <Route path="/inspecaoMaterialRodante" element={<InspecaoMaterialRodante />} exact />

                    {/* Follow up */}
                    <Route path="/followUp" element={<FollowUp />} exact />
                    <Route path="/cadastrofollowUp" element={<CadastroFollowUp />} exact />
                    <Route path="/cadastrofollowUp/:id_param/:num_1108" element={<CadastroFollowUp />} exact />
                    <Route path="/temposDiligenciamento" element={<TemposDiligenciamento />} exact />
                    
                    {/* Diligenciamento */}
                    <Route path="/diligenciamento" element={<Painel />} exact />
                    <Route path="/dashboard" element={<Dashboard />} exact />

                    {/* CMI */}
                    <Route path="/cmi" element={<FormularioProcesso />} exact />

                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Rotas;