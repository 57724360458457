import axios from "axios";
import { useState, useEffect } from "react";

//Componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import InformacoesItem from "../../../../components/InformacoesItem";
import MovimentacoesItem from "../../../../components/MovimentacoesItem";
import Anexos from "../../../../components/Anexos";
import SelecaoItem from "../../../../components/SelecaoItem";
import SelecaoFornecedor from "../../../../components/SelecaoFornecedor";
import SelecaoClassificacaoRD from "../../../../components/SelecaoClassificacaoRD";
import ConfirmarExclusao from "../../../../components/ConfirmarExclusao";
import ModalAvisos from "../../../../components/modalAvisos";
import DadosTransferenciaMaterial from "../../../../components/DadosTransferenciaMaterial";
import LaudoAvariaMotor from "../relatoriosChecagem/laudoAvariaMotor";
import LaudoReformaPneu from "../relatoriosChecagem/laudoReformaPneu";
import InspecaoMaterialRodante from "../relatoriosChecagem/inspecaoMaterialRodante";

//Utils
import { obterProximaEtapa, obterTodasEtapas } from "../../../../utils/etapasDoProcesso";
import { dataAtual } from "../../../../utils/datas";
import { definirProximaEtapa, obterEtapasAnteriores } from "../../../../utils/etapasDoProcesso";

import { Stack, Typography, Button, Select, Option, Input, FormLabel, Table, ToggleButtonGroup, Link, Checkbox, Textarea, Sheet, Switch, FormHelperText } from "@mui/joy";

import { toast } from 'react-toastify';

//icones react
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen, FaInfoCircle, FaCheck } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { IoEye, IoEyeOff } from "react-icons/io5";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Apis do RM
import { RM, api } from '../../../../services/api';

import { useNavigate, useLocation, useParams } from "react-router-dom";

import Autocomplete from '@mui/joy/Autocomplete';
import realizaConsulta from "../../../../services/rm";

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let desabilitaAprovadores = true;
let desabilitarEtapasAprovacao = true;

let laudoAvariaMotorRequired = false;
let laudoReformaPneuRequired = false;

export default function Solicitacao(props) {

  document.addEventListener("keydown", function (event) {
    // Verifique se a tecla pressionada é Enter (código 13)
    if (event.keyCode === 13) {
      // Chame a sua função desejada aqui
      event.preventDefault();
    }
  });

  const navigate = useNavigate(); //navegacao entre components
  const { id_param } = useParams(); // id da solicitacao passada por url
  const mobile = useMediaQuery('(max-width:1200px)'); //Telas menores que 1200px de largura serão considerados mobile

  const [valueTogglePagina, setValueTogglePagina] = useState("formulario"); //toggle que muda para aba formulario/anexo
  const [openModal, setOpenModal] = useState(false); //Modal de informar qtd a solicitar do item pedido
  const [modalMovimentacoesItem, setModalMovimentacoesItem] = useState(false);
  const [openModalListaItem, setOpenModalListaItem] = useState(false); //Modal para exibir lista de itens quando pesquisar por descrição
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [anexos, setAnexos] = useState([]);
  const [uploadAnexos, setUploadAnexos] = useState(new FormData());
  const [anexosSelecionados, setAnexosSelecionados] = useState(null); // guarda arquivos selecionados da aba anexos, para usuario nao perder os pré-selecinados antes de enviar
  const [decisaoAprovacao, setDecisaoAprovacao] = useState("");
  const [numeroOS, setNumeroOS] = useState("");
  const [codLocSelectOpen, setCodLocSelectOpen] = useState(false);
  const [osVinculada, setOsVinculada] = useState(false);
  const [formulario, setFormulario] = useState({
    tipoPedido: "",
    itens: [],
    coligada: "1",
    filial: "1",
    localEstoque: "",
    centroCusto: "",
    departamento: "01",
    departamentos: [],
    dataSolicitacao: dataAtual(),
    criterio: "NO",
    tipoPedido: "",
    informacoesAdicionais: "",
    motivoReprovacao: "",
    motivoCancelamento: "",
    etapaAtual: "Solicitante",
    numeroMov1: "",
    numeroPms: "",
    numeroMov2: "",
    numeroMov3: "",
    numeroMov4: "",
    numeroMov21: "",
    movimento1103: "",
    movimento1104: "",
    movimento1121: "",
    vendorlist: "",
    contratoTipoContrato: "",
    contratoNome: "",
    contratoDataIni: "",
    contratoDataFim: "",
    contratoClassRD: "",
    contratoFornecedor: "",
    pedidoContrato: "",
    transferencia: false,
    filialOrigem: "",
    localEstoqueOrigem: "",
    observacoesTransferencia: "",
    observacoesFornecedores: "",
    horimetro: ""
  });
  const [indiceItemConsultado, setIndiceItemConsultado] = useState(0);
  const [nomeCodigoNovoItem, setNomeCodigoNovoItem] = useState("");
  const [itensEncontradosBusca, setItensEncontradosBusca] = useState([]);
  const [itemTemporario, setItemTemporario] = useState({});
  const [dialogExclusao, setDialogExclusao] = useState(false);
  const [modalAvisos, setModalAvisos] = useState(false);
  const [atualizarEstados, setAtualizarEstados] = useState(false);
  const [etapaAtual, setEtapaAtual] = useState("Solicitante");
  const [numeroPms, setNumeroPms] = useState("");
  const [permissaoAprovador, setPermissaoAprovador] = useState(false);
  const [informacoesGerais, setInformacoesGerais] = useState({ coligadas: [], filiais: [], locaisDeEstoque: [], centrosDeCusto: [] })
  const [etapasAnteriores, setEtapasAnteriores] = useState([]);
  const [etapaARetornar, setEtapaARetornar] = useState("");
  const [erroEvoluir, setErroEvoluir] = useState(false);
  const [renderizar, setRenderizar] = useState(false);
  const [listaLeadTimePedidos, setListaLeadTimePedidos] = useState([]);
  const [enviarDisabled, setEnviarDisabled] = useState(false);
  const [verItensSomenteBaixa, setVerItensSomenteBaixa] = useState(false); // para ver itens que nao aparece pro aprov. gerencial/institucional.

  const [nomeFornecedorBuscado, setNomeFornecedorBuscado] = useState();
  const [fornecedoresSelecionados, setFornecedoresSelecionados] = useState([]);
  const [fornecedoresResultBusca, setFornecedoresResultBusca] = useState([]);
  const [openModalListaFornecedor, setOpenModalListaFornecedor] = useState(false);
  const [itensParaFornecedor, setItensParaFornecedor] = useState([]);
  const [mostrarCamposPedidoFornecedor, setMostrarCamposPedidoFornecedor] = useState(false);

  const [nomeClassificacaoRDBuscado, setNomeClassificacaoRDBuscado] = useState([]);
  const [classificacaoRDSelecionadas, setClassificacaoRDSelecionadas] = useState([]);
  const [classificacaoRDResultBusca, setClassificacaoRDResultBusca] = useState([]);
  const [openModalListaClassificacaoRD, setOpenModalListaClassificacaoRD] = useState(false);
  const [classificacaoRDVinculada, setClassificacaoRDVinculada] = useState(false);

  const [compradoresDisponiveis, setCompradoresDisponiveis] = useState([]);
  const [compradorSelected, setCompradorSelected] = useState(null);

  const [podeMovimentarParaCancelar, setPodeMovimentarParaCancelar] = useState(false);//permite ao solicitante cancelar seu pedido

  //variaveis dos laudos de checagem:
  const [laudoAvariaMotor, setLaudoAvariaMotor] = useState(null);
  const [laudoAvariaMotorTemp, setLaudoAvariaMotorTemp] = useState(null);

  const [laudoReformaPneuTemp, setLaudoReformaPneuTemp] = useState(null);
  const [listaLaudoReformaPneu, setListaLaudoReformaPneu] = useState(null);
  const [listaLaudoReformaPneuTemp, setListaLaudoReformaPneuTemp] = useState(null);

  const [inspecaoMaterialRodanteTemp, setInspecaoMaterialRodanteTemp] = useState(null);
  const [listaInspecaoMaterialRodante, setListaInspecaoMaterialRodante] = useState(null);
  const [listaInspecaoMaterialRodanteTemp, setListaInspecaoMaterialRodanteTemp] = useState(null);
  const [componentesMaterialRodanteTemp, setComponentesMaterialRodanteTemp] = useState([]);

  // const [laudoAvariaMotorRequired, setLaudoAvariaMotorRequired] = useState(false);
  // const [laudoReformaPneuRequired, setLaudoReformaPneuRequired] = useState(false);

  useEffect(() => {

    //Obtendo o token
    if (JSON.parse(localStorage.getItem("usuario")) != null) {
      token = JSON.parse(localStorage.getItem('usuario')).token;
      usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
      usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
    }
    //exibe modal de avisos se for criar novo pedido:
    if (!id_param){
      setModalAvisos(true);
    }

    if (numeroProcesso == "") {
      obterLocalEstoqueECentroCusto();
      obterDepartamentos();
      obterLeadTimeTiposPedidos();
    }
    else {
      //Obtendo departamentos
      obterDepartamentos();
    }
    buscarComprador();

    const delayRenderizacao = setTimeout(() => {
      setRenderizar(true);
    }, 2000);
    //resetando variaveis de laudos obrigatorios:
    laudoAvariaMotorRequired = false;
    laudoReformaPneuRequired = false
    //Aqui um trecho de cleanup, que serve para limpar variaveis de sessão e recursos assim que este componente for desmontado:
    return () => {
      localStorage.removeItem('qtdArquivosSelecionados');
      localStorage.removeItem('nomesArquivosSelecionados');
      clearTimeout(delayRenderizacao);
    };
  }, []);

  //useEffect para calcular criterio de atendimento:
  // useEffect(() => {
  //   determinaCriterioAtendimento();
  // }, [formulario.tipoPedido, formulario.dataNecessidade]);

  useEffect(() => {
    if (formulario.filial) {
        obterLocalEstoqueECentroCusto(formulario.filial);
    }
  }, [formulario.filial]);

  //UseEffect que analisa se preciso exibir o modal de avisos para a etapaAtual (aprovador técnico):
  useEffect(() => {
    if(etapaAtual == "Aprov. Técnico"){
      setModalAvisos(true);
    }
  },[etapaAtual]);

  const obterLocalEstoqueECentroCusto = (filial = -1) => {
    if (filial == -1){
      filial = formulario.filial;
    }
    const usuario = JSON.parse(localStorage.getItem('usuario')).user.login;
    let id_toast = toast.loading("Obtendo informações do RM...", { toastId: "toast" });

    let urlLocalEstoque = "";
    if (id_param && etapaAtual != "Solicitante"){
      //se id da solicitacao for passado, envia o id para a url:
      urlLocalEstoque = `/locaisEstoque?login=${usuario}&coligada=${formulario.coligada}&filial=${filial}&solicitacao=${id_param}`;
    } else {
      urlLocalEstoque = `/locaisEstoque?login=${usuario}&coligada=${formulario.coligada}&filial=${filial}&solicitacao=`;
    }

    api.get(urlLocalEstoque, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }
    ).then(function ({ data }) {

      let listaCentrosDeCusto = [];
      let listaLocaisDeEstoque = [];

      //Definindo opções de coligadas, filiais, locais de estoque e centros de custo
      informacoesGerais.coligadas = [
        "1 - Pelicano Construções - ES",
        // "2 - RAF Empreendimentos",
        // "3 - Unipel Engenharia",
        // "4 - Consórcio TAP",
        // "5 - Flávia Furtado Ribeiro",
        // "6 - Fernando de Sousa Ribeiro",
        // "7 - Tencol Engenharia LTDA",
        // "8 - Consórcio Contractor / Pelicano / Sulcatarinense / Enecon",
        // "9 - Exotic Importação, exportação, de marmores e granitos Eireli",
        // "10 - Consórcio Pelicano / monteiro de castro"
      ]

      informacoesGerais.filiais = [
        "1 - Pelicano Construcoes - ES",
        "2 - Pelicano Construcoes - MG",
        "4 - Pelicano Construcoes - MA",
        "5 - Pelicano Construcoes - PA",
        "7 - Pelicano Construcoes - SP",
        "100 - Pelicano Construcoes - GO",
        "101 - Pelicano Construcoes - MS",
        "102 - Pelicano Construcoes - SP"
      ]



      if (data.data.CENTROCUSTOS) {
        data.data.CENTROCUSTOS.forEach(centroDeCusto => {
          listaCentrosDeCusto.push({ codigo: centroDeCusto.CODCUSTO, nome: centroDeCusto.NOME });
        });
      }
      else {
        
        toast.update(id_toast, { render: "O RM não respondeu. Por favor, Atualize a página. Se o erro persistir, tente novamente mais tarde.", type: "warning", isLoading: false, closeOnClick: true });
        setTimeout(() => {
          // window.location.reload(true);
        }, 5000);
      }

      informacoesGerais.centrosDeCusto = listaCentrosDeCusto;

      if (data.data.LOCALESTOQUE) {
        data.data.LOCALESTOQUE.forEach(localDeEstoque => {
          listaLocaisDeEstoque.push({ codigo: localDeEstoque.localEstoque, nome: localDeEstoque.localEstoque + " - " + localDeEstoque.nomeLocal });
        });
      } else {
        
        toast.update(id_toast, { render: "O RM não respondeu. Por favor, Atualize a página. Se o erro persistir, tente novamente mais tarde.", type: "warning", isLoading: false, closeOnClick: true });
        setTimeout(() => {
          // window.location.reload(true);
        }, 5000);
      }

      //Atualizando locais de estoque em informacoes gerais
      informacoesGerais.locaisDeEstoque = listaLocaisDeEstoque;

      //Obtendo departamentos
      //obterDepartamentos();
      toast.update(id_toast, { render: "Dados carregados com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
    }).catch(function (err) {
      toast.error("Ocorreu um erro ao buscar locais de estoque e centros de custos, consulte o log");
      toast.error(JSON.stringify(err.response.data));
      console.error(err);
    })
  }

  const obterDepartamentos = () => {
    realizaConsulta('01.001.020', {
      params: {
        'COLIGADA': formulario.coligada,
        'FILIAL': formulario.filial
      }
    }
    )
      .then(({ data }) => {

        if (data) {
          setFormulario(prevState => ({
            ...prevState,
            departamentos: data,
          }));
        }

        obterDadosFormulario(data);
      }).catch((err) => {
        
        toast.error(JSON.stringify(err.response.data));
        toast.error("Erro ao obter departamentos");
      });
  }

  const obterLeadTimeTiposPedidos = () => {
    api.get(`/tiposPedido/`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then(({ data }) => {
        setListaLeadTimePedidos(data);
      }).catch((err) => {
        
        toast.error(JSON.stringify(err.response.data));
      });
  }

  const vincularOS = (formulario, itensMarcados = []) => {
    const numOS = formulario.numeroOs || numeroOS;
    let id;
    toast.loading("Buscando O.S", { toastId: 1 });
    if (formulario.localEstoque) {
      if (itensMarcados.length == 0) {
      }
      if (numOS.length > 0) {

        api.get(`/itensOS?COLIGADA=${formulario.coligada}&IDMOV=${numeroOS || formulario.numeroOs}&FILIAL=${formulario.filial}&CODLOC=${formulario.localEstoque}`)
          .then(({ data }) => {

            
            if (data.message != "Sucesso") {
              toast.dismiss();
              toast.warning(data.message);
              return;
            }
            if (data.data.length == 0) {
              toast.dismiss();
              toast.warning("Ordem de serviço não encontrada!")
              return;
            }

            toast.dismiss();

            if (data.data.length > 0 && informacoesGerais.locaisDeEstoque.length > 0) {

              //Verificando se o usuário tem acesso ao local de estoque e o centro de custo da O.S
              const centroCusto = informacoesGerais.centrosDeCusto.filter(centroDeCusto => centroDeCusto.codigo == data.data[0].CODCCUSTO);
              const localDeEstoque = informacoesGerais.locaisDeEstoque.filter(localDeEstoque => localDeEstoque.codigo == data.data[0].CODLOC);

              if (centroCusto.length == 0 || localDeEstoque.length == 0) {
                toast.dismiss();
                toast.warning("Você não tem permissão no local de estoque e/ou centro de custo desta O.S");
                formulario.numeroOs = "";
                return;
              }

              const dataAberturaOSFormatada = data.data[0].RECCREATEDON.split(" - ")[0].split("/")[2] + "-" + data.data[0].RECCREATEDON.split(" - ")[0].split("/")[1] + "-" + data.data[0].RECCREATEDON.split(" - ")[0].split("/")[0];
              let dadosItens = [];
              //Obtendo apenas os códigos do itens marcados em etapas anteriores
              let codigosItensMarcados = [];
              let idsItensMarcados = [];
              itensMarcados.forEach(item => {
                codigosItensMarcados.push(item.sequenciaOS);
                idsItensMarcados.push(item.id);
              });

              
              data.data.forEach((item, indice) => {
                dadosItens.push(
                  {
                    idPrd: item.IDPRD,
                    codProduto: item.CODIGOPRD,
                    descricao: item.NOMEFANTASIA,
                    quantidadeProduto: item.QUANTIDADEARECEBER,
                    QUANTIDADEARECEBER: item.QUANTIDADEARECEBER,
                    CODTMV: item.CODTMV, //Não tem mais, agora o nome é TMV
                    estoque: item.SALDO_FISICO,
                    saldoMin: item.SALDO_MIN,
                    saldoMax: item.SALDO_MAX,
                    CODUND: item.CODUND,
                    saldoContrato: item.SALDODISPCONT,
                    pedidoAFornecedor: item.PEDIDOF,
                    precoUntAlvo: item.APLICACAO1, //Não tem mais
                    idObjetoOficina: item.IDOBJOF,
                    observacoesItem: item.HISTORICO_ITEM, //Não tem mais
                    observacoesOS: item.HISTORICO_OS, //Não tem mais
                    N_SEQ_OS: item.N_SEQ_OS,
                    naoUsarEstoque: false,
                    naoUsarContrato: false,
                    tipoPedido: formulario.tipoPedido,
                    criterio: formulario.criterio,
                    marcado: codigosItensMarcados.indexOf(item.N_SEQ_OS) != -1,
                    movimentado: item.MOVIMENTADO,
                    tipo: item.TIPO,
                    slaContrato: item.SLACONTRATO
                  }
                );
              });

              setFormulario({
                id: formulario.id,
                usuario_id: formulario.numeroMov1 == "" ? usuarioID : formulario.usuario_id,
                coligada: data.data[0].CODCOLIGADA.toString(),
                filial: data.data[0].CODFILIAL.toString(),
                localEstoque: data.data[0].CODLOC.toString() + " - " + data.data[0].CODLOCNOME,
                centroCusto: data.data[0].CODCCUSTO + " - " + data.data[0].CODCUSTONOME,
                dataAberturaOS: dataAberturaOSFormatada,
                IDMOV_OS: data.data[0].IDMOV_OS,
                itens: dadosItens,
                departamento: data.data[0].DEPARTAMENTO, //.split(" - ")[0],
                departamentos: formulario.departamentos,
                dataSolicitacao: dataAtual(),
                dataNecessidade: formulario.dataNecessidade,
                descricao: data.data[0].DESCRICAO,
                prefixoEquipamento: data.data[0].IDOBJOF,
                marca: data.data[0].MARCA,
                modelo: data.data[0].MODELO,
                horimetro: data.data[0].HORIMETRO,
                criterio: formulario.criterio,
                tipoPedido: formulario.tipoPedido,
                aplicacao: formulario.aplicacao,
                justificativa: formulario.justificativa,
                informacoesAd: formulario.informacoesAd || "",
                motivoCancelamento: formulario.motivoCancelamento || "",
                motivoReprovacao: formulario.motivoReprovacao || "",
                observacoesSolicitacao: formulario.observacoesSolicitacao || "",
                decisao: formulario.decisao,
                etapaAtual: formulario.etapaAtual,
                numeroOs: numOS,
                numeroPms: formulario.numeroPms,
                status: formulario.status,
                movimento1103: formulario.movimento1103,
                movimento1104: formulario.movimento1104,
                movimento1121: formulario.movimento1121,
                numeroMov1: formulario.numeroMov1,
                numeroMov2: formulario.numeroMov2,
                numeroMov3: formulario.numeroMov3,
                numeroMov4: formulario.numeroMov4,
                numeroMov21: formulario.numeroMov21,
                vendorlist: formulario.vendorlist,
                observacoesFornecedores: formulario.observacoesFornecedores
              });
              setOsVinculada(true);
              if (itensMarcados.length == 0) {
                toast.update(id, { render: "O.S Vinculada", type: "success", isLoading: false, autoClose: 2000 });
              }
            }
            else {
              if (itensMarcados.length == 0) {
                toast.update(id, { render: "O.S não encontrada", type: "warning", isLoading: false, autoClose: 2000 });
                formulario.numeroOs = "";
              }
            }
          }).catch(function (err) {
            console.error(err);
            toast.dismiss();
            toast.error(err.response.data.message);
            toast.error("Ocorreu um erro ao carregar a OS. Por favor, atualize a página.");
          });
      }
    }
    else {
      toast.dismiss();
      toast.warning("Escolha um local de estoque para vincular a O.S");
    }
  }

  const obterDadosFormulario = (departamentos) => {

    if (id_param) {
      setNumeroProcesso(id_param);

      //Testando obter processo
      api.get(`/solicitacoes/${id_param}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      )
        .then(function ({ data }) {
          // const departamentosAtuais = formulario.departamentos;
          console.log("aqui solicitação: ", data);
          const formularioSalvo = data.data;
          formularioSalvo.departamentos = departamentos;
          //caso tenha pedido contrato juridico, recarregar classificacao RD:
          if (data.data.contratoClassRD) {
            setNomeClassificacaoRDBuscado(data.data.contratoClassRD);
            setClassificacaoRDVinculada(true);
          }
          //caso seja pedido de contrato, recarregar switch check informando:
          if (data.data.pedidoContrato) {
            setMostrarCamposPedidoFornecedor(true);
          }
          // formularioSalvo.descricao = data.data.descricao;
          setFormulario(formularioSalvo);
          setLaudoAvariaMotorTemp(formularioSalvo?.motor);
          setListaLaudoReformaPneu(formularioSalvo?.pneus);
          setListaInspecaoMaterialRodante(formularioSalvo?.rodante);
          //Recarrega o campo vendorlist, que é array json, para exibir pro usuario:
          if (data.data.vendorlist && data.data.vendorlist.length && data.data.vendorlist.length > 0) {
            try {
              setFornecedoresSelecionados(JSON.parse(data.data.vendorlist)); //pegar lista de vendorlist e prenche lista para formar table
            }
            catch (error) {
              setFornecedoresSelecionados([]);
            }
          }

          if (data.data.numeroOs && data.data.etapaAtual == "Solicitante") {
            //Limpando os itens que foram selecionados para listar a O.S com os itens marcados
            vincularOS(data.data, data.data.itens);
            data.data.itens = [];
          }
          setEtapaAtual(data.data.etapaAtual);

          //Preenchendo atributos de identificação
          setNumeroOS(data.data.numeroOs);
          formulario.localEstoque = data.data.localEstoque.split(" - ")[0];

          //Definindo a permissão de aprovação
          const grupos = JSON.parse(localStorage.getItem("usuario")).grupo;
          var etapaAtual = ""; //grupos.length > 0 ? grupos[0].perfil : "";
          var obra = "";//JSON.parse(localStorage.getItem("usuario")).obra; //??
          const idDoUsuario = JSON.parse(localStorage.getItem("usuario")).user.id;

          //Varrer variável grupos do usuário e identificar se a atividade atual pertence a ele:
          if (id_param) {
            etapaAtual = "000"
            grupos.forEach((grupo) => {
              //se perfil aprovador do grupo corresponde a etapa atual, e obra do grupo corresponde ao local de estoque da solicitacao..
              // e também a solicitacao tem o tipoPedido que se encontra em um dos grupos do usuário, ele pode aprovar:
              if ((grupo.perfil == data.data.etapaAtual) && (grupo.obra == data.data.localEstoque) && (grupo.tipoPedido.includes(data.data.tipoPedido))) {
                etapaAtual = grupo.perfil;
                obra = grupo.obra;
              }
            });
          }
          //Permissao de aprovador é para usuário que seja responsável pela etapa atual, e também pertença ao local de estoque da solicitação:
          if (
            (etapaAtual == data.data.etapaAtual && obra == data.data.localEstoque) ||
            (etapaAtual == "" && idDoUsuario == data.data.usuario_id)
          ) {
            setPermissaoAprovador(true);
          }

          const departamentoDoBanco = data.data.departamento.includes(" - ") ? data.data.departamento.split(" - ")[0] : data.data.departamento;
          const departamentoSalvo = departamentos.filter(departamento => departamento.CODDEPARTAMENTO == departamentoDoBanco)[0];

          if (departamentoSalvo) {
            setFormulario(prevState => ({
              ...prevState,
              departamento: departamentoSalvo.CODDEPARTAMENTO + " - " + departamentoSalvo.NOME,
            }));
          }

          //Atribuindo variavel desabilitaAprovadores
          desabilitaAprovadores = data.data.etapaAtual != "Solicitante" || (data.data.numeroOs && data.data.etapaAtual == "Solicitante") || (usuarioID != data.data.usuario_id && data.data.etapaAtual == "Solicitante" && id_param != "");
          desabilitarEtapasAprovacao = data.data.etapaAtual != "Solicitante" || (usuarioID != data.data.usuario_id && data.data.etapaAtual == "Solicitante" && id_param != "");
          // (etapaAtual != "Solicitante") || (osVinculada) || (formulario.usuario_id ? usuarioID != formulario.usuario_id : false)}
        })
        .catch(function (err) {
          console.log(err);
          toast.error(JSON.stringify(err.response.data));
          toast.error("Ocorreu um erro ao carregar a solicitação. Por favor, atualize a página.");

        });
    }

    //Atribuindo variavel desabilitaAprovadores
    //Se é um novo formulário não existe motivo para bloquear os campos
    desabilitaAprovadores = false;
    desabilitarEtapasAprovacao = false;
  }

  const desvincularOS = () => {
    setOsVinculada(false);
    formulario.numeroOs = null;
    setNumeroOS("");

    setFormulario(prevState => ({
      ...prevState,
      tipoPedido: "002",
      itens: [],
      coligada: "1",
      filial: "1",
      localEstoque: "001.00",
      centroCusto: "01.01.013",
      departamento: "01",
      dataSolicitacao: dataAtual(),
      tipoPedido: "001",
    }));

    toast.info("O.S desvinculada!");
  }

  const atualizarFormulario = (chave, valor, item = false, indiceItem) => {
    let objFormulario = formulario;
    if (item) {
      osVinculada ?
        objFormulario.itens[indiceItem][chave] = valor :
        objFormulario.itens[indiceItemConsultado][chave] = valor;
    }
    else {
      objFormulario[chave] = valor;
    }

    setFormulario(objFormulario);
    setAtualizarEstados(!atualizarEstados);

    //Alterando a filial na variavel de estado
    // setFormulario(prevState => ({
    //   ...prevState,
    //   filial: formulario.filial,
    // }));

    if (!formulario.departamento) {
      if (formulario.departamentos.length > 0) {
        setFormulario(prevState => ({
          ...prevState,
          departamento: formulario.departamentos[0].CODDEPARTAMENTO,
        }));
      }
      else {
        toast.warning("Esta filial não possui departamento cadastrado, escolha outra.");
        setFormulario(prevState => ({
          ...prevState,
          departamento: "",
        }));
      }
    }

    // obterLocalEstoqueECentroCusto();
  }

  const buscarItem = () => {
    if (!formulario.localEstoque) {
      toast.warn("Informe o local de estoque antes de adicionar um item.");
      setCodLocSelectOpen(true);
      return;
    }
    if (nomeCodigoNovoItem) {
      const id = toast.loading("Buscando o item informado");
      const isNumber = (!isNaN(parseInt(nomeCodigoNovoItem.replace("/^\d+$/.test(str)"))));
      const codigo = isNumber ? nomeCodigoNovoItem.padStart(5, "0") : null;
      const descricao = isNumber ? null : nomeCodigoNovoItem;

      api.get(`/buscarItens?CODIGO=${codigo}&NOME=${descricao}&CODLOC=${formulario.localEstoque}&FILIAL=${formulario.filial}`)
        .then(function ({ data }) {
          if (data.length > 1) {
            toast.update(id, { render: "Item(s) encontrado(s)", type: "success", isLoading: false, autoClose: 1000 });
            setItensEncontradosBusca(data);
            setOpenModalListaItem(true);
            setNomeCodigoNovoItem("");
          } else if (data.length == 1) {
            toast.update(id, { render: "Item encontrado", type: "success", isLoading: false, autoClose: 1000 });
            novoItem(data[0].CODIGOPRD);
            setNomeCodigoNovoItem("");
          }
          // else {
          // if (!formulario.localEstoque) {
          //   toast.update(id, { render: "Escolha um local de estoque para adicionar um item", type: "info", isLoading: false, autoClose: 2500 });
          // }
          // else {
          //   toast.update(id, { render: "Item  não encontrado neste local de estoque", type: "warning", isLoading: false, autoClose: 2500 });
          // }
          // }
        }).catch(function (err) {
          let msgErro = err?.response?.data?.message;
          toast.update(id, { render: msgErro, type: "warning", isLoading: false, autoClose: 1000 });
          // toast.error();
        });
    }
  }

  //Adicionar novo item manualmente no formulário
  const novoItem = (codprd) => {
    if ((formulario.itens.length > 0) &&
      (formulario.itens.find(item => item.codProduto == codprd))) {
      toast.warning("Item ja existe na tabela");
    }
    else {
      api.get(`/buscarItem?CODIGO=${codprd}&CODLOC=${formulario.localEstoque}&FILIAL=${formulario.filial}`)
        .then(({ data }) => {
          const dadosFormatados = {
            codProduto: data.CODIGOPRD,
            idPrd: data.IDPRD,
            descricao: data.NOMEFANTASIA,
            quantidadeProduto: data.QUANTIDADEARECEBER,
            QUANTIDADEARECEBER: "",
            CODTMV: "",
            estoque: data.SALDOFISICO2,
            saldoMin: data.SALDO_MIN,
            saldoMax: data["SALDO MAX"],
            CODUND: data.UNIDADE,
            saldoContrato: data.SALDODISPCONT,
            pedidoAFornecedor: data.PEDIDOF,
            precoUntAlvo: "",
            idObjetoOficina: "",
            observacoesItem: "",
            naoUsarEstoque: false,
            naoUsarContrato: false,
            codcfo: data.CODCFO ?? 0,
            precoUnit: data.PRECOFATURAMENTO ?? 0,
            quantidadeBaixarRM: 0,
            quantidadeBaixarContratoRM: 0,
            tipo: data.TIPO_PRODUTO,
            slaContrato: data.SLACONTRATO ?? 0
          }
          setItemTemporario(dadosFormatados);
          setOpenModal(true);
        }).catch(({ data }) => {
          toast.error(data.message);
          toast.error(JSON.stringify(data));
        })
    }
  }

  const acrescentarItem = () => {
    let itens = formulario.itens;
    if (itens.length > 0) {
      let itemEncontrado = false;
      itens.forEach((item, indice) => {
        if (item.codProduto == itemTemporario.codProduto) {
          itens[indice] = itemTemporario;
          itemEncontrado = true;
        }
      });
      if (!itemEncontrado) {
        itens.push(itemTemporario);
      }
    }
    else {
      itens.push(itemTemporario);
    }

    //valida se a SLA do item será atendida pela data da necessidade:
    if (itemTemporario.slaContrato && itemTemporario.slaContrato > 0) {
      let prazoSLA = itemTemporario.slaContrato; //2 dias
      // Data atual
      let dataAtual = new Date();
      let milissegundosDataAtual = dataAtual.getTime();
      // Calcular a data limite do SLA
      // dataLimiteSLA.setDate(dataLimiteSLA.getDate() + prazoSLA);
      let dataNecessidade = new Date(formulario.dataNecessidade);
      // Calcular a quantidade de milissegundos correspondente aos dias a serem adicionados
      let milissegundosParaAdicionar = prazoSLA * 24 * 60 * 60 * 1000;
      // Somar os milissegundos para adicionar à data atual
      let novaDataMilissegundos = milissegundosDataAtual + milissegundosParaAdicionar;
      // Criar um novo objeto Date com a nova data em milissegundos
      let dataComSLA = new Date(novaDataMilissegundos);
      //se data necessidade for menor que a data necessaria para atender o SLA, mostrar alert:
      
      if (dataNecessidade < dataComSLA && !itemTemporario.naoUsarContrato) {
        alert("a data da necessidade não atende ao SLA do contrato deste item.");
      }
    }

    formulario.itens = itens;
    setOpenModal(false);
    setItemTemporario({});
  }

  const excluirItem = (indice) => {
    setDialogExclusao(false)
    setIndiceItemConsultado(0);
    formulario.itens.splice(indice, 1);
    //resetar variaveis de exigencia de laudos, pois pode ser que ele tenha sido excluido:
    laudoAvariaMotorRequired = false;
    laudoReformaPneuRequired = false;
  }

  const realizarSolicitacao = async () => {

    let itens = [];
    let itensMovimento = [];
    let campoOSSelecionado = false;

    const proximaEtapa = await obterProximaEtapa(formulario, etapaAtual, decisaoAprovacao, etapaARetornar); //definirProximaEtapa(formulario, etapaAtual, decisaoAprovacao);

    formulario.itens.forEach((item, indice) => {
      campoOSSelecionado = item.marcado ? true : campoOSSelecionado;
      if (item.marcado || !osVinculada) {
        itens.push(
          {
            idPrd: item.idPrd,
            codProduto: item.codProduto, //obrigatório
            descricao: item.descricao, //obrigatório
            estoque: item.estoque, //obrigatório
            saldoMin: item.saldoMin,
            saldoMax: item.saldoMax,
            saldoContrato: item.saldoContrato,
            pedidoAFornecedor: item.pedidoAFornecedor,
            unidade: item.CODUND,
            quantidadeProduto: item.quantidadeProduto, //obrigatório
            quantidadeComprar: item.QUANTIDADEARECEBER,
            naoUsarContrato: item.naoUsarContrato,
            naoUsarEstoque: item.naoUsarEstoque,
            observacoesItem: item.observacoesItem,
            precoUntAlvo: item.precoUntAlvo,
            idObjetoOficina: item.idObjetoOficina,
            marcado: item.marcado || false,
            sequenciaOS: item.N_SEQ_OS,
            codcfo: item.codcfo,
            precoUnit: item.precoUnit,
            tipo: item.tipo,
            slaContrato: item.SLACONTRATO ? item.SLACONTRATO: item.slaContrato
          }
        )

        itensMovimento.push(
          {
            idprd: item.idPrd,
            quantidadeAReceber: item.quantidadeProduto,
            ultimoValor: null,
            PRECOALVO: null,
            NUMOS: numeroOS,
            IDOBJOF: item.idObjetoOficina,
            codUnd: item.CODUND,
            N_SEQ_OS: osVinculada ? item.N_SEQ_OS : (indice + 1),
          }
        )
        //se tem item para realizar laudo de avaria motor, sinalizar:
        if(item.codProduto == "13900" || item.codProduto == "28633"){
          laudoAvariaMotorRequired = true;
        }
        //se tem servico de reforma pneu, sinalizar:
        if(item.codProduto == "13451"){
          laudoReformaPneuRequired = true;
        }
      }
    })

    //Variaveis que precisam de remover a descricao e enviar apenas o código para salvar:
    let formLocalEstoque = null;
    let formCentroCusto = null;
    let formDepartamento = null;
    if (osVinculada) {
      formLocalEstoque = formulario.localEstoque ? formulario.localEstoque.split(" - ")[0] : "";
      formCentroCusto = formulario.centroCusto ? formulario.centroCusto.split(" - ")[0] : "";
      formDepartamento = formulario.departamento ? formulario.departamento.split(" - ")[0] : "";
    } else {
      formLocalEstoque = formulario.localEstoque ? formulario.localEstoque : "";
      formCentroCusto = formulario.centroCusto ? formulario.centroCusto : "";
      formDepartamento = formulario.departamento ? formulario.departamento : "";
    }

    if (fornecedoresSelecionados.length > 0) {
      formulario.vendorlist = JSON.stringify(fornecedoresSelecionados);
    }

    //Validando se o tipo pedido foi selecionado
    if (!formulario.tipoPedido) {
      toast.warning("Você precisa escolher o tipo de pedido");
      return;
    }

    const solicitacao = {
      usuario_id: usuarioID,
      coligada: formulario.coligada, //obrigatório
      filial: formulario.filial, //obrigatório
      localEstoque: formLocalEstoque, //obrigatório
      centroCusto: formCentroCusto, //obrigatório
      departamento: formDepartamento,
      dataNecessidade: formulario.dataNecessidade, //obrigatório
      prefixoEquipamento: formulario.prefixoEquipamento || "",
      descricaoObj: formulario.descricao,
      marca: formulario.marca || "",
      modelo: formulario.modelo || "",
      dataSolicitacao: formulario.dataSolicitacao, //obrigatório
      numeroPms: numeroPms,
      numeroOs: numeroOS,
      tipoPedido: formulario.tipoPedido, //obrigatório
      criterio: formulario.criterio, //obrigatório
      aplicacao: formulario.aplicacao, //obrigatório
      justificativa: formulario.justificativa, //obrigatório
      observacoesSolicitacao: formulario.observacoesSolicitacao,
      etapaAnterior: etapaAtual, //obrigatório
      etapaAtual: proximaEtapa, //obrigatório
      status: 1, //obrigatório
      decisao: decisaoAprovacao,
      informacoesAd: formulario.informacoesAdicionais,
      motivoReprovacao: formulario.motivoReprovacao,
      motivoCancelamento: formulario.motivoCancelamento,
      itens: itens,
      numeroMov1: null,
      numeroMov2: null,
      numeroMov3: null,
      numeroMov4: null,
      numeroMov21: null,
      vendorlist: formulario.vendorlist,
      contratoTipoContrato: formulario.contratoTipoContrato,
      contratoNome: formulario.contratoNome,
      contratoDataIni: formulario.contratoDataIni,
      contratoDataFim: formulario.contratoDataFim,
      contratoClassRD: formulario.contratoClassRD,
      contratoFornecedor: formulario.contratoFornecedor,
      pedidoContrato: formulario.pedidoContrato,
      transferencia: formulario.transferencia,
      filialOrigem: formulario.filialOrigem,
      localEstoqueOrigem: formulario.localEstoqueOrigem,
      observacoesTransferencia: formulario.observacoesTransferencia,
      // responsavel: "",
      observacoesFornecedores: formulario.observacoesFornecedores,
      horimetro: formulario.horimetro
    }

    //Adiciona laudos de checagem ao objeto da solicitacao(se houver)
    if(laudoAvariaMotor != null) {
      solicitacao.motor = laudoAvariaMotor;
    }
    if(listaLaudoReformaPneu != null) {
      solicitacao.pneus = listaLaudoReformaPneu;
    }
    if(listaInspecaoMaterialRodante != null) {
      solicitacao.rodante = listaInspecaoMaterialRodante;
    }

    //Gerar movimento 1101 no RM:
    const pedido1101 = {
      CODCOLIGADA: formulario.coligada,
      ITENS: itensMovimento,
      CODFILIAL: formulario.filial,
      CODLOC: formLocalEstoque,
      VALORBRUTO: null,
      CODCCUSTO: formCentroCusto,
      USUARIO: usuarioLogin,
      MARCA: null,
      APLICACAO: formulario.aplicacao,
      JUSTFIOBS: formulario.justificativa,
      DATAENTREGA: formulario.dataNecessidade,
      CAMPOLIVRE1: '',
      CAMPOLIVRE2: '',
      CAMPOLIVRE3: osVinculada ? numeroOS : '',
      HISTORICOLONGO_TMOV: formulario.observacoesSolicitacao,
      HISTORICOLONGO_TITMMOV: formulario.observacoesSolicitacao,
      OPERACAO: "S",
      MOTIVOCANCELAMENTO: "",
      NUMEROMOV: null,
      CODDEPARTAMENTO: formDepartamento,
      TIPOPEDIDO: formulario.tipoPedido,
      CRITERIO: formulario.criterio,
      QTDITENS: itensMovimento.length,
      IDMOV_OS: osVinculada ? formulario.IDMOV_OS : 0,
      ESUP: 1
    }
    console.log("Solicitacao a salvar: ", solicitacao);
    if (validarCamposObrigatorios(solicitacao, itens, campoOSSelecionado)) {
      let id_toast = toast.loading("Gerando movimento 1.1.01..");

      setEnviarDisabled(true);
      axios.post("https://almox.pelicano.eng.br/php/movimento/1.1.01/PedidoMateriais.php",
        pedido1101, {
        timeout: 100000
      }
      ).then(function (response) {
        let retorno = response.data;
        console.log("Retorno gerar mov 1.1.01 ", retorno);
        let retornoPrefixo = retorno.substring(0, 2);
        if (retornoPrefixo == "1;") {
          toast.update(id_toast, { render: "Movimento 1.1.01 gerado com Sucesso!", type: "success", isLoading: false, autoClose: 2000 });
          // toast.success("Movimento 1101 gerado com sucesso!");
          solicitacao.numeroMov1 = retorno.split(";")[1]; //guardar o numeroMov 1101 na solicitacao
          solicitacao.numeroPms = retorno.split(";")[1];
          id_toast = toast.loading("Salvando Solicitação..");

          api.post(`/solicitacoes`,
            solicitacao,
            {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }
          )
            .then(function ({ data }) {
              
              toast.update(id_toast, { render: `Solicitação ${data.data.id} criado com sucesso!`, type: "success", isLoading: false, autoClose: 2500 });
              id_toast = toast.loading("Salvando anexos..");
              // toast.success("Solicitação realizada com sucesso");
              api.post(`/anexos?solicitacao_id=${data.data.id}&responsavel=${usuarioLogin}`,
                uploadAnexos,
                {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                  }
                }
              ).then(function (response) {
                toast.update(id_toast, { render: "Anexos salvos com Sucesso!", type: "success", isLoading: false, autoClose: 2000 });
                navigate("/centralTarefas");
              }).catch(function (err) {
                console.error(err);
                toast.error(JSON.stringify(err.response.data));
              })
            })
            .catch(function (err) {
              //toast.error("Ocorreu um erro ao realizar a solicitação, consulte o log");
              console.error(err);
              toast.update(id_toast, { render: "Ocorreu um erro ao realizar a solicitação, consulte o log!", type: "error", isLoading: false, autoClose: 2000 });
              toast.error(JSON.stringify(err.response.data));
            });
          //fim chamada api
        }//if-sucesso-1101
        else {

          let mensagemErro = "";
          if (response.data.includes("=======================================")) {
            mensagemErro = response.data.split("=======================================")[0];
          }
          if (mensagemErro.includes("Série RM:")) {
            mensagemErro = mensagemErro.split("Série RM:")[1];
          }
          if (mensagemErro.includes("00:00:00")) {
            mensagemErro = mensagemErro.replaceAll("00:00:00", "");
          }
          // "Aviso do RM: " + response.data.split("=======================================")[0];
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
      }).catch(function (err) {
        //toast.error("Erro ao tentar gerar 1101!");
        toast.update(id_toast, { render: "Erro ao tentar gerar 1101, consulte o log!", type: "error", isLoading: false, autoClose: 2000 });
        console.error(err);
        toast.error(JSON.stringify(err.response.data));
      }).finally(() => {
        setEnviarDisabled(false);
      })
    }
  }

  const validarCamposObrigatorios = (solicitacao, itens, campoSelecionado) => {
    if (!solicitacao.dataNecessidade) { toast.warning("O campo data da necessidade é obrigatório"); return false }
    if (!solicitacao.criterio) { toast.warning("O campo critério atendimento é obrigatório"); return false }
    if (!solicitacao.aplicacao) { toast.warning("O campo aplicação é obrigatório"); return false }
    if (!solicitacao.justificativa) { toast.warning("O campo justificativa é obrigatório"); return false }
    if (!solicitacao.localEstoque) { toast.warning("O campo local de estoque é obrigatório"); return false }
    if (!solicitacao.centroCusto) { toast.warning("O campo centro de custo é obrigatório"); return false }
    if (!osVinculada && itens.length == 0) { toast.warning("Adicione pelo menos 1 item na solicitação"); return false }
    if (osVinculada && !campoSelecionado) { toast.warning("Selecione pelo menos 1 item da O.S"); return false }

    //se é pedidoContrato, validar campos obrigatórios:
    if (solicitacao.pedidoContrato && !solicitacao.contratoNome) { toast.warning("Para pedido contrato, informe o nome do contrato"); return false }
    if (solicitacao.pedidoContrato && !solicitacao.contratoTipoContrato) { toast.warning("Para pedido contrato, informe o tipo do contrato"); return false }
    // if (solicitacao.pedidoContrato && !solicitacao.contratoClassRD) { toast.warning("Para pedido contrato, informe a classificação RD"); return false }
    if (solicitacao.pedidoContrato && !solicitacao.contratoFornecedor) { toast.warning("Para pedido contrato, informe o fornecedor"); return false }
    if (solicitacao.pedidoContrato && !solicitacao.contratoDataIni) { toast.warning("Para pedido contrato, informe a data do contrato"); return false }

    //se é pedido transferencia, validar campos obrigatórios
    if(solicitacao.transferencia && !solicitacao.localEstoqueOrigem){ toast.warning("Para transferência de materiais, informe o local de estoque origem."); return false}
    if(solicitacao.transferencia && !solicitacao.filialOrigem){ toast.warning("Para transferência de materiais, informe a filial de origem."); return false}
    if(!('motor' in solicitacao) && laudoAvariaMotorRequired) { toast.warning("Para os itens solicitados, é obrigatório informar o laudo de avaria do motor."); return false}
    if(!('pneus' in solicitacao) && laudoReformaPneuRequired) { toast.warning("Para os itens solicitados, é obrigatório informar o laudo de reforma de pneu."); return false}
    return true;
  }

  const rolarParaOCampo = (decisao) => {
    if (decisao != "Aprovado") {
      //Precisa haver um delay na chamada da função de rolagem porque a renderização do componente é assíncrona
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50);
    }
  }


  const solicitanteCancelaProcesso = async() => {
    // O solicitante ao receber sua solicitacao reprovada, pode também cancela-la se quiser
    let confirmar_cancelamento = window.confirm("Deseja realmente cancelar sua solicitação?");
    if (confirmar_cancelamento){
      setDecisaoAprovacao("Cancelado");
      formulario.motivoCancelamento = "O solicitante cancelou o pedido.";
      formulario.status = 2;
      setPodeMovimentarParaCancelar(true);
    }
  }

  useEffect(() => {
    //Esse useEffect verifica se o solicitante cancelou o seu pedido, e permite atualizar como cancelado.
    if (podeMovimentarParaCancelar) {
      movimentarProcesso();
      setPodeMovimentarParaCancelar(false); // Reseta para evitar loops infinitos
    }
  }, [podeMovimentarParaCancelar]);


  const movimentarProcesso = async () => {

    //Verificar se o aprovador possui permissão no local de estoque e centro de custo da solicitação
    // const possuiPermissaoCentroCusto = (informacoesGerais.centrosDeCusto.findIndex(cc => formulario.centroCusto.split(" - ")[0] == cc.codigo) != -1);
    const possuiPermissaoCentroCusto = (
      formulario.centroCusto?.split(" - ")[0] &&
      informacoesGerais.centrosDeCusto.findIndex(cc => formulario.centroCusto.split(" - ")[0] == cc.codigo) != -1
    );
    //const possuiPermissaoLocalEstoque = (informacoesGerais.locaisDeEstoque.findIndex(le => formulario.localEstoque.split(" - ")[0] == le.codigo) != -1);
    const possuiPermissaoLocalEstoque = (
      formulario.localEstoque?.split(" - ")[0] &&
      informacoesGerais.locaisDeEstoque.findIndex(le => formulario.localEstoque.split(" - ")[0] == le.codigo) != -1
    );

    // Validacao da permissao de centro custo e local estoque, existir para solicitante, apenas:
    if (formulario.etapaAtual == "Solicitante"){
      if (!possuiPermissaoCentroCusto) {
        toast.info("Você não possui permissão no centro de custo " + formulario.centroCusto);
        return;
      }
      if (!possuiPermissaoLocalEstoque) {
        toast.info("Você não possui permissão no local de estoque " + formulario.localEstoque);
        return;
      }
    }

    let sucessoCancelarMovimento = true; //só usar em caso de reprovar a solicitacao e voltar pro solicitante

    //Trecho de validações básicas:
    if ((decisaoAprovacao == "Reprovado") && (!formulario.motivoReprovacao)) {
      toast.warning("O campo motivo da reprovação é obrigatório");
      return;
    }
    if ((decisaoAprovacao == "Cancelado") && (!formulario.motivoCancelamento)) {
      toast.warning("O campo motivo do cancelamento é obrigatório");
      return;
    }
    //valida se o aprovador "Análise Crítica Compras" aprovou e informou o nome comprador:
    if ((etapaAtual == "Análise Crítica Compras") && (decisaoAprovacao == "Aprovado") && (!compradorSelected)) {
      toast.warning("É necessário informar o nome do comprador ao aprovar a solicitação.");
      return;
    }

    // (formulario, etapaAtual, decisao, 
    //   etapaARetornar=null, emergencial=false, tipoPedido=null, totalCotacao=null, criterioAtendimento=null)
    let emergencial = false;
    if ((formulario.criterio == "MP") || (formulario.criterio == "UR")) {
      emergencial = true;
    }

    const proximaEtapa = await obterProximaEtapa(formulario, etapaAtual, decisaoAprovacao, etapaARetornar); //definirProximaEtapa(formulario, etapaAtual, decisaoAprovacao);

    let itens = [];
    let campoOSSelecionado = false;

    formulario.itens.forEach((item, indice) => {
      campoOSSelecionado = item.marcado ? true : campoOSSelecionado;
      if (item.marcado || !osVinculada) {
        itens.push(
          {
            id: item.id,
            idPrd: item.idPrd,
            codProduto: item.codProduto, //obrigatório
            descricao: item.descricao, //obrigatório
            estoque: item.estoque, //obrigatório
            saldoMin: item.saldoMin,
            saldoMax: item.saldoMax,
            saldoContrato: item.saldoContrato,
            pedidoAFornecedor: item.pedidoAFornecedor,
            unidade: item.CODUND ? item.CODUND : item.unidade, //Se é CODUND (item adicionado em tempo execucao) ou unidade (já adicionado)
            quantidadeProduto: item.quantidadeProduto, //obrigatório
            quantidadeComprar: item.QUANTIDADEARECEBER,
            naoUsarContrato: item.naoUsarContrato,
            naoUsarEstoque: item.naoUsarEstoque,
            observacoesItem: item.observacoesItem,
            precoUntAlvo: item.precoUntAlvo,
            idObjetoOficina: item.idObjetoOficina,
            marcado: item.marcado ? item.marcado : 0,
            sequenciaOS: item.N_SEQ_OS ? item.N_SEQ_OS : item.sequenciaOS,
            quantidadeComprarRM: item.quantidadeComprarRM ? item.quantidadeComprarRM : 0,
            quantidadeBaixarRM: item.quantidadeBaixarRM ? item.quantidadeBaixarRM : 0,
            quantidadeBaixarContratoRM: item.quantidadeBaixarContratoRM ? item.quantidadeBaixarContratoRM : 0,
            codcfo: item.codcfo,
            precoUnit: item.precoUnit,
            tipo: item.tipo,
            slaContrato: item.SLACONTRATO ? item.SLACONTRATO : item.slaContrato
          }
        )
      }
    })

    // se o usuario solicitante refez o vendorlist, salvar este campo como array object em strigify;
    let vendorList = fornecedoresSelecionados.length > 0 ? JSON.stringify(fornecedoresSelecionados) : '[]';

    const solicitacao = {
      id: formulario.id,
      usuario_id: usuarioID,
      coligada: formulario.coligada, //obrigatório
      filial: formulario.filial, //obrigatório
      localEstoque: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque, //obrigatório
      centroCusto: osVinculada ? formulario.centroCusto.split(" - ")[0] : formulario.centroCusto, //obrigatório
      departamento: formulario.departamento,
      dataNecessidade: formulario.dataNecessidade, //obrigatório
      prefixoEquipamento: formulario.prefixoEquipamento || "",
      descricaoObj: formulario.descricao,
      marca: formulario.marca || "",
      modelo: formulario.modelo || "",
      dataSolicitacao: formulario.dataSolicitacao, //obrigatório
      numeroPms: formulario.numeroPms,
      numeroOs: numeroOS,
      tipoPedido: formulario.tipoPedido, //obrigatório
      criterio: formulario.criterio, //obrigatório
      aplicacao: formulario.aplicacao, //obrigatório
      justificativa: formulario.justificativa, //obrigatório
      observacoesSolicitacao: formulario.observacoesSolicitacao,
      etapaAnterior: etapaAtual, //obrigatório
      etapaAtual: proximaEtapa, //obrigatório
      status: formulario.status, //obrigatório
      decisao: decisaoAprovacao,
      informacoesAd: formulario.informacoesAdicionais,
      motivoReprovacao: formulario.motivoReprovacao,
      motivoCancelamento: formulario.motivoCancelamento,
      itens: itens,
      numeroMov1: formulario.numeroMov1,
      numeroMov2: formulario.numeroMov2,
      numeroMov3: formulario.numeroMov3,
      numeroMov4: formulario.numeroMov4,
      numeroMov21: formulario.numeroMov21,
      movimento1103: formulario.movimento1103 ? formulario.movimento1103 : 0,
      movimento1104: formulario.movimento1104 ? formulario.movimento1104 : 0,
      movimento1121: formulario.movimento1121 ? formulario.movimento1121 : 0,
      vendorlist: vendorList,
      contratoTipoContrato: formulario.contratoTipoContrato,
      contratoNome: formulario.contratoNome,
      contratoDataIni: formulario.contratoDataIni,
      contratoDataFim: formulario.contratoDataFim,
      contratoClassRD: formulario.contratoClassRD,
      contratoFornecedor: formulario.contratoFornecedor,
      pedidoContrato: formulario.pedidoContrato,
      // responsavel: ""
      observacoesFornecedores: formulario.observacoesFornecedores,
      comprador: formulario.comprador || null,
      compradorId: formulario.compradorId || null,
      horimetro: formulario.horimetro
    }

    //Adiciona laudos de checagem ao objeto da solicitacao(se houver)
    if(laudoAvariaMotor != null) {
      solicitacao.motor = laudoAvariaMotor;
    }
    if(listaLaudoReformaPneu != null) {
      solicitacao.pneus = listaLaudoReformaPneu;
    }
    if(listaInspecaoMaterialRodante != null) {
      solicitacao.rodante = listaInspecaoMaterialRodante;
    }

    // Se etapa atual "Análise Crítica Compras": incluir o nome do comprador que ele selecionou:
    if ((etapaAtual == "Análise Crítica Compras") && (decisaoAprovacao == "Aprovado")) {
      solicitacao.comprador = compradorSelected.nome;
      solicitacao.compradorId = compradorSelected.compradorId;
    }

    // - Bloco de chamada dos movimentos do RM:
    //Se for etapa retorno ao solicitante para ajustes, chamar o atualiza1101. Caso solicitante cancelou seu pedido, pula o atualiza1101:
    if ((etapaAtual == "Solicitante") && (numeroProcesso != "") && (decisaoAprovacao != "Cancelado")) {
      setEnviarDisabled(true);
      await atualizaSolicitacao1101(formulario, etapaAtual, numeroProcesso, solicitacao);
      setEnviarDisabled(false);
    } else {
      //Caso seja evolucao de movimento, chama a funcao que faz os movimentos de evolucao
      // 1 - caso reprove e volte pro solicitante, ou caso cancelado, deve-se cancelar movimentos existentes
      if ((decisaoAprovacao == "Reprovado") || (decisaoAprovacao == "Cancelado")) {
        sucessoCancelarMovimento = await identificaMovimentosACancelar(solicitacao);
        console.log("sucessoCancelarMovimento", sucessoCancelarMovimento);
        if (sucessoCancelarMovimento == false) {
          solicitacao.etapaAtual = etapaAtual; //erro ao cancelar, manter na etapa atual até acertar
          console.log("erro ao cancelar, manter na etapa atual até acertar", solicitacao.etapaAtual);
          return;
        }
      }
      else { // 2- Regra geral segue a evolucao dos movimentos normalmente
        setEnviarDisabled(true);
        await montaEvolucao1102(proximaEtapa, formulario, solicitacao);
        setEnviarDisabled(false);
      }
    }

    //erro ao evoluir 1.1.03/04/21, manter na etapa atual até acertar
    console.log("TEM ERRO Evoluir ", erroEvoluir);
    if(erroEvoluir == true){
      solicitacao.etapaAtual = etapaAtual;
    }

    let id_toast = toast.loading("Atualizando o processo...");

    api.put(`/solicitacoes/` + numeroProcesso,
      solicitacao,
      {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
    )
      .then(async function ({ data }) {
        //toast.dismiss();
        toast.update(id_toast, { render: "Movimentação salva com Sucesso!", type: "success", isLoading: false, autoClose: 4000 });
        api.post(`/anexos?solicitacao_id=${data.data.id}&responsavel=${usuarioLogin}`,
          uploadAnexos,
          {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function (response) {

          //Enviando nome dos arquivos selecionados pelo jurídico para enviar e-mail ao fornecedor
          if (etapaAtual == "Jurídico") {
            
            api.post(`/anexosContratos`,
              itensParaFornecedor,
              {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              }
            ).then(function (response) {

              // só voltar para central tarefas se proxima tarefas nao for 'compras' e nao travou na etapa atual:
              if (proximaEtapa != "Compras" && solicitacao.etapaAtual != solicitacao.etapaAnterior) {
                navigate("/centralTarefas");
              }
            }).catch((err => {
              console.error(err);
              toast.error(JSON.stringify(err.response.data));
            }));
          }
          else {
            // só voltar para central tarefas se proxima tarefas nao for 'compras' e nao travou na etapa atual:
            if (proximaEtapa != "Compras" && solicitacao.etapaAtual != solicitacao.etapaAnterior) {
              navigate("/centralTarefas");
            }
          }
        }).catch(function (err) {
          console.error(err);
          toast.error(JSON.stringify(err.response.data));
        })
      })
      .catch(function (err) {
        //toast.dismiss();
        console.error(err);
        toast.update(id_toast, { render: "Ocorreu um erro ao salvar o processo, consulte o log!", type: "error", isLoading: false, autoClose: 4000 });
        toast.error(JSON.stringify(err.response.data));
      });
  }

  const definirStatus = (decisao) => {
    //se aprovado ou reprovado, continua em andamento:
    if ((decisao == "Aprovado") || (decisao == "Reprovado")) {
      formulario.status = 1;
      return;
    }
    if (decisao == "Cancelado") {
      formulario.status = 2;
      return;
    }
    if (decisao == "Concluído") {
      formulario.status = 3;
      return;
    }
  }

  const montaEvolucao1102 = async (proximaEtapa, formulario, solicitacao) => {
    let pedidoEvolucao1102 = {};
    //checa a etapa após o aprov. tec:
    if ((proximaEtapa == "Compras") || (proximaEtapa == "Aprov. Gerencial")) {
      //Header do pedido 1102 (1102 nao precisa enviar itens):
      pedidoEvolucao1102 = {
        CODCOLIGADA: formulario.coligada,
        CODMOVIMENTODESTINO: "1.1.02",
        CODMOVIMENTOORIGEM: "1.1.01",
        IDMOV: formulario.numeroMov1,
        ID01: formulario.numeroMov1,
        CODUSUARIO: usuarioLogin,
        ITENS: [{ QUANTIDADE: 1, SEQUENCIA: 1 }],
        SERIE: "RMA",
        PROCESSO_ID: formulario.id,
        CODLOC: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque,
        CODFILIAL: formulario.filial,
      }
      //Checar se ainda nao foi gerado 1102, caso já tenha gerado, pular para os movimentos seguintes:
      if (solicitacao.numeroMov2 == null) {
        let id_toast = toast.loading("Salvando movimento 1.1.02..");
        //Requisicao para gerar movimento 1102
        await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
          pedidoEvolucao1102, {
          timeout: 100000
        }
        ).then(async function (response) {
          let retorno = response.data;
          let retornoPrefixo = retorno.substring(0, 2);
          if (retornoPrefixo == "1;") {
            toast.update(id_toast, { render: "O movimento 1.1.02 foi Salvo com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            //atribuir 1102 para a solicitacao que será atualizada no laravel:
            solicitacao.numeroMov2 = parseInt(retorno.split(";")[1]);
            //gerar os demais mov 1103/04/21
            await montaEvolucaoPedido(solicitacao.numeroMov2, proximaEtapa, formulario, solicitacao);
          }//if-sucesso-1102
          else {
            setErroEvoluir(true); //sinaliza que deu erro
            solicitacao.etapaAtual = etapaAtual;
            const mensagemErro = "Aviso do RM ao gerar movimento 1.1.02: " + response.data.split("=======================================")[0];
            toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
        }).catch(async function (err) {
          setErroEvoluir(true);
          solicitacao.etapaAtual = etapaAtual;
          console.error(err);
          let mensagemErro = "Aviso do RM ao gerar movimento 1.1.02: " + err.response.data;
          //erro especifico : data da necessidade menor que a do movimento 1101, nao vai evoluir:
          if (mensagemErro.includes("Data de Previsão de entrega") && mensagemErro.includes("não pode ser menor que Data de Emissão")){
            mensagemErro = "Aviso do R.M ao gerar movimento 1.1.02: "+
            " A Data de necessidade não pode ser menor que a data de emissão do movimento 1.1.01. É necessário retornar o pedido ao solicitante para atualizar a data de necessidade.";
          }

          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });

          let erroCompleto = JSON.stringify(err.response.data);
          if (erroCompleto.includes("Total de Erros")){
            erroCompleto = erroCompleto.split("Total de Erros")[0];
          }
          toast.error(erroCompleto);
        })
      } else {
        //se já existe mov 1102, chamar a geracao dos demais movs:
        await montaEvolucaoPedido(solicitacao.numeroMov2, proximaEtapa, formulario, solicitacao);
      }

    }
  }

  const montaEvolucaoPedido = async (numeroMov2, proximaEtapa, formulario, solicitacao) => {
    // caso a próxima etapa seja 'Compras', a etapa atual fará evolução dos movimentos 1103 1104 1121:
    let pedidoEvolucao1103 = {};
    let pedidoEvolucao1104 = {};
    let pedidoEvolucao1121 = {};

    let itensBaixar = [];
    let itensComprar = [];
    let itensBaixarContrato = [];

    let temEtapaAprovacaoCompras = false;

    let id_toast = "meuToast10";
    if (proximaEtapa == "Aprov. Gerencial") {
      // Header do pedido 1103 (baixa estoque):
      pedidoEvolucao1103 = {
        CODCOLIGADA: formulario.coligada,
        CODMOVIMENTODESTINO: "1.1.03",
        CODMOVIMENTOORIGEM: "1.1.02",
        IDMOV: null,
        ID01: formulario.numeroMov1,
        CODUSUARIO: usuarioLogin,
        SERIE: "BX2",
        PROCESSO_ID: 0,
        CODLOC: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque,
        CODFILIAL: formulario.filial,
        ITENS: []
      }

      //header do pedido 1104 (solicitacao de compra):
      // pedidoEvolucao1104 = {
      //   CODCOLIGADA: formulario.coligada,
      //   CODMOVIMENTODESTINO: "1.1.04",
      //   CODMOVIMENTOORIGEM: "1.1.02",
      //   IDMOV: null,
      //   ID01: formulario.numeroMov1,
      //   CODUSUARIO: usuarioLogin,
      //   SERIE: "PC",
      //   PROCESSO_ID: 0,
      //   CODLOC: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque,
      //   CODFILIAL: formulario.filial,
      //   ITENS: []
      // }

      //header do pedido 1121 (baixa de contrato):
      pedidoEvolucao1121 = {
        CODCOLIGADA: formulario.coligada,
        CODMOVIMENTODESTINO: "1.1.21",
        CODMOVIMENTOORIGEM: "1.1.02",
        IDMOV: null,
        ID01: formulario.numeroMov1,
        CODUSUARIO: usuarioLogin,
        SERIE: "RC",
        PROCESSO_ID: 0,
        CODLOC: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque,
        CODFILIAL: formulario.filial,
        ITENS: []
      }
      //montar itens de cada pedido (1103/1104/1121)
      solicitacao.itens.forEach((item, index) => {
        if (item.marcado || !osVinculada) {
          let qtdAtender = 0; // quantidade que falta para concluir o pedido feito
          let itemQtdAComprar = 0;
          let itemSaldoEstoque = item.estoque;
          let itemSaldoContrato = item.saldoContrato;
          //se nao for usar saldo estoque/contrato, zerar saldo estoque/contrato para nao subtrair da quantidade a comprar:
          if (item.naoUsarEstoque) {
            itemSaldoEstoque = 0;
          }
          if (item.naoUsarContrato) {
            itemSaldoContrato = 0;
          }

          //CASO 1 - Baixa de estoque:
          //se ha qtd no estoque , enviar item para baixa com a qtd disponivel do estoque:
          if (item.naoUsarEstoque == false) {
            let qtdParaBaixarEstoque = 0;
            //se o saldo estoque me atende totalmente:
            if (item.quantidadeProduto <= parseInt(itemSaldoEstoque)) {
              qtdParaBaixarEstoque = item.quantidadeProduto;
              qtdAtender = 0;
            } else if (item.quantidadeProduto > parseInt(itemSaldoEstoque)) {
              qtdParaBaixarEstoque = itemSaldoEstoque;
              qtdAtender = parseInt(item.quantidadeProduto) - parseInt(itemSaldoEstoque)
            }

            if (qtdParaBaixarEstoque > 0) {
              let obj = {
                QUANTIDADE: qtdParaBaixarEstoque,
                SEQUENCIA: parseInt(index) + 1
              }
              itensBaixar.push(obj);
              item.quantidadeBaixarRM = qtdParaBaixarEstoque;
            }
          } else {
            //se usuario nao usará estoque, quantidade atender é a mesma qtd que foi solicitado
            qtdAtender = item.quantidadeProduto;
          }

          //CASO 2 - Saldo Contrato, se houver
          //se há saldo de contrato, enviar item para baixa de contrato com a qtd disponível do contrato:
          if (item.naoUsarContrato == false) {
            let qtdParaBaixarContrato = 0;

            if (qtdAtender <= parseInt(itemSaldoContrato)) {
              qtdParaBaixarContrato = qtdAtender;
              qtdAtender = 0;
            } else if (qtdAtender > parseInt(itemSaldoContrato)) {
              qtdParaBaixarContrato = itemSaldoContrato;
              qtdAtender = qtdAtender - parseInt(itemSaldoContrato);
            }

            if (qtdParaBaixarContrato > 0) {
              let obj = {
                QUANTIDADE: qtdParaBaixarContrato,
                SEQUENCIA: parseInt(index) + 1
              }
              itensBaixarContrato.push(obj);
              item.quantidadeBaixarContratoRM = qtdParaBaixarContrato;
            }
          }

          //Caso 3 - Comprar
          //se o saldo de estoque e saldo contrato ainda nao atendeu a qtd solicidade, vamos comprar:
          itemQtdAComprar = qtdAtender;
          //se ha qtd a comprar, item vai virar solicitacao de compra com a qtd necessario a comprar:
          if (itemQtdAComprar > 0) {
            temEtapaAprovacaoCompras = true; // aqui informa se haverá ou não, uma etapa de aprovacao dos itens a comprar: 
            // let obj = {
            //   QUANTIDADE: itemQtdAComprar,
            //   SEQUENCIA: parseInt(index) + 1
            // }
            // itensComprar.push(obj);
            item.quantidadeComprarRM = itemQtdAComprar;
          }

        }
      });

      //se tem itens a baixar, anexar no pedidoEvolucao1103
      if (itensBaixar.length > 0) {
        //solicitacao.etapaAtual = "Baixas"; //alterando nome final da requisicao de material:
        pedidoEvolucao1103.ITENS = itensBaixar;
      }
      //se tem itens a baixar do contrato, anexar no pedidoEvolucao1121
      if (itensBaixarContrato.length > 0) {
        //solicitacao.etapaAtual = "Baixas";
        pedidoEvolucao1121.ITENS = itensBaixarContrato;
      }
      //Se tem itens com qtds calculadas, salvar primeiro no laravel para nao perder dados:
      if (itensBaixar.length > 0 || itensBaixarContrato.length > 0) {
        await atualizaSolicitacaoLaravel(solicitacao);
      }

      //Necessário atribuir o numero 1.1.02 nos próximos movimentos
      pedidoEvolucao1103.IDMOV = numeroMov2;
      //pedidoEvolucao1104.IDMOV = numeroMov2;
      pedidoEvolucao1121.IDMOV = numeroMov2;

      //Se há itens a baixar, Gerar movimento 1103
      if (pedidoEvolucao1103.ITENS.length > 0 && solicitacao.numeroMov3 == null) {



        let sucessoAoGerar1103 = false;
        for (let i = 0; i < 3; i++) {
          //a cada tentativa tenho que mudar a SERIE para salvar no RM:
          if (i == 1) {
            pedidoEvolucao1103.SERIE = "BX3"
          } else if (i == 2) {
            pedidoEvolucao1103.SERIE = "BX4"
          }
          const retorno = await gerarMovimento(pedidoEvolucao1103, "1103", solicitacao);
          if (retorno) {
            sucessoAoGerar1103 = true;
            //atribuir 1103 true para a solicitacao que será atualizada no laravel:
            solicitacao.movimento1103 = sucessoAoGerar1103;
            if (pedidoEvolucao1121.ITENS.length == 0) {
              navigate("/centralTarefas");
            }
            break;
          }
        }
        if (!sucessoAoGerar1103) {
          setErroEvoluir(true); //sinaliza que dá erro
          solicitacao.etapaAtual = etapaAtual;
          toast.update(id_toast, { render: "Não foi possível gerar o movimento 1.1.03 neste momento, tente novamente em alguns instantes.", type: "error", isLoading: false, closeOnClick: true });

        }
      }//bloco-1103

      //Gerar movimento 1121
      if (pedidoEvolucao1121.ITENS.length > 0 && solicitacao.numeroMov21 == null) {
        let sucessoAoGerar1121 = false;
        // for (let i = 0; i < 3; i++) {
        const retorno = await gerarMovimento1121New(solicitacao);
        if (retorno) {
          sucessoAoGerar1121 = true;
          //atribuir 1121 true para a solicitacao que será atualizada no laravel:
          solicitacao.movimento1121 = sucessoAoGerar1121;
          navigate("/centralTarefas");
          // break;
        }
        // }
        if (!sucessoAoGerar1121) {
          setErroEvoluir(true); //sinaliza que dá erro
          solicitacao.etapaAtual = etapaAtual;
          toast.update(id_toast, { render: "Não foi possível gerar o movimento 1.1.21 neste momento, tente novamente em alguns instantes.", type: "warning", isLoading: false, closeOnClick: true });
        }
      }//bloco-1121

      //Se nao existir itens a comprar nesta solicitação, o processo já irá terminar em 'Baixas':
      if (temEtapaAprovacaoCompras == false) {
        solicitacao.etapaAtual = "Baixas";
        navigate("/centralTarefas");
      }

    }
    //checa a etapa após o aprovador de compras (analise critica)
    else if (proximaEtapa == "Compras") {
      //header do pedido 1104 (solicitacao de compra):
      pedidoEvolucao1104 = {
        CODCOLIGADA: formulario.coligada,
        CODMOVIMENTODESTINO: "1.1.04",
        CODMOVIMENTOORIGEM: "1.1.02",
        IDMOV: numeroMov2,
        ID01: formulario.numeroMov1,
        CODUSUARIO: usuarioLogin,
        SERIE: "PC",
        PROCESSO_ID: 0,
        CODLOC: osVinculada ? formulario.localEstoque.split(" - ")[0] : formulario.localEstoque,
        CODFILIAL: formulario.filial,
        ITENS: [],
        CODVEN: compradorSelected.codigo,
      }
      //montar itens de cada pedido (1103/1104/1121)
      solicitacao.itens.forEach((item, index) => {
        if (item.marcado || !osVinculada) {
          //se tem quantidade comprar, gerar mov 1104
          if (item.quantidadeComprarRM && item.quantidadeComprarRM > 0) {
            let obj = {
              QUANTIDADE: item.quantidadeComprarRM,
              SEQUENCIA: parseInt(index) + 1
            }
            itensComprar.push(obj);
          }
        }
      });
      //se tem itens a comprar, anexar no pedidoEvolucao1104
      if (itensComprar.length > 0) {
        //Atualiza solicitacoa no laravel, para nao perder calculo de qtds:
        await atualizaSolicitacaoLaravel(solicitacao);

        solicitacao.etapaAtual = "Compras"; //etapa atual da solicitacao será compras:
        pedidoEvolucao1104.ITENS = itensComprar;
        //Se há itens a comprar, Gerar movimento 1104
        if (solicitacao.numeroMov4 == null) {
          let sucessoAoGerar1104 = false;
          const retorno = await gerarMovimento(pedidoEvolucao1104, "1104", solicitacao);
          if (retorno) {
            sucessoAoGerar1104 = true;
            //atribuir 1104 true para a solicitacao que será atualizada no laravel:
            solicitacao.movimento1104 = sucessoAoGerar1104;
            navigate("/centralTarefas");
          }
          if (!sucessoAoGerar1104) {
            setErroEvoluir(true);
            solicitacao.etapaAtual = etapaAtual;
            toast.update(id_toast, { render: "Não foi possível gerar o movimento 1.1.04 neste momento, tente novamente em alguns instantes.", type: "error", isLoading: false, closeOnClick: true });
          }
        }//bloco-1104
      } else {
        //se nao tem itens a comprar, finaliza a etapa finda em baixas;
        solicitacao.etapaAtual = "Baixas";
        navigate("/centralTarefas");
      }
    }//etapa compras
  }

  const atualizaSolicitacao1101 = async (formulario, etapaAtual, numeroProcesso, solicitacao) => {
    let itensMovimento = [];
    let campoOSSelecionado = false;

    formulario.itens.forEach(item => {
      campoOSSelecionado = item.marcado ? true : campoOSSelecionado;
      if (item.marcado || !osVinculada) {

        itensMovimento.push(
          {
            idprd: item.idPrd,
            quantidadeAReceber: item.quantidadeProduto,
            ultimoValor: null,
            PRECOALVO: null,
            NUMOS: numeroOS,
            IDOBJOF: item.idObjetoOficina,
            codUnd: item.CODUND ? item.CODUND : item.unidade,
            N_SEQ_OS: osVinculada ? item.N_SEQ_OS : 0,
          }
        )
      }
    });

    //Variaveis que precisam de remover a descricao e enviar apenas o código para salvar:
    let formLocalEstoque = null;
    let formCentroCusto = null;
    let formDepartamento = null;
    if (formulario.localEstoque.includes(" - ")) {
      formLocalEstoque = formulario.localEstoque.split(" - ")[0];
    } else {
      formLocalEstoque = formulario.localEstoque;
    }
    if (formulario.centroCusto.includes(" - ")) {
      formCentroCusto = formulario.centroCusto.split(" - ")[0];
    } else {
      formCentroCusto = formulario.centroCusto;
    }
    if (formulario.departamento.includes(" - ")) {
      formDepartamento = formulario.departamento.split(" - ")[0];
    } else {
      formDepartamento = formulario.departamento;
    }

    //Atualizar movimento 1101 no RM:
    const pedidoAtualizado1101 = {
      CODCOLIGADA: formulario.coligada,
      ITENS: itensMovimento,
      CODFILIAL: formulario.filial,
      CODLOC: formLocalEstoque,
      VALORBRUTO: null,
      CODCCUSTO: formCentroCusto,
      USUARIO: usuarioLogin,
      MARCA: null,
      APLICACAO: formulario.aplicacao,
      JUSTFIOBS: formulario.justificativa,
      DATAENTREGA: formulario.dataNecessidade,
      CAMPOLIVRE1: '',
      CAMPOLIVRE2: '',
      CAMPOLIVRE3: osVinculada ? numeroOS : '',
      HISTORICOLONGO_TMOV: formulario.observacoesSolicitacao,
      HISTORICOLONGO_TITMMOV: formulario.observacoesSolicitacao,
      OPERACAO: "A", // Operação Atualizacao
      MOTIVOCANCELAMENTO: "",
      NUMEROMOV: parseInt(formulario.numeroPms), // Atualizacao possui o numero do movimento.
      CODDEPARTAMENTO: formDepartamento,
      TIPOPEDIDO: formulario.tipoPedido,
      CRITERIO: formulario.criterio,
      QTDITENS: itensMovimento.length,
      IDMOV_OS: osVinculada ? formulario.IDMOV_OS : 0
    }

    //TODO: Excecao Pedido de O.S - caso O.S, criar um 1.1.01 ao inves de atualizar:
    if (formulario.numeroOs != ""){
      pedidoAtualizado1101.OPERACAO = "S";
      console.log("Atualiza com OPERACAO S");
    }

    let id_toast = toast.loading("Atualizando movimento 1.1.01..");



    let retorno = await axios.post("https://almox.pelicano.eng.br/php/movimento/1.1.01/PedidoMateriais.php",
      pedidoAtualizado1101, {
      timeout: 100000
    }
    ).then(function (response) {
      let retorno = response.data;

      let retornoPrefixo = retorno.substring(0, 2);
      if (retornoPrefixo == "1;") {
        toast.update(id_toast, { render: "Movimento 1.1.01 Atualizado com Sucesso!", type: "success", isLoading: false, autoClose: 2000 });
        //Excecao OS - se for OS, recriar 1.1.01 e salvar o novo num OS na solicitacao
        if(formulario.numeroOs != ""){
          solicitacao.numeroMov1 = retorno.split(";")[1]; //guardar o numeroMov 1101 na solicitacao
          solicitacao.numeroPms = retorno.split(";")[1];
        }
      }//if-sucesso-1101
      else {
        setErroEvoluir(true);
        solicitacao.etapaAtual = etapaAtual; //caso erro, mantem na etapa atual

        let mensagemErro = "";
        if (response.data.includes("=======================================")) {
          mensagemErro = response.data.split("=======================================")[0];
        }
        if (mensagemErro.includes("Série RM:")) {
          mensagemErro = mensagemErro.split("Série RM:")[1];
        }
        if (mensagemErro.includes("00:00:00")) {
          mensagemErro = mensagemErro.replaceAll("00:00:00", "");
        }
        // const mensagemErro = "Aviso do RM: " + response.data.split("=======================================")[0];
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }

    }).catch(function (err) {
      setErroEvoluir(true);
      solicitacao.etapaAtual = etapaAtual;
      const mensagemErro = "Aviso do RM: " + err.data.split("=======================================")[0];
      toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      // toast.update(id_toast, { render: "Erro ao tentar atualizar movimento 1101, consulte o log!", type: "error", isLoading: false, autoClose: 2000 });
      console.error(err);
      toast.error(JSON.stringify(err.response.data));
    })
  }

  const gerarMovimento = async (jsonEvolucao, numero_movimento, solicitacao) => {
    /*
      Método genérico que serve para salvar os movimentos 1103, 1104 e 1121 (todos retornam "1" ao serem bem-sucedidos):
     */
    console.log("jsonEvolucao " + numero_movimento);
    console.log(jsonEvolucao);

    let id_toast = toast.loading("Gerando movimento " + numero_movimento + "..");
    try {
      const response = await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
        jsonEvolucao, {
        timeout: 100000
      });
      let retorno = response.data;
      let retornoPrefixo = retorno.substring(0, 2);

      console.log("RETORNO Gerar Movimento");
      console.log(retorno);

      if (retornoPrefixo == "1;") {
        //salvar o numero do movimento gerado na solicitacao:
        if (numero_movimento == "1103") {
          solicitacao.numeroMov3 = parseInt(retorno.split(";")[1]);
        }
        else if (numero_movimento == "1104") {
          solicitacao.numeroMov4 = parseInt(retorno.split(";")[1]);
        }
        else if (numero_movimento == "1121") {
          solicitacao.numeroMov21 = parseInt(retorno.split(";")[1]);
        }

        toast.update(id_toast, { render: "O movimento " + numero_movimento + " foi Salvo com sucesso no RM!", type: "success", isLoading: false, closeOnClick: true });
        return true;
      } else {
        let substring = "=======================================";
        if (response.data.includes(substring)) {
          const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + response.data.split("=======================================")[0];
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        else {
          console.log("RETORNO Erro Gerar Movimento");
          console.log(response);

          const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        return false;
      }
    } catch (err) {
      console.log("catch erro gerarmov");
      console.log(err);

      let substring = "=======================================";
      if (err.response.data.includes(substring)) {
        const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + err.response.data.split("=======================================")[0];
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }
      else {
        // const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
        let mensagemErro = "Aviso do RM ao gerar movimento " + numero_movimento + ": " + err.response.data;
        //erro especifico : data da necessidade menor que a do movimento 1101, nao vai evoluir:
        if (mensagemErro.includes("Data de Previsão de entrega") && mensagemErro.includes("não pode ser menor que Data de Emissão")){
          mensagemErro = "Aviso do R.M ao gerar movimento "+ numero_movimento + ": "+
          "A Data de necessidade não pode ser menor que a data de emissão do movimento 1.1.01. É necessário retornar o pedido ao solicitante para atualizar a data de necessidade.";
        }
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });

        let erroCompleto = JSON.stringify(err.response.data);
        if (erroCompleto.includes("Total de Erros")){
          erroCompleto = erroCompleto.split("Total de Erros")[0];
        }
        toast.error(erroCompleto);

      }

      return false;
    }
  }

  const gerarMovimento1121 = async (solicitacao) => {
    /*
      Método genérico que serve para salvar o movimento 1121 :
     */
    // primeiro salva a solicitacao no laravel
    let sucesso = false;
    let id_toast = toast.loading("Salvando alterações...", { toastId: "toast" });
    api.put(`/solicitacoes/` + numeroProcesso,
      solicitacao,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
      .then(async function ({ data }) {
        toast.update("toast", { render: "Gerando Mov 1.1.21!", type: "success", isLoading: false, autoClose: 4000 });
        //PASSO 2 - Salvou no laravel, agora salva o mov 1.1.21
        let json = {
          "solicitacao_id": solicitacao.id
        }
        try {
          const response = await api.post('/movimentoContrato/',
            json, {
            timeout: 100000
          });


          let retorno = response.data;
          let retornoPrefixo = retorno.substring(0, 2);

          if (retornoPrefixo == "1;") {
            //salvar o numero do movimento gerado na solicitacao:
            solicitacao.numeroMov21 = parseInt(retorno.split(";")[1]);
            toast.update("toast", { render: "O movimento movimento 1.1.21 foi Salvo com sucesso!", type: "success", isLoading: false, closeOnClick: true });
            sucesso = true;
          } else {
            let substring = "=======================================";
            if (response.data.includes(substring)) {
              const mensagemErro = "Aviso ao salvar movimento movimento 1.1.21: " + response.data.split("=======================================")[0];
              toast.update("toast", { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
            }
            else {
              const mensagemErro = "O sistema encontrou uma inconsistência ao salvar o movimento movimento 1.1.21. Para mais informações, consulte o log";
              toast.update("toast", { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
            }
            sucesso = false;
          }
        } catch (err) {
          console.log("resposta-Error-laravel:")
          console.log(err);
          let substring = "=======================================";
          if (err.data.includes(substring)) {
            const mensagemErro = "Aviso ao salvar movimento 1.1.21: " + err.data.split("=======================================")[0];
            toast.update("toast", { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
          else {
            const mensagemErro = "O sistema encontrou uma inconsistência ao salvar o movimento movimento 1.1.21. Para mais informações, consulte o log";
            toast.update("toast", { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
          sucesso = false;
        }
      })
      .catch(function (err) {
        //toast.dismiss();
        console.error(err);
        toast.update("toast", { render: "Ocorreu um erro ao salvar a solicitação. Tente novamente em instantes!", type: "error", isLoading: false, autoClose: 4000 });
        toast.error(JSON.stringify(err.response.data));
      });

    return sucesso;
  }

  const atualizaSolicitacaoLaravel = async (solicitacao) => {
    /*
      Método que serve para salvar(no laravel) alterações da solicitação para salvar qtds calculadas, após gerado o 1.1.02 no rm.
     */
    // primeiro salva a solicitacao no laravel

    let copiaSolicitacao = JSON.parse(JSON.stringify(solicitacao));
    copiaSolicitacao.ignorarFluxo = true;

    let id_toast = toast.loading("Atualizando dados...");
    await api.put(`/solicitacoes/` + numeroProcesso,
      copiaSolicitacao,
      { headers: { 'Authorization': 'Bearer ' + token } }
    )
      .then(async function ({ data }) {
        toast.update(id_toast, { render: "Alterações salvas!", type: "success", isLoading: false, autoClose: 4000 });
        return true;
      }).catch(function (err) {
        //toast.dismiss();
        console.error(err);
        toast.update(id_toast, { render: "Ocorreu um erro ao salvar alterações da solicitação. Tente novamente em instantes!", type: "error", isLoading: false, autoClose: 4000 });
        return false;
        toast.error(JSON.stringify(err.response.data));
      });

  }

  const gerarMovimento1121New = async (solicitacao) => {
    /*
      Método genérico que serve para salvar o movimento 1121 :
     */
    let id_toast = toast.loading("Salvando mov. 1.1.21...", { toastId: "toast" });
    let json = {
      "solicitacao_id": solicitacao.id
    }
    try {
      const response = await api.post('/movimentoContrato/',
        json, {
        timeout: 100000
      });


      let retorno = response.data;
      let retornoPrefixo = retorno.substring(0, 2);

      if (retornoPrefixo == "1;") {
        //salvar o numero do movimento gerado na solicitacao:
        solicitacao.numeroMov21 = parseInt(retorno.split(";")[1]);

        toast.update(id_toast, { render: "O movimento movimento 1.1.21 foi Salvo com sucesso!", type: "success", isLoading: false, closeOnClick: true });
        return true;
      } else {
        let substring = "=======================================";
        if (response.data.includes(substring)) {
          const mensagemErro = "Aviso ao salvar movimento movimento 1.1.21: " + response.data.split("=======================================")[0];
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        else {
          const mensagemErro = "O sistema encontrou uma inconsistência ao salvar o movimento movimento 1.1.21. Para mais informações, consulte o log";
          toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
        }
        return false;
      }
    } catch (err) {

      let substring = "=======================================";
      if (err.data.includes(substring)) {
        const mensagemErro = "Aviso ao salvar movimento 1.1.21: " + err.data.split("=======================================")[0];
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }
      else {
        const mensagemErro = "O sistema encontrou uma inconsistência ao salvar o movimento movimento 1.1.21. Para mais informações, consulte o log";
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
      }
      return false;
    }
  }

  const handleAutocompleteEtapaARetornar = (event, newValue) => {
    setEtapaARetornar(newValue);
  };

  const determinaCriterioAtendimento = () => {
    /* 
    Com base no lead time cadastrado, o sistema automatiza o campo critério de atendimento,
    caso a data de necessidade seja menor que o lead time o sistema irá marcar como urgente,
    caso contrário critério normal. 
    */
    //só funciona se usuario definir data necessidade e tipo pedido:
    if (formulario.dataNecessidade && formulario.tipoPedido) {
      let dataAtual = new Date();
      let dataNecessidade = new Date(formulario.dataNecessidade);
      // Convertendo ambas as datas para milissegundos
      let dataAtualEmMilissegundos = dataAtual.getTime();
      let dataNecessidadeEmMilissegundos = dataNecessidade.getTime();
      // Calculando a diferença em milissegundos
      let diferencaEmMilissegundos = dataNecessidadeEmMilissegundos - dataAtualEmMilissegundos;
      // Convertendo a diferença para dias
      let diasRestantesDataNecessidade = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

      let objetoEncontrado = listaLeadTimePedidos.find(objeto => objeto.tipoPedido == formulario.tipoPedido);
      if (objetoEncontrado) {
        if (diasRestantesDataNecessidade < objetoEncontrado.prazo) {
          setFormulario(prevState => ({
            ...prevState,
            criterio: "UR",
          }));
        } else {
          setFormulario(prevState => ({
            ...prevState,
            criterio: "NO",
          }));
        }
      }
    }
  }

  const buscarFornecedor = () => {
    if (nomeFornecedorBuscado.length >= 3) {
      const id = toast.loading("Consultando fornecedores..");
      realizaConsulta('01.001.021', { params: { 'NOMEFANTASIA': nomeFornecedorBuscado } })
        .then(function ({ data }) {
          if (data.length > 0) {
            toast.update(id, { render: "Fornecedor(es) encontrado(s)", type: "success", isLoading: false, autoClose: 3000 });
            setNomeFornecedorBuscado("");
            setFornecedoresResultBusca(data);
            setOpenModalListaFornecedor(true);
            return;
          }
          toast.update(id, { render: "Nenhum fornecedor foi encontrado!", type: "info", isLoading: false, autoClose: 3000 });
        }).catch(function (err) {
          toast.update(id, { render: "Fornecedor não encontrado.", type: "error", isLoading: false, autoClose: 3000 });
          setNomeFornecedorBuscado("");
          toast.error(JSON.stringify(err.response.data));
        });
    } else {
      toast.warning("Informe pelo menos 3 caracteres para buscar fornecedor.");
    }
  }


  const buscarClassificacaoRD = () => {
    if (nomeClassificacaoRDBuscado.length > 3) {
      // const id = toast.loading("Consultando fornecedores..");
      realizaConsulta('01.001.022', {
        params: {
          'CODCOLIGADA': '1',
          'DESCRICAO': nomeClassificacaoRDBuscado
        }
      }).then(function ({ data }) {
        // toast.update(id, { render: "Fornecedor(es) encontrado(s)", type: "success", isLoading: false, autoClose: 1000 });
        setNomeClassificacaoRDBuscado("");
        setClassificacaoRDResultBusca(data);
        setOpenModalListaClassificacaoRD(true);
      }).catch(function (err) {
        // toast.update(id, { render: "Fornecedor não encontrado.", type: "error", isLoading: false, autoClose: 2000 });
        setNomeClassificacaoRDBuscado("");
        toast.error(JSON.stringify(err.response.data));
      });
    } else {
      alert("Informe ao menos 3 dígitos para buscar classificação RD.");
    }
  }


  const addVendorList = (objeto) => {
    /* 
      Adicionar fornecedor selecionado pelo usuario ao vendor list.
    */
    if ((fornecedoresSelecionados.length > 0) &&
      (fornecedoresSelecionados.find(item => item.CNPJ == objeto.CNPJ))) {
      toast.warning("Fornecedor já existe na tabela");
      return;
    }
    if ((mostrarCamposPedidoFornecedor) && (fornecedoresSelecionados.length == 1)) {
      //caso seja pedido de contrato, só pode adicionar um fornecedor
      toast.warning("Em solicitação de pedido de contrato, só é permitido adicionar um fornecedor.");
      return;
    }

    let dadosFormatados = {
      NOMEFANTASIA: objeto.NOMEFANTASIA,
      NOME: objeto.NOME,
      CNPJ: objeto.CNPJ,
      CODCFO: objeto.CODCFO
    }

    if (mostrarCamposPedidoFornecedor) {
      //se pedido contrato, preencher o campo contratoFornecedor
      atualizarFormulario("contratoFornecedor", dadosFormatados.CODCFO);
      setFornecedoresSelecionados([...fornecedoresSelecionados, dadosFormatados]);
    } else {
      setFornecedoresSelecionados([...fornecedoresSelecionados, dadosFormatados]);
    }

  }


  const removeVendorListItem = (cnpjParaRemover) => {
    /* Remover da lista de fornecedores indicados um fornecedor específico. */
    const newArray = fornecedoresSelecionados.filter((objeto) => objeto.CNPJ !== cnpjParaRemover);
    setFornecedoresSelecionados(newArray);

    if (mostrarCamposPedidoFornecedor && newArray.length == 0) {
      //se pedido contrato, limpar campo contratoFornecedor
      atualizarFormulario("contratoFornecedor", "");
    }
  }

  const addClassificacaoRD = (objeto) => {
    /* 
      Adicionar classificacao RD selecionada pelo usuario no modal de RD.
    */
    if ((classificacaoRDSelecionadas.length > 0) &&
      (classificacaoRDSelecionadas.find(item => item.CODTB2FLX == objeto.CODTB2FLX))) {
      toast.warning("Classificação RD já existe na tabela");
    }
    else {
      let dadosFormatados = {
        CODTB2FLX: objeto.CODTB2FLX,
        DESCRICAO: objeto.DESCRICAO
      }
      atualizarFormulario("contratoClassRD", dadosFormatados.CODTB2FLX);
      setNomeClassificacaoRDBuscado(dadosFormatados.CODTB2FLX);
      setClassificacaoRDVinculada(true);
      //setClassificacaoRDSelecionadas([...classificacaoRDSelecionadas, dadosFormatados]);
    }
  }

  const removeClassificacaoRD = (objeto) => {
    /* 
      Remove classificacao RD selecionada pelo usuario no modal de RD.
    */
    atualizarFormulario("contratoClassRD", "");
    setNomeClassificacaoRDBuscado("");
    setClassificacaoRDVinculada(false);
  }


  const handleRedirecionar = () => {
    // Redirecionar para o site desejado
    window.open('https://pelicanoconstrucoes142642.fluig.cloudtotvs.com.br/portal/p/1/pageworkflowview?processID=CadastroFornecedor', '_blank');
  };


  const buscarComprador = async () => {
    /* 
      Buscar lista de compradores do R.M
    */
    realizaConsulta('01.001.023', { params: {} })
      .then(function ({ data }) {

        let nomesCompradores = [];
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            nomesCompradores.push({ nome: data[i].NOME, codigo: data[i].CODVEN, compradorId: data[i].COMPRADORID });//TODO: pegar comprador id
          }
        }

        setCompradoresDisponiveis(nomesCompradores);
        return;
      }).catch(function (err) {
        console.error("Não funcionou buscarComprador");
        toast.error(JSON.stringify(err.response.data));
      });
  }

  const handleCompradorSelected = (event, newValue) => {
    setCompradorSelected(newValue);
  };


  const identificaMovimentosACancelar = async (solicitacao) => {
    /* Identificar todos os movimentos que precisam cancelar antes de voltar pro solicitante. */
    let numeroMovimento = null;
    let descricaoMovimento = "";
    let sucessoCancelar1102 = true;
    let sucessoCancelar1103 = true;
    let sucessoCancelar1121 = true;
    let sucessoCancelar1101 = true;

    if (solicitacao.numeroMov21 != null) {
      sucessoCancelar1121 = await cancelaMovimentoRequisicao(solicitacao, solicitacao.numeroMov21, "1.1.21");
    }
    if (solicitacao.numeroMov3 != null) {
      sucessoCancelar1103 = await cancelaMovimentoRequisicao(solicitacao, solicitacao.numeroMov3, "1.1.03");
    }
    if (solicitacao.numeroMov2 != null) {
      sucessoCancelar1102 = await cancelaMovimentoRequisicao(solicitacao, solicitacao.numeroMov2, "1.1.02");

    }
    if (solicitacao.numeroMov1 != null && solicitacao.numeroOs) {
      console.log("tem O.S e vai cancelar 1.1.01!");
      sucessoCancelar1101 = await cancelaMovimentoRequisicao(solicitacao, solicitacao.numeroMov1, "1.1.01");
    }

    console.log("Retorno apos tentar cancelar os movimentos ", sucessoCancelar1102 && sucessoCancelar1103 && sucessoCancelar1121 && sucessoCancelar1101);

    return sucessoCancelar1102 && sucessoCancelar1103 && sucessoCancelar1121 && sucessoCancelar1101; // retorna que deu problema

  }

  const cancelaMovimentoRequisicao = async (solicitacao, numeroMovimentoRM, descricaoMovimento) => {
    /* Cancela os movimentos caso o aprovador de compras reprove e retorne ao solicitante. */

    let jsonCancelaMovimento = {
      coligada: 1,
      idmov: numeroMovimentoRM //pode ser 1.1.02/1.1.03/1.1.21
    };

    let id_toast = toast.loading("Cancelando movimento");
    return axios.post(`https://api.pelicano.eng.br/api/totvs/cancela-movimento`,
      jsonCancelaMovimento, {
      timeout: 100000
    }
    ).then(async function (response) {
      let resp = response.data.result;
      console.log("retorno do cancelaMov", response.data.result);

      if (resp.includes("\\\"DataSetXML\\\":\\\"\\\",\\\"ErrorMessage\\\":null,\\\"ErrorType\\\":null")) {
        toast.update(id_toast, { render: "Movimento " + descricaoMovimento + " cancelado com sucesso.", type: "success", isLoading: false, autoClose: 2000 });

        // limpar da solicitacao os numeros dos movs cancelados no RM
        if(descricaoMovimento == "1.1.01") {
          solicitacao.numeroMov1 = null;
        }
        else if (descricaoMovimento == "1.1.02") {
          solicitacao.numeroMov2 = null;
        }
        else if (descricaoMovimento == "1.1.03") {
          solicitacao.numeroMov3 = null;
          solicitacao.movimento1103 = 0;
        }
        else if (descricaoMovimento == "1.1.21") {
          solicitacao.numeroMov21 = null;
          solicitacao.movimento1121 = 0;

          // id_toast = toast.loading("Cancelando requisição de contrato..");
          // api.post(`/cancelaRequisicaoContrato=${numeroMovimentoRM}`,
          //   {
          //     headers: {
          //       'Authorization': 'Bearer ' + token,
          //       'Content-Type': 'multipart/form-data'
          //     }
          //   }
          // ).then(function (response) {
          //   toast.update(id_toast, { render: "Requisição contrato cancelada com Sucesso!", type: "success", isLoading: false, autoClose: 3000 });
          // }).catch(function (err) {
          //   toast.update(id_toast, { render: "Requisição contrato não pode ser cancelada, pois pode ter sido movimentada!", type: "warning", isLoading: false, autoClose: 3000 });
          //   console.error(err);
          // })

        }//fim bloco 1.1.21

        return true; // Retorna true se o cancelamento for bem-sucedido

      } else {
        const decodedMsg = JSON.parse(resp);
        const mensagemErro = "Aviso do RM ao cancelar movimento " + descricaoMovimento + ": " + decodedMsg;
        toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });

        return false; // Retorna false se o cancelamento deu alguma restrição.
      }
    }).catch(function (err) {
      console.error("erro cancelaMovimento", err);
      toast.update(id_toast, { render: "O cancelamento do movimento " + descricaoMovimento + " não foi efetivado, consulte o log.", type: "warning", isLoading: false, closeOnClick: true });
      toast.error(JSON.stringify(err.response.data));
      return false; // Retorna false se o cancelamento deu erro.
    })
  }


  return (
    <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
      <Header mobile={mobile} notification />
      <Sidebar mobile={mobile} />

      <Stack
        ml={mobile ? "0px" : "250px"}
        mt="50px"
        flex={1}
        height={"calc(100% - 55px)"}
        p={mobile ? "20px" : "50px 50px 50px 80px"}
        // overflow="auto"
        overflow="hidden"
      >
        {/* Conteúdo da Tela */}
        <Typography level={"h4"}>Solicitação: {formulario.id} | Etapa atual: {etapaAtual}</Typography>

        <Stack flexDirection={mobile ? "column" : "row"} mt="10px" ml="auto">
          {numeroProcesso != "" ?
            <Stack flexDirection={"row"} alignItems={"center"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
              <Link sx={{ textAlign: "center" }} fontSize="16px" onClick={() => navigate("/acompanhamentoItens/" + numeroProcesso, { state: { processo: numeroProcesso, movimento: formulario.numeroMov1 } })} mr="20px">
                Acompanhamento dos Itens
              </Link>
              <Link sx={{ textAlign: "center" }} fontSize="16px" onClick={() => navigate("/fluxoAprovacao", { state: { processo: numeroProcesso, origemChamada: "/solicitacao/" } })} mr="20px">
                Fluxo de Aprovação
              </Link>
            </Stack>
            : null
          }
          <ToggleButtonGroup
            value={valueTogglePagina}
            onChange={(event, newValue) => newValue ? setValueTogglePagina(newValue) : null}
          >
            <Button value="formulario" sx={valueTogglePagina == "formulario" ? { fontWeight: "bold", width: "100%" } : { fontWeight: "normal", width: "100%" }}>Formulário</Button>
            <Button value="anexo" sx={valueTogglePagina == "anexo" ? { fontWeight: "bold", width: "100%" } : { fontWeight: "normal", width: "100%" }}>Anexos</Button>
            <Button value="laudoAvariaMotor" sx={valueTogglePagina == "laudoAvariaMotor" ? { fontWeight: "bold", width: "100%" } : { fontWeight: "normal", width: "100%" }}>Avaria Motor</Button>
            <Button value="laudoReformaPneu" sx={valueTogglePagina == "laudoReformaPneu" ? { fontWeight: "bold", width: "100%" } : { fontWeight: "normal", width: "100%" }}>Reforma Pneu</Button>
            <Button value="inspecaoMaterialRodante" sx={valueTogglePagina == "inspecaoMaterialRodante" ? { fontWeight: "bold", width: "100%" } : { fontWeight: "normal", width: "100%" }}>Material Rodante</Button>
          </ToggleButtonGroup>
        </Stack>

        {/* ABA FORMULARIO  */}
        {valueTogglePagina == "formulario" && renderizar && (
          <Stack>
            <Typography level="title-lg" mt="40px">
              Identificação
            </Typography>

            {(etapaAtual == "Solicitante") ?
              <Stack alignItems={"flex-start"} mt="10px" ml="auto" sx={mobile ? { width: "100%" } : { width: "300px" }}>
                <Typography level="h6" mr="20px" mb="5px">
                  {numeroProcesso == "" ? "Vincular a OS" : "OS Vinculada"}
                </Typography>
                <Stack flexDirection={"row"} justifyContent="flex-start" width={mobile ? "100%" : "300px"} sx={{ flex: 1 }}>
                  <Input value={numeroOS} onChange={(event) => setNumeroOS(event.target.value)} type="number" placeholder="Nº OS" disabled={(numeroProcesso != "" || osVinculada)} sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1 }} />
                  {etapaAtual == "Solicitante" ?
                    <Button /*disabled={numeroOS == ""}*/ disabled={desabilitarEtapasAprovacao || formulario.numeroPms} onClick={osVinculada ? desvincularOS : () => [formulario.numeroOs = numeroOS, vincularOS(formulario)]} size="sm" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: "100px" }}>{osVinculada ? "Desvincular" : "Vincular"}</Button>
                    : null}
                </Stack>
              </Stack>
              : null
            }

            <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
              <Stack flex={1} minWidth={mobile ? "100%" : "110px"}>
                <FormLabel>Coligada</FormLabel>
                <Select defaultValue={"1"} value={formulario.coligada} onChange={(event, newValue) => [atualizarFormulario("coligada", newValue)]} disabled={(etapaAtual != "Solicitante") || (osVinculada) || (formulario.usuario_id ? usuarioID != formulario.usuario_id : false)}>
                  {informacoesGerais.coligadas.map(function (coligada, indice) {
                    return (
                      <Option key={indice} value={coligada.split(" - ")[0]}>{coligada}</Option>
                    );
                  })}
                </Select>
              </Stack>

              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Filial</FormLabel>

                <Select key={informacoesGerais} defaultValue="1" value={formulario.filial} onChange={(event, newValue) => [atualizarFormulario("filial", newValue)]} disabled={(etapaAtual != "Solicitante") || (osVinculada) || (formulario.usuario_id ? usuarioID != formulario.usuario_id : false)}>
                  {informacoesGerais.filiais.map(function (filial, indice) {
                    return (
                      <Option key={indice} value={filial.split(" - ")[0]}>{filial}</Option>
                    );
                  })}
                </Select>
              </Stack>

              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Local de Estoque</FormLabel>
                {osVinculada || formulario.numeroOs || etapaAtual != "Solicitante" ?
                  <Input value={formulario.localEstoque} disabled />
                  :
                  <Select
                    key={informacoesGerais.locaisDeEstoque}
                    listboxOpen={codLocSelectOpen}
                    onListboxOpenChange={(isOpen) => formulario.itens.length ? toast.warn("Limpe a lista de itens antes de trocar o local de estoque.") : setCodLocSelectOpen(isOpen)}
                    value={formulario.localEstoque}
                    onChange={(event, newValue) => atualizarFormulario("localEstoque", newValue)}
                    disabled={desabilitaAprovadores}
                  >
                    {informacoesGerais.locaisDeEstoque.map(function (localEstoque, indice) {
                      return (
                        <Option key={indice} value={localEstoque.codigo}>{localEstoque.nome}</Option>
                      );
                    })}
                  </Select>
                }
              </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
              <Stack flex={1} minWidth={mobile ? "100%" : "110px"}>
                <FormLabel>Centro de Custo</FormLabel>
                {osVinculada || formulario.numeroOs || etapaAtual != "Solicitante" ?
                  // {osVinculada || etapaAtual != "Solicitante" ?
                  <Input value={formulario.centroCusto} disabled />
                  :
                  <Select value={formulario.centroCusto} onChange={(event, newValue) => atualizarFormulario("centroCusto", newValue)} disabled={desabilitaAprovadores}>
                    {informacoesGerais.centrosDeCusto.map(function (centroCusto, indice) {
                      return (
                        <Option key={indice} value={centroCusto.codigo}>{centroCusto.codigo} - {centroCusto.nome}</Option>
                      );
                    })}
                  </Select>
                }
              </Stack>

              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Departamento</FormLabel>

                {osVinculada || formulario.numeroOs || etapaAtual != "Solicitante" ?
                  <Input value={formulario.departamento} disabled />
                  :
                  <Select value={formulario.departamento.split(" - ")[0]} onChange={(event, newValue) => atualizarFormulario("departamento", newValue)} disabled={(etapaAtual != "Solicitante" || formulario.usuario_id ? usuarioID != formulario.usuario_id : false)}>
                    {formulario.departamentos.map((departamento, indice) => {
                      return (
                        <Option key={indice} value={departamento.CODDEPARTAMENTO}>{departamento.CODDEPARTAMENTO + " - " + departamento.NOME}</Option>
                      );
                    })}
                  </Select>
                }

              </Stack>

              <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Data da Necessidade</FormLabel>
                <Input type="date" value={formulario.dataNecessidade} onChange={(event) => atualizarFormulario("dataNecessidade", event.target.value)} disabled={desabilitarEtapasAprovacao} />
              </Stack>

              {osVinculada ?
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                  <FormLabel>Data Abertura da OS</FormLabel>
                  <Input type="date" disabled value={formulario.dataAberturaOS} />
                </Stack>
                : null
              }
            </Stack>

            {/* Pedido de transferencia de Material - Só funciona com pedido manual, e para habilitar só se tiver um item adicionado */}
            { formulario?.itens.length > 0 ?
              <DadosTransferenciaMaterial token={token} usuarioLogin={usuarioLogin} etapaAtual={etapaAtual} mobile={mobile} 
              osVinculada={osVinculada} filiais={informacoesGerais.filiais} atualizarFormulario={atualizarFormulario}/>
              :
              null
            }


            {osVinculada || formulario.numeroOs ?
              <Stack flexDirection={mobile ? "column" : "row"} justifyContent={mobile ? "flex-start" : "space-between"} mt="30px">
                <Stack flex={1} mr="30px" mt={mobile ? "20px" : ""}>
                  <FormLabel>Descrição do Equipamento</FormLabel>
                  <Input type="text" value={formulario.descricao} disabled />
                </Stack>
                <Stack flex={1} mr="30px" mt={mobile ? "20px" : ""}>
                  <FormLabel>Prefixo do Equipamento</FormLabel>
                  <Input type="text" value={formulario.prefixoEquipamento} disabled />
                </Stack>
                <Stack flex={1} mr="30px" mt={mobile ? "20px" : ""}>
                  <FormLabel>Marca</FormLabel>
                  <Input type="text" value={formulario.marca} disabled />
                </Stack>
                <Stack flex={1} mr="30px" mt={mobile ? "20px" : ""}>
                  <FormLabel>Modelo</FormLabel>
                  <Input type="text" value={formulario.modelo} disabled />
                </Stack>
                <Stack flex={1} mt={mobile ? "20px" : ""}>
                  <FormLabel>Horímetro</FormLabel>
                  <Input type="text" value={formulario.horimetro} disabled />
                </Stack>
              </Stack>
              : null
            }

            <Typography level="title-lg" mt="40px">
              Itens
            </Typography>
            {(etapaAtual == "Solicitante") && (!osVinculada) && (formulario.usuario_id ? usuarioID == formulario.usuario_id : true) ?
              <Stack mt="10px" ml="auto">
                <Stack flexDirection={"row"} width={mobile ? "95%" : "100%"}>
                  <Input value={nomeCodigoNovoItem} onChange={(event) => setNomeCodigoNovoItem(event.target.value)} placeholder="Código ou Descrição" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                  <Button size="sm" onClick={buscarItem} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Adicionar Item</Button>
                </Stack>
              </Stack>
              : null
            }

            {/* Na etapa aprovador gerencial/institucional, exibir um botão para mostrar também itens de somente baixas (visao mobile) */}
            {(etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras" || etapaAtual == "Compras") && mobile && (
              <Stack width={"250px"} flexDirection={"row"} alignItems={"center"} mr="30px" mt="10px" mb={"20px"}>
                <Button variant="outlined" onClick={() => setVerItensSomenteBaixa(!verItensSomenteBaixa)} sx={{ padding: "5px 15px" }}>
                  <Stack flexDirection={"row"} alignItems="center">
                    {verItensSomenteBaixa ?
                      <IoEye size={20} />
                      :
                      <IoEyeOff size={20} />
                    }
                    <Typography level="title-lg" fontSize={14} ml={"10px"}>{verItensSomenteBaixa ? "Ocultar requisição original" : "Ver requisição original"}</Typography>
                  </Stack>
                </Button>
              </Stack>
            )}

            <Stack mt="30px">
              {mobile ?
                <Stack>
                  {formulario.itens.map((item, indice) => {
                    return (
                      <div key={indice}>
                        {/* a <Stack> possui uma condicao, caso seja aprovador gerencial/institucional/analise-critica responsavel, ocultar itens que não possuem quantidade a comprar. */}
                        <Stack style={(etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras" || etapaAtual == "Compras") && item.quantidadeComprarRM == 0 && !verItensSomenteBaixa ? { display: "none" } : (etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras" || etapaAtual == "Compras") && item.quantidadeComprarRM == 0 && verItensSomenteBaixa ? { backgroundColor: "#ddd" } : {}} p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                          <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Código: </Typography>{item.codProduto}</Typography>
                            {/* {(formulario.itens[indice].movimentado.length != 0) ? null : <Checkbox checked={formulario.itens[indice].selecionado} onChange={(event) => atualizarFormulario("selecionado", event.target.checked, true, indice)} />} */}
                            {osVinculada && etapaAtual == "Solicitante" && (formulario.itens[indice].movimentado[0]) ? <Checkbox checked={item.marcado} onChange={(event) => atualizarFormulario("marcado", event.target.checked, true, indice)} /> : null}
                          </Stack>
                          <Typography level="body-sm"><Typography level="title-md">Item: </Typography>{indice + 1}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Descrição: </Typography>{item.descricao}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Saldo: </Typography>{item.estoque}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Qtd: </Typography>{item.quantidadeProduto}</Typography>

                          {formulario.numeroMov2 ? <Typography level="body-sm"><Typography level="title-md">Qtd Comprar: </Typography>{item.quantidadeComprarRM && item.quantidadeComprarRM}</Typography> : null}
                          {formulario.numeroMov3 ? <Typography level="body-sm"><Typography level="title-md">Qtd Estoque: </Typography>{item.quantidadeBaixarRM && item.quantidadeBaixarRM}</Typography> : null}
                          {formulario.numeroMov21 ? <Typography level="body-sm"><Typography level="title-md">Qtd Contrato: </Typography>{item.quantidadeBaixarContratoRM && item.quantidadeBaixarContratoRM}</Typography> : null}

                          {etapaAtual == "Solicitante" && osVinculada ? <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{item.movimentado[0] ? item.movimentado[0].STATUS : " - "}</Typography> : null}
                          {/* {formulario.itens[indice].movimentado.length != 0 ? <div onClick={() => [setModalMovimentacoesItem(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice])]} className="hover" style={{ marginTop: "20px", alignSelf: "center" }}><FaInfoCircle size={20} color="#444" /></div> : null} */}
                          {/* {formulario.itens[indice].movimentado.length != 0 ? <div onClick={() => [setModalMovimentacoesItem(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice])]} className="hover" style={{ marginTop: "20px", alignSelf: "center" }}><FaInfoCircle size={20} color="#444" /></div> : null} */}
                          {etapaAtual == "Solicitante" && osVinculada && item.movimentado[0] ? <div onClick={() => [setModalMovimentacoesItem(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice])]} className="hover"><FaInfoCircle size={20} color="#444" /></div> : null}

                          {/* <Typography level="body-sm"><Typography level="title-md">Não usar estoque: </Typography>{item.naoUsarEstoque == 1? <FaCheck/> : "-"}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Não usar contrato: </Typography>{item.naoUsarContrato == 1? <FaCheck/> : "-"}</Typography> */}

                          <Stack level="body-sm" mt="20px">
                            <Stack flexDirection="row" alignItems="center">
                              <div className="hover">
                                {((etapaAtual == "Solicitante") && (!osVinculada) && (formulario.usuario_id ? usuarioID == formulario.usuario_id : true)) ?

                                  // o solicitante não pode editar itens que geraram movimento de baixa 1.1.03 nem contrato 1.1.21
                                  (item.quantidadeBaixarContratoRM == 0 && item.quantidadeBaixarRM == 0) && (
                                    <FaRegEdit onClick={() => [setOpenModal(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice]), console.log(formulario.itens[indice])]} color="#2974ac" size={22} />
                                  )
                                  :
                                  <FaFolderOpen onClick={() => [setOpenModal(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice]), console.log(formulario.itens[indice])]} color="#2974ac" size={22} />
                                }
                              </div>

                              {((etapaAtual == "Solicitante") && (!osVinculada) && (formulario.usuario_id ? usuarioID == formulario.usuario_id : true)) ?
                                <div onClick={() => [setIndiceItemConsultado(indice), setDialogExclusao(true)]} className="hover" style={{ marginLeft: "10px" }}>

                                  {/* o solicitante não pode excluir itens que geraram movimento de baixa 1.1.03 nem contrato 1.1.21 */}
                                  {(item.quantidadeBaixarContratoRM == 0 && item.quantidadeBaixarRM == 0) && (
                                    <FaRegTrashAlt color="#990000" size={22} />
                                  )}

                                </div>
                                : null}
                              {/* <Button onClick={() => [setOpenModal(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice])]} color="neutral" sx={osVinculada ? { width: "100%" } : { width: "50%", marginRight: "10px" }}>{osVinculada || numeroProcesso != "" ? "Consultar" : "Editar"}</Button>
                              {(etapaAtual == "Solicitante" && !osVinculada) ? <Button onClick={() => [setIndiceItemConsultado(indice), setDialogExclusao(true)]} color="danger" sx={{ width: "50%" }}>Excluir</Button> : null} */}
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
                :
                <Table style={{ tableLayout: "auto"}} hoverRow stripe="even" noWrap aria-label="basic table">
                  <thead>

                    {/* Na etapa aprovador gerencial/institucional/analise-critica, exibir um botão para mostrar também itens de somente baixas */}
                    {(etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras" || etapaAtual == "Compras") && (
                      <Stack width={"250px"} flexDirection={"row"} alignItems={"center"} mr="30px" mb={"20px"}>
                        <Button variant="outlined" onClick={() => setVerItensSomenteBaixa(!verItensSomenteBaixa)} sx={{ padding: "5px 15px" }}>
                          <Stack flexDirection={"row"} alignItems="center">
                            {verItensSomenteBaixa ?
                              <IoEye size={20} />
                              :
                              <IoEyeOff size={20} />
                            }
                            <Typography level="title-lg" fontSize={14} ml={"10px"}>{verItensSomenteBaixa ? "Ocultar requisição original" : "Ver requisição original"}</Typography>
                          </Stack>
                        </Button>
                      </Stack>
                    )
                    }

                    <tr>
                      {osVinculada && etapaAtual == "Solicitante" ? <th style={{ height: "25px", width: "25px" }}></th> : null}
                      <th style={{ height: "25px", width: "25px" }}>Item</th>
                      <th style={{ width: "80px", height: "25px" }}>Código</th>
                      <th style={{ height: "25px", width: "600px" }}>Descrição</th>
                      <th style={{ width: "50px", height: "25px" }}>Saldo</th>
                      <th style={{ width: "50px", height: "25px" }}>Qtd</th>
                      {formulario.numeroMov2 ? <th style={{ width: "50px", height: "25px" }}>Qtd Comprar</th> : null}
                      {formulario.numeroMov3 ? <th style={{ width: "50px", height: "25px" }}>Qtd Estoque</th> : null}
                      {formulario.numeroMov21 ? <th style={{ width: "50px", height: "25px" }}>Qtd Contrato</th> : null}
                      {etapaAtual == "Solicitante" && osVinculada ? <th style={{ width: "70px", height: "25px" }}>Status</th> : null}
                      {etapaAtual == "Solicitante" && osVinculada ? <th style={{ width: "60px", height: "25px", textAlign: "center" }}>Status</th> : null}
                      {/* <th style={{ width: "100px", height: "25px" }}>Bloquear estoque</th>
                      <th style={{ width: "100px", height: "25px" }}>Bloquear contrato</th> */}
                      <th style={{ width: "150px", height: "25px", textAlign: "center" }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formulario.itens.map((item, index) =>
                      item.quantidadeProduto ?
                        // <tr> possui uma condicao, caso seja aprovador gerencial/institucional responsavel, ocultar itens que não possuem quantidade a comprar.
                        <tr key={index} style={(etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras" || etapaAtual == "Compras") && item.quantidadeComprarRM == 0 && !verItensSomenteBaixa ? { display: "none" } : (etapaAtual == "Aprov. Gerencial" || etapaAtual == "Aprov. Institucional" || etapaAtual == "Análise Crítica Compras") && item.quantidadeComprarRM == 0 && permissaoAprovador && verItensSomenteBaixa ? { backgroundColor: "#bbb" } : {}}>

                          {osVinculada && etapaAtual == "Solicitante" ? <td style={{ padding: "0 10px" }}>{(formulario.itens[index].movimentado[0]) ? null : <Checkbox checked={item.marcado} onChange={(event) => atualizarFormulario("marcado", event.target.checked, true, index)} />}</td> : null}
                          <td style={{ padding: "0 10px" }}>{parseInt(index + 1)}</td>
                          <td style={{ padding: "0 10px" }}>{item.codProduto}</td>
                          <td style={{ padding: "0 10px", minWidth: "400px" }}>{item.descricao}</td>
                          <td style={{ padding: "0 10px" }}>{item.estoque}</td>
                          <td style={{ padding: "0 10px" }}>{item.quantidadeProduto}</td>
                          {formulario.numeroMov2 ? <td style={{ padding: "0 10px" }}>{item.quantidadeComprarRM && item.quantidadeComprarRM}</td> : null}
                          {formulario.numeroMov3 ? <td style={{ padding: "0 10px" }}>{item.quantidadeBaixarRM && item.quantidadeBaixarRM}</td> : null}
                          {formulario.numeroMov21 ? <td style={{ padding: "0 10px" }}>{item.quantidadeBaixarContratoRM && item.quantidadeBaixarContratoRM}</td> : null}
                          {etapaAtual == "Solicitante" && osVinculada ? <td style={{ padding: "0 10px" }}>{item.movimentado[0] ? item.movimentado[0].STATUS : ""}</td> : null}
                          {etapaAtual == "Solicitante" && osVinculada ? <td style={{ padding: "0 10px", textAlign: "center" }}>{item.movimentado[0] ? <div onClick={() => [setModalMovimentacoesItem(true), setIndiceItemConsultado(index), setItemTemporario(formulario.itens[index]), console.log(formulario.itens[index])]} className="hover"><FaInfoCircle size={20} color="#444" /></div> : null}</td> : null}
                          {/* {etapaAtual == "Solicitante" ? <td style={{ padding: "0 10px" }}>{item.CODTMV == "1.1.01" ? "Pedido" : item.CODTMV == "1.1.03" ? "Baixado" : " - "}</td> : null} */}

                          {/* <td style={{ padding: "0 10px" }}>{item.naoUsarEstoque == 1? <FaCheck/> : "-"}</td>
                          <td style={{ padding: "0 10px" }}>{item.naoUsarContrato == 1? <FaCheck/> : "-"}</td> */}

                          <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                            <div className="hover">
                              {((etapaAtual == "Solicitante") && (!osVinculada) && (formulario.usuario_id ? usuarioID == formulario.usuario_id : true)) ?

                                // o solicitante não pode editar itens que geraram movimento de baixa 1.1.03 nem contrato 1.1.21
                                (item.quantidadeBaixarContratoRM == 0 && item.quantidadeBaixarRM == 0) && (
                                  <FaRegEdit onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(formulario.itens[index])]} color="#2974ac" size={22} />
                                )
                                :
                                <FaFolderOpen onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(formulario.itens[index])]} color="#2974ac" size={22} />
                              }
                            </div>
                            {((etapaAtual == "Solicitante") && (!osVinculada) && (formulario.usuario_id ? usuarioID == formulario.usuario_id : true)) ?
                              <div onClick={() => [setIndiceItemConsultado(index), setDialogExclusao(true)]} className="hover" style={{ marginLeft: "10px" }}>

                                {/* o solicitante não pode excluir itens que geraram movimento de baixa 1.1.03 nem contrato 1.1.21 */}
                                {(item.quantidadeBaixarContratoRM == 0 && item.quantidadeBaixarRM == 0) && (
                                  <FaRegTrashAlt color="#990000" size={22} />
                                )}

                              </div>
                              : null}
                          </td>
                        </tr>
                        : null
                    )}
                  </tbody>
                </Table>
              }
              <Typography pl={"5px"} width={mobile ? "80%" : "650px"} backgroundColor={"#fcbf49"} mb={2}>
                Para itens com tipos de pedido diferentes, devem ser criadas requisições diferentes.
              </Typography>

            </Stack>

            <Typography level="title-lg" mt="40px">
              Informações Adicionais
            </Typography>

            <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"}>
                <FormLabel>Número da PMS</FormLabel>
                <Input disabled value={formulario.numeroPms} sx={{ backgroundColor: "#f0f0f0" }} />
              </Stack>

              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Número da OS</FormLabel>
                {/* <Input value={osVinculada ? numeroOS : ""} disabled /> */}
                <Input value={numeroOS} disabled sx={{ backgroundColor: "#f0f0f0" }} />
              </Stack>

              <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                <FormLabel>Tipo do Pedido*</FormLabel>
                <Select defaultValue="001" value={formulario.tipoPedido} onChange={(evento, newValue) => [atualizarFormulario("tipoPedido", newValue)]} disabled={desabilitarEtapasAprovacao}>
                  {listaLeadTimePedidos.map(function (leadTime, indice) {
                    return (
                      <Option key={indice} value={leadTime.tipoPedido}>{leadTime.descricao}</Option>
                    );
                  })}
                </Select>
              </Stack>

              {/* Não deve aparecer este item para os aprovadores anteriores ao gerencial */}
              {/* {etapaAtual != "Solicitante" && etapaAtual != "Aprov. Técnico" && */}
                <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                  <FormLabel>Critério de Atendimento</FormLabel>
                  <Select defaultValue="NO" value={formulario.criterio} onChange={(event, newValue) => atualizarFormulario("criterio", newValue)} disabled={desabilitarEtapasAprovacao}>
                    <Option value="ES">Estoque</Option>
                    <Option value="MP">Máquina Parada</Option>
                    <Option value="NO">Normal</Option>
                    <Option value="UR">Urgente</Option>
                  </Select>
                </Stack>
              {/* } */}
            </Stack>

            <Stack mt={mobile ? "20px" : "30px"}>
              <FormLabel>Aplicação Detalhada</FormLabel>
              <Input type="text" value={formulario.aplicacao} onChange={(event) => atualizarFormulario("aplicacao", event.target.value)} disabled={etapaAtual == "Aprov. Técnico" && permissaoAprovador? false: desabilitarEtapasAprovacao} />
            </Stack>

            <Stack mt={mobile ? "20px" : "30px"}>
              <FormLabel>Justificativa</FormLabel>
              <Input type="text" value={formulario.justificativa} onChange={(event) => atualizarFormulario("justificativa", event.target.value)} disabled={etapaAtual == "Aprov. Técnico" && permissaoAprovador? false: desabilitarEtapasAprovacao} />
            </Stack>

            <Stack mt={mobile ? "20px" : "30px"}>
              <FormLabel>Observações</FormLabel>
              <Input type="text" value={formulario.observacoesSolicitacao} onChange={(event) => atualizarFormulario("observacoesSolicitacao", event.target.value)} disabled={etapaAtual == "Aprov. Técnico" && permissaoAprovador? false: desabilitarEtapasAprovacao} />
            </Stack>

              {/* Mostrar comprador selecionado, quando estiver na etapa compras */}
              { formulario.comprador ?
                <Stack mt={mobile ? "20px" : "30px"}>
                  <FormLabel>Comprador</FormLabel>
                  <Input type="text" value={formulario.comprador} disabled />
                </Stack>
                :
                null
              }

            {/* Escolher se é pedido de contrarto, apenas o solicitante faz */}
            {etapaAtual == "Solicitante" && fornecedoresSelecionados.length == 0 ?
              <Stack mt={mobile ? "20px" : "30px"}>
                <Typography level="body-sm">Pedido de Contrato?</Typography>
                <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                  <Switch
                    checked={mostrarCamposPedidoFornecedor} disabled={osVinculada}
                    onChange={(event) => [setMostrarCamposPedidoFornecedor(event.target.checked), atualizarFormulario("pedidoContrato", event.target.checked)]}
                  />
                </Stack>
              </Stack>
              :
              <Typography mt={"30px"} level="title-lg">{formulario.pedidoContrato ? "Pedido de contrato" : null} </Typography>
            }

            {mostrarCamposPedidoFornecedor || formulario.pedidoContrato ?
              <>

                <Stack flexDirection={mobile ? "column" : "row"}>
                  <Stack minWidth={mobile ? "100%" : "110px"} mt={mobile ? "20px" : "30px"}>
                    <FormLabel>Código do Tipo Contrato</FormLabel>
                    <Select defaultValue="001" value={formulario.contratoTipoContrato}
                      onChange={(event, newValue) => atualizarFormulario("contratoTipoContrato", newValue)} disabled={desabilitaAprovadores}>
                      <Option value="001">Prestação de Serviços</Option>
                      <Option value="002">Fornecimento Material</Option>
                      <Option value="003">Preventivo</Option>
                      <Option value="004">Corporativo</Option>
                      <Option value="005">Fornecimento Alimentação</Option>
                      <Option value="006">Manutenção</Option>
                      <Option value="007">Fornecimento Água e Gelo</Option>
                    </Select>
                  </Stack>

                  <Stack flex={1} mt={mobile ? "20px" : "30px"} ml={mobile ? "0" : "30px"} >
                    <FormLabel>Nome do Contrato</FormLabel>
                    <Input type="text" value={formulario.contratoNome} onChange={(event) => atualizarFormulario("contratoNome", event.target.value)} disabled={desabilitaAprovadores} />
                  </Stack>

                  <Stack mt={mobile ? "20px" : "30px"} ml={mobile ? "0" : "30px"}>
                    <FormLabel>Data Início Contrato</FormLabel>
                    <Input type="date" value={formulario.contratoDataIni} onChange={(event) => atualizarFormulario("contratoDataIni", event.target.value)} disabled={desabilitaAprovadores} />
                  </Stack>
                  <Stack mt={mobile ? "20px" : "30px"} ml={mobile ? "0" : "30px"}>
                    <FormLabel>Data Fim Contrato</FormLabel>
                    <Input type="date" value={formulario.contratoDataFim} onChange={(event) => atualizarFormulario("contratoDataFim", event.target.value)} disabled={desabilitaAprovadores} />
                  </Stack>
                </Stack>

                <Stack flexDirection={"column"} width={mobile ? "95%" : "200px"} mt={mobile ? "20px" : "10px"}>
                  <FormLabel>Classificação RD &nbsp;</FormLabel>
                  <Stack flexDirection={"row"}>
                    {/* Somente solicitante edita o campo buscar classificacao RD */}
                    {etapaAtual == "Solicitante" ?
                      <>
                        <Input disabled={classificacaoRDVinculada ? classificacaoRDVinculada : desabilitaAprovadores} value={nomeClassificacaoRDBuscado} onChange={(event) => setNomeClassificacaoRDBuscado(event.target.value)} placeholder="Class. RD" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                        <Button disabled={desabilitaAprovadores} size="sm" onClick={classificacaoRDVinculada ? removeClassificacaoRD : buscarClassificacaoRD} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>{classificacaoRDVinculada ? "Remover" : "Buscar"}</Button>
                      </>
                      :
                      <Input disabled={desabilitaAprovadores} value={formulario.contratoClassRD} />
                    }
                  </Stack>
                </Stack>

              </>
              :
              null
            }

            <Typography level="title-lg" mt="30px">
              Fornecedores Sugeridos
            </Typography>

            <Stack mt="5px" ml="auto">
              <Stack flexDirection={"row"} width={mobile ? "95%" : "100%"} mt={mobile ? "20px" : "10px"}>
                <Input value={nomeFornecedorBuscado} onChange={(event) => setNomeFornecedorBuscado(event.target.value)} placeholder="Nome ou código fornecedor" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                  <Button size="sm" onClick={buscarFornecedor} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Add Fornecedor</Button>
              </Stack>
            </Stack>

            <Stack mt="30px">
              {mobile ?
                <Stack>
                  {fornecedoresSelecionados.map((item, indice) => {
                    return (
                      <div key={indice}>
                        <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                          <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">CNPJ: </Typography>{item.CNPJ}</Typography>
                          </Stack>
                          <Typography level="body-sm"><Typography level="title-md">Nome Fantasia: </Typography>{item.NOMEFANTASIA}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Razão Social: </Typography>{item.NOME}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Cod.CFO: </Typography>{item.CODCFO}</Typography>

                          <div onClick={() => removeVendorListItem(item.CNPJ)} color="danger" sx={{ width: "20%" }}>
                            <FaRegTrashAlt color="#990000" size={22} />
                          </div>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
                :
                <Sheet>
                  <Table hoverRow noWrap aria-label="basic table">
                    <thead>
                      <tr>
                        <th style={{ width: "100px", height: "25px" }}>CNPJ</th>
                        <th style={{ width: "250px", height: "25px" }}>Nome Fantasia</th>
                        <th style={{ width: "250px", height: "25px" }}>Razão</th>
                        <th style={{ width: "80px", height: "25px" }}>CODCFO</th>
                        <th style={{ width: "70px", height: "25px", textAlign: "center" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fornecedoresSelecionados.map((item, index) =>

                        <tr key={index}>
                          <td style={{ padding: "0 10px" }}>{item.CNPJ}</td>
                          <td style={{ padding: "0 10px" }}>{item.NOMEFANTASIA}</td>
                          <td style={{ padding: "0 10px" }}>{item.NOME}</td>
                          <td style={{ padding: "0 10px" }}>{item.CODCFO}</td>

                          <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                            <div className="hover">
                            </div>
                            <div onClick={(e) => removeVendorListItem(item.CNPJ)} className="hover" style={{ marginLeft: "10px" }}>
                              <FaRegTrashAlt color="#990000" size={22} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                </Sheet>
              }
            </Stack>

            <Stack mt={"10px"} width={mobile ? "80%" : "300px"}>
              <Button size="sm" variant="outlined" onClick={handleRedirecionar}>Solicitar cadastro de fornecedor no Fluig</Button>
            </Stack>

            {/* Container de aprovação */}
            {permissaoAprovador && formulario.etapaAtual != "Solicitante" && formulario.etapaAtual != "Compras" ?
              <Stack flexDirection={"column"} boxShadow={mobile ? "" : "md"} padding={0} borderRadius={"10px"} mt="50px" sx={mobile ? {} : { backgroundColor: "#fff" }}>
                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={mobile ? "flex-start" : "space-between"} alignItems={mobile ? "flex-start" : "flex-end"}>
                  <Stack>
                    <Typography level="title-lg" mb={"10px"}>Decisão Sobre a Requisição</Typography>
                    <ToggleButtonGroup
                      value={decisaoAprovacao}
                      onChange={(event, newValue) =>
                        [
                          setDecisaoAprovacao(newValue ? newValue : decisaoAprovacao),
                          rolarParaOCampo(newValue ? newValue : decisaoAprovacao),
                          definirStatus(newValue ? newValue : decisaoAprovacao),
                          //Caso seja fase 2 de compras, carregar o select de retorno as atividades anteriores
                          setEtapasAnteriores(obterEtapasAnteriores(etapaAtual))
                        ]
                      }
                    >
                      <Button value="Aprovado" style={decisaoAprovacao == "Aprovado" ? { backgroundColor: "#7cb342" } : {}}>
                        <Typography sx={decisaoAprovacao == "Aprovado" ? { color: "#eee" } : {}}>Aprovar</Typography>
                      </Button>
                      {/* {etapaAtual != "Aprov. Institucional" && etapaAtual != "Aprov. Gerencial" && etapaAtual != "Análise Crítica Compras" && ()} */}
                      <Button value="Reprovado" style={decisaoAprovacao == "Reprovado" ? { backgroundColor: "#ff8f00" } : {}}>
                        <Typography sx={decisaoAprovacao == "Reprovado" ? { color: "#eee" } : {}}>Reprovar</Typography>
                      </Button>

                      <Button value="Cancelado" style={decisaoAprovacao == "Cancelado" ? { backgroundColor: "#f44336" } : {}}>
                        <Typography sx={decisaoAprovacao == "Cancelado" ? { color: "#eee" } : {}}>Cancelar</Typography>
                      </Button>
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack width={mobile ? "100%" : "auto"}>

                    {/* Select de selecionar etapas anteriores, caso reprovacao (fase 2) */}
                    {etapasAnteriores.length > 0 && decisaoAprovacao == "Reprovado" && (
                      <Stack m={"10px"}>
                        <Typography level="title-md">Retornar para Atividade:</Typography>
                        <Autocomplete options={etapasAnteriores} onChange={handleAutocompleteEtapaARetornar} value={etapaARetornar} />
                      </Stack>
                    )}

                    <Button disabled={decisaoAprovacao == "" || enviarDisabled} sx={mobile ? { marginTop: "20px" } : {}} onClick={movimentarProcesso}>Confirmar Decisão</Button>
                  </Stack>
                </Stack>



                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={"space-between"}>

                  {(decisaoAprovacao == "Aprovado" && etapaAtual == "Análise Crítica Compras") ?
                    <Stack mt="40px" width={mobile ? "80%" : "600px"}>
                      <Typography level="title-md">Selecionar Comprador:</Typography>
                      <Autocomplete options={compradoresDisponiveis} getOptionLabel={opt => opt.nome}
                        onChange={handleCompradorSelected} value={compradorSelected} inputValue={compradorSelected?.nome} />
                    </Stack>
                    :
                    null
                  }

                  <Stack mt="30px" width={mobile ? "100%" : "50%"} mr="30px">
                    <Typography level="title-md">Informações Adicionais</Typography>
                    <Textarea value={formulario.informacoesAdicionais} onChange={event => atualizarFormulario("informacoesAdicionais", event.target.value)} minRows={3} sx={{ marginTop: "5px" }} placeholder="Descreva as informações adicionais" />
                  </Stack>
                  {decisaoAprovacao == "Reprovado" ?
                    <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                      <Typography level="title-md">Motivo da Reprovação</Typography>
                      <Textarea value={formulario.motivoReprovacao} onChange={event => atualizarFormulario("motivoReprovacao", event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#ff8f00" }} placeholder="Informe o motivo da reprovação" />
                    </Stack>
                    : null}
                  {decisaoAprovacao == "Cancelado" ?
                    <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                      <Typography level="title-md">Motivo do Cancelamento</Typography>
                      <Textarea value={formulario.motivoCancelamento} onChange={event => atualizarFormulario("motivoCancelamento", event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#f44336" }} placeholder="Informe o motivo do cancelamento" />
                    </Stack>
                    : null}
                </Stack>
              </Stack>
              : null
            }

            {/* Container de envio do solicitante */}
            {
              (
                (etapaAtual == "Solicitante" && formulario.numeroMov1 == "")
                ||
                (etapaAtual == "Solicitante" && formulario.numeroMov1 != "" && (usuarioID == formulario.usuario_id))
              ) ?
                // {etapaAtual == "Solicitante" && ((formulario.usuario_id == usuarioID) || (numeroProcesso == "" && formulario.usuario_id != usuarioID)) ?
                <>
                <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Stack sx={{ alignSelf: "flex-end" }} mt="50px" display={"flex"}>
                      <Button disabled={enviarDisabled} onClick={numeroProcesso == "" ? realizarSolicitacao : movimentarProcesso} sx={{ alignSelf: "flex-end" }}>ENVIAR</Button>
                    </Stack>
                    { etapaAtual == "Solicitante" && formulario.numeroMov1 != "" && (usuarioID == formulario.usuario_id) ?
                        <Stack sx={{ alignSelf: "flex-end" }} mt="20px" ml="20px" display={"flex"}>
                        <Button onClick={solicitanteCancelaProcesso} value="Cancelado" style={decisaoAprovacao == "Cancelado" ? { backgroundColor: "#f44336" } : {backgroundColor: "#ff8f00"}}>
                          <Typography sx={decisaoAprovacao == "Cancelado" ? { color: "#eee" } : {}}>Cancelar</Typography>
                        </Button>
                      </Stack>
                      :
                      null
                    }
                </Stack>
                </>
                : null
            }

            <InformacoesItem
              openModal={openModal}
              setOpenModal={setOpenModal}
              mobile={mobile}
              itemTemporario={itemTemporario}
              setItemTemporario={setItemTemporario}
              formulario={formulario}
              indiceItemConsultado={indiceItemConsultado}
              atualizarFormulario={atualizarFormulario}
              numeroProcesso={numeroProcesso}
              etapaAtual={etapaAtual}
              osVinculada={osVinculada}
              acrescentarItem={acrescentarItem}
              usuarioLogado={usuarioID}
              transferencia={formulario.transferencia}
            />

            {etapaAtual == "Solicitante" ?
              <MovimentacoesItem
                openModal={modalMovimentacoesItem}
                setOpenModal={setModalMovimentacoesItem}
                mobile={mobile}
                itemTemporario={itemTemporario}
                setItemTemporario={setItemTemporario}
                formulario={formulario}
                indiceItemConsultado={indiceItemConsultado}
                atualizarFormulario={atualizarFormulario}
                numeroProcesso={numeroProcesso}
                etapaAtual={etapaAtual}
                osVinculada={osVinculada}
                acrescentarItem={acrescentarItem}
                usuarioLogado={usuarioID}
              />
              : null
            }

            {/* Modal de listagem de itens quando pesquisado por descricao */}
            <SelecaoItem
              openModalListaItem={openModalListaItem}
              setOpenModalListaItem={setOpenModalListaItem}
              itensEncontradosBusca={itensEncontradosBusca}
              novoItem={novoItem}
            />

            {/* Modal de listagem de fornecedores quando pesquisado a lista de vendor list pelo solicitante */}
            <SelecaoFornecedor
              openModalListaFornecedor={openModalListaFornecedor}
              setOpenModalListaFornecedor={setOpenModalListaFornecedor}
              fornecedoresResultBusca={fornecedoresResultBusca}
              addVendorList={addVendorList}
            // novoItem={novoItem}
            />

            {/* Modal de listagem de classificacao RD quando pesquisado pelo solicitante */}
            <SelecaoClassificacaoRD
              openModalListaClassificacaoRD={openModalListaClassificacaoRD}
              setOpenModalListaClassificacaoRD={setOpenModalListaClassificacaoRD}
              classificacaoRDResultBusca={classificacaoRDResultBusca}
              addClassificacaoRD={addClassificacaoRD}
            // novoItem={novoItem}
            />

          </Stack>
        )}

        {formulario.itens.length > 0 ?

          <ConfirmarExclusao
            dialogExclusao={dialogExclusao}
            setDialogExclusao={setDialogExclusao}
            mensagem={`Tem certeza que deseja excluir o item de código ${formulario.itens[indiceItemConsultado].CODIGOPRD} ? Esta ação é irreversível`}
            excluirItem={excluirItem}
            indiceItemConsultado={indiceItemConsultado}
          />
          : null
        }

        {/* ABA DE ANEXO */}
        {valueTogglePagina == "anexo" && (
          <Anexos
            numeroProcesso={numeroProcesso}
            mobile={mobile} anexos={anexos}
            setUploadAnexos={setUploadAnexos}
            setAnexosSelecionados={setAnexosSelecionados}
            anexosSelecionados={anexosSelecionados}
            usuarioResponsavel={usuarioLogin}
            etapaAtual={formulario.etapaAtual}
            itensParaFornecedor={itensParaFornecedor}
            setItensParaFornecedor={setItensParaFornecedor}
          />
        )}

        {valueTogglePagina == "laudoAvariaMotor" && (
          <LaudoAvariaMotor 
            novoLaudo={setLaudoAvariaMotor}
            laudoAvariaMotorTemp={laudoAvariaMotorTemp}
            setLaudoAvariaMotorTemp={setLaudoAvariaMotorTemp}
            desabilitaAprovadores={desabilitaAprovadores} />
        )}

        {valueTogglePagina == "laudoReformaPneu" && (
          <LaudoReformaPneu
            laudoReformaPneuTemp={laudoReformaPneuTemp}
            setLaudoReformaPneuTemp={setLaudoReformaPneuTemp} //o formulario a ser salvo
            
            novaListaLaudoReformaPneu={setListaLaudoReformaPneu} //lista de forms a salvar
            listaLaudoReformaPneu={listaLaudoReformaPneu} // lista para recarregar no filho
            setListaLaudoReformaPneuTemp={setListaLaudoReformaPneuTemp} //por equanto sem usar
            desabilitaAprovadores={desabilitaAprovadores} />
        )}

        {valueTogglePagina == "inspecaoMaterialRodante" && (
          <InspecaoMaterialRodante
            inspecaoMaterialRodanteTemp={inspecaoMaterialRodanteTemp}
            setInspecaoMaterialRodanteTemp={setInspecaoMaterialRodanteTemp} //o form a ser salvo
            novaListaInspecaoMaterialRodante={setListaInspecaoMaterialRodante} //form a salvar
            listaInspecaoMaterialRodante={listaInspecaoMaterialRodante} //objeto para recarregar no filho

            componentesMaterialRodanteTemp={componentesMaterialRodanteTemp} //os componentes do material-rodante a ser salvo, por enquanto sem usar
            setComponentesMaterialRodanteTemp={setComponentesMaterialRodanteTemp}
            
            // setListaInspecaoMaterialRodanteTemp={setListaInspecaoMaterialRodanteTemp}
            desabilitaAprovadores={desabilitaAprovadores} />
        )}

          <ModalAvisos
          modalAvisos={modalAvisos}
          setModalAvisos={setModalAvisos}
          mensagem={`Favor inserir o anexo para os itens que devem ter relatório técnico (motores, pneus, material rodante, etc).`}
          />



      </Stack>
    </Stack>
  );
}
