import axios from "axios";
import { useState, useEffect, useRef } from "react";

import { formatarDataHoraBrasil } from "../../../../../utils/datas";
import { toast } from 'react-toastify';

// Icones
import { FaSearch } from "react-icons/fa";
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen } from "react-icons/fa";
// import { IoChevronBack, IoArrowForwardSharp, IoCheckmarkSharp, IoWarning, IoDocumentText, IoArrowDownOutline, IoCubeOutline, IoInformationCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
// import { HiOutlineMinus } from "react-icons/hi";
// import { FaLongArrowAltDown, FaLongArrowAltRight } from "react-icons/fa";
// import { AiFillMessage } from "react-icons/ai";
// import { TfiSplitH } from "react-icons/tfi";

//componentes
import Header from "../../../../../components/Header";
import Sidebar from "../../../../../components/Sidebar";

//Mui
import { Stack, Typography, Button, Modal, Link, Sheet, ModalClose, Select,
         Option, Avatar, Card, Dropdown, MenuButton, Menu, MenuItem, Switch,
         Radio, List, ListItem, ListItemButton, ListItemContent,
         ListItemDecorator, FormHelperText, RadioGroup, FormLabel, Input, Textarea, Table } from "@mui/joy";

//Roteamento
import { useNavigate, useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api, RM } from "../../../../../services/api";
import realizaConsulta from "../../../../../services/rm";

//Largura da tela
const larguraTela = window.innerWidth;

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let permissoes = [];

export default function LaudoAvariaMotor(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const mobile = useMediaQuery('(max-width:1200px)');

    const [prefixoPesquisado, setPrefixoPesquisado] = useState(""); 
    const [formulario, setFormulario] = useState(
        {
            id: -1,
            prefixo: "",
            tipoobjeto: "",
            codtipoobjeto: "",
            marca: "",
            modelo: "",
            serie: "",
            placa: "",
            horimetro_atual: "",
            horimetro_acumulado: "",
            o_que_aconteceu: "",
            porque_aconteceu: "",
            causas_investigar: "",
            notas_explicativas: "",
            situacao_atual_equipamento: "",
            conclusao: "",
            recomendacao: ""
        });

    const [listaLaudos, setListLaudos] = useState([
        {
            id: 999,
            prefixo: "123",
            marca: "",
            modelo: "",
            serie: "",
            placa: "",
            horimetro_atual: "",
            horimetro_acumulado: "",
            o_que_aconteceu: "",
            porque_aconteceu: "",
            causas_investigar: "",
            notas_explicativas: "",
            situacao_atual_equipamento: "",
            conclusao: "",
            recomendacao: ""
        },
        {
            id: 1000,
            prefixo: "456",
            marca: "",
            modelo: "",
            serie: "",
            placa: "",
            horimetro_atual: "",
            horimetro_acumulado: "",
            o_que_aconteceu: "",
            porque_aconteceu: "",
            causas_investigar: "",
            notas_explicativas: "",
            situacao_atual_equipamento: "",
            conclusao: "",
            recomendacao: ""
        }
    ]);

    useEffect(() => {
        if(props.laudoAvariaMotorTemp != null && props.laudoAvariaMotorTemp != "" && props.laudoAvariaMotorTemp != undefined){
            setFormulario(props.laudoAvariaMotorTemp);
        }
    }, []);

    const atualizarFormulario = (campo, valor) => {
        setFormulario({
        ...formulario,      // Copia o estado atual do formulário
        [campo]: valor      // Atualiza o campo correspondente com o novo valor
        });
    };


    const consultaPrefixo = async () => {
        realizaConsulta("/01.001.031/", { params: { 'PREFIXO_S': prefixoPesquisado }}) 
        .then(function (response) {

            if(Array.isArray(response.data) && response.data.length === 0){
                toast.warning("Não existem dados para o prefixo pesquisado.");
                return;
            }
            setFormulario({
                ...formulario,
                prefixo: prefixoPesquisado,
                tipoobjeto: response.data[0].NOME_TIPOOBJ,
                codtipoobjeto: response.data[0].IDTIPOOBJ,
                marca: response.data[0].MARCA,
                modelo: response.data[0].MODELO,
                serie: response.data[0].SERIE,
                placa: response.data[0].PLACA,
                horimetro_atual: response.data[0].HORIMETRO_ATUAL,
                horimetro_acumulado: response.data[0].HORIMETRO_ACUMULADO
            });
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    // const salvarLaudo = () => {
    //     console.log("whats props laudoAvariaMotor: ",formulario);
    //     props.novoLaudo(formulario);
    // }

    useEffect(() => {
        props.setLaudoAvariaMotorTemp(formulario)
    }, [formulario]);

    useEffect(() => {
        if (formulario.prefixo) {  // Evita chamadas desnecessárias
            props.novoLaudo(formulario);
        }
    }, [formulario]); // Dispara quando `formulario` muda

    return(
        
            <Stack ml={mobile ? "0px" : "20px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : ""} p={mobile ? "20px" : "5px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">Laudo Avaria de Motor</Typography>

                <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                    <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Busca por Prefixo Equipamento</FormLabel>
                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Input type="text" value={prefixoPesquisado} onChange={(event) => setPrefixoPesquisado(event.target.value)} disabled={props.desabilitaAprovadores} sx={{ borderTopRightRadius: mobile ? "5px" : 0, borderBottomRightRadius: mobile? "5px" : 0 , height: "40px", width: mobile ? "100%" : "212px"}} />
                            <Button disabled={props.desabilitaAprovadores} onClick={() => consultaPrefixo()} size="sm" sx={{ borderTopLeftRadius: mobile? "5px": 0, borderBottomLeftRadius: mobile? "5px": 0, height: "40px"}}><FaSearch /></Button>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                    <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Tipo</FormLabel>
                        <Stack flexDirection={mobile ? "column" : "row"} width={mobile ? "100%" : "280px"}>
                            <Input type="text" value={formulario.tipoobjeto} disabled sx={{width: mobile ? "100%" : "300px"}}/>
                            {/* <Button disabled={props.desabilitaAprovadores} onClick={() => consultaPrefixo()} size="sm" sx={{ borderTopLeftRadius: mobile? "5px": 0, borderBottomLeftRadius: mobile? "5px": 0, height: "40px"}}><FaSearch /></Button> */}
                        </Stack>
                    </Stack>

                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Prefixo</FormLabel>
                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Input type="text" value={formulario.prefixo} disabled />
                            {/* <Button disabled={props.desabilitaAprovadores} onClick={() => consultaPrefixo()} size="sm" sx={{ borderTopLeftRadius: mobile? "5px": 0, borderBottomLeftRadius: mobile? "5px": 0, height: "40px"}}><FaSearch /></Button> */}
                        </Stack>
                    </Stack>
                    
                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Marca</FormLabel>
                        <Input type="text" value={formulario.marca} disabled/>
                    </Stack>

                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Modelo</FormLabel>
                        <Input type="text" value={formulario.modelo} disabled/>
                    </Stack>

                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Série</FormLabel>
                        <Input type="text" value={formulario.serie} disabled/> 
                    </Stack>
                </Stack>

                <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                    <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Placa</FormLabel>
                        <Input type="text" value={formulario.placa} disabled/>
                    </Stack>

                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Horímetro atual</FormLabel>
                        <Input type="text" value={formulario.horimetro_atual} disabled/>
                    </Stack>

                    <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                        <FormLabel>Horímetro acumulado</FormLabel>
                        <Input type="text" value={formulario.horimetro_acumulado} disabled/>
                    </Stack>
                </Stack>

                <Stack flexDirection={"column"} mt="30px">

                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : ""}>
                            <FormLabel>O que aconteceu</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.o_que_aconteceu} onChange={(event) => atualizarFormulario("o_que_aconteceu", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>
                        <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                            <FormLabel>Por que aconteceu</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.porque_aconteceu} onChange={(event) => atualizarFormulario("porque_aconteceu", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>
                    </Stack>

                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : "20px"}>
                            <FormLabel>Causas a investigar</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.causas_investigar} onChange={(event) => atualizarFormulario("causas_investigar", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>

                        <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : "20px"}>
                            <FormLabel>Notas explicativas</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.notas_explicativas} onChange={(event) => atualizarFormulario("notas_explicativas", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>
                    </Stack>

                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : "20px"}>
                            <FormLabel>Situação Atual Equipamento</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.situacao_atual_equipamento} onChange={(event) => atualizarFormulario("situacao_atual_equipamento", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>

                        <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : "20px"}>
                            <FormLabel>Conclusão</FormLabel>
                            <Textarea disabled={props.desabilitaAprovadores} value={formulario.conclusao} onChange={(event) => atualizarFormulario("conclusao", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                        </Stack>
                    </Stack>

                    <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : "20px"}>
                        <FormLabel>Recomendação</FormLabel>
                        <Textarea disabled={props.desabilitaAprovadores} value={formulario.recomendacao} onChange={(event) => atualizarFormulario("recomendacao", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                    </Stack>
                </Stack>

                {/* <Stack flexDirection={"column"} mt="30px">
                    <Stack ml={mobile ? "0" : "5px"} mt={mobile ? "20px" : ""}>
                        <Button onClick={() => salvarLaudo()} sx={{width: mobile ? "100%" : "200px"}}>Salvar</Button>
                    </Stack>
                </Stack> */}


                {/* Listagem */}
                {/* <Stack mt="30px">
                    { mobile ?
                        <Stack>
                            {listaLaudos.map((item, indice) => {
                                return (
                                <div>
                                    <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                    <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                                        <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Prefixo: </Typography>{item.prefixo}</Typography>
                                    </Stack>
                                    <Typography level="body-sm"><Typography level="title-md">Marca: </Typography>{item.marca}</Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Modelo: </Typography>{item.modelo}</Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Série: </Typography>{item.serie}</Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Placa: </Typography>{item.placa}</Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Horímetro atual: </Typography>{item.horimetro_atual}</Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Horímetro acumulado: </Typography>{item.horimetro_acumulado}</Typography>

                                    <Stack level="body-sm" mt="20px">
                                        <Stack flexDirection="row" alignItems="center">
                                        <Button onClick={() => null} color="neutral" sx={{ width: "50%", marginRight: "10px" }}>Ver</Button>
                                        </Stack>
                                    </Stack>
                                    </Stack>
                                </div>
                                );
                            })}
                        </Stack>

                        :
                        
                        <Table hoverRow stripe="even" noWrap aria-label="basic table">
                            <thead>
                                <tr>
                                    <th>Prefixo</th>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Série</th>
                                    <th>Placa</th>
                                    <th>Horímetro atual</th>
                                    <th>Horímetro acumulado</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaLaudos.map((item, index) =>
                                    <tr key={index}>
                                        <td style={{padding: "0 10px"}}>{item.prefixo}</td>
                                        <td style={{padding: "0 10px"}}>{item.marca}</td>
                                        <td style={{padding: "0 10px"}}>{item.modelo}</td>
                                        <td style={{padding: "0 10px"}}>{item.serie}</td>
                                        <td style={{padding: "0 10px"}}>{item.placa}</td>
                                        <td style={{padding: "0 10px"}}>{item.horimetro_atual}</td>
                                        <td style={{padding: "0 10px"}}>{item.horimetro_acumulado}</td>
                                        <td style={{ padding: "0 10px", display: "flex", alignItems: "center", flexDirection: "row" }}>
                                        <div className="hover">
                                            <FaRegEdit onClick={() => alert("1")} color="#2974ac" size={22} />
                                        </div>
                                        <div className="hover" style={{ marginLeft: "10px" }}>
                                            <FaRegTrashAlt onClick={() => alert("2")} color="#990000" size={22} />
                                        </div>
                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </Stack> */}


            </Stack>

        
    );

}