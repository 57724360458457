import { useState, useEffect, useRef } from "react";

import { formatarDataHoraBrasil } from "../../../../../utils/datas";
import { toast } from 'react-toastify';

// Icones
import { FaSearch } from "react-icons/fa";
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen } from "react-icons/fa";
import { formatarData } from "../../../../../utils/datas";

//componentes
import Header from "../../../../../components/Header";
import Sidebar from "../../../../../components/Sidebar";

//Mui
import { Stack, Typography, Button, Modal, Link, Sheet, ModalClose, Select,
         Option, Avatar, Card, Dropdown, MenuButton, Menu, MenuItem, Switch,
         Radio, List, ListItem, ListItemButton, ListItemContent,
         ListItemDecorator, FormHelperText, RadioGroup, FormLabel, Input, Textarea, Table, Box, Chip } from "@mui/joy";
import MultipleSelect from 'react-select';

//Roteamento
import { useNavigate, useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api, RM } from "../../../../../services/api";
import realizaConsulta from "../../../../../services/rm";
import { BsWindowSidebar } from "react-icons/bs";

//Largura da tela
const larguraTela = window.innerWidth;

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let permissoes = [];

const tiposMotivos = [
    { value: "01", label: "Desgaste Natural" },
    { value: "02", label: "Baixa Calibragem" },
    { value: "03", label: "Talão Danificado" },
    { value: "04", label: "Alta Calibragem" },
    { value: "05", label: "Excesso de Peso" },
    { value: "06", label: "Acidente com Corte" },
    { value: "07", label: "Excesso Desgaste/Rodagem" },
    { value: "08", label: "Super Aquecimento" },
    { value: "09", label: "Perda da Recapagem" },
    { value: "10", label: "Danificação por Contaminantes" },
    { value: "11", label: "Danificado Serviço Ineficiente" },
    { value: "12", label: "Desagregado" },
    { value: "13", label: "Falha Operacional" },
    { value: "14", label: "Falha Mecânica" },
    { value: "15", label: "Infiltrações/Oxidações das Tramas" },
    { value: "16", label: "Retirar para Reforma/Reparo" },
  ];


export default function LaudoReformaPneu(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const mobile = useMediaQuery('(max-width:1200px)');

    const [numeroFogoPesquisado, setNumeroFogoPesquisado] = useState(""); 
    const [formulario, setFormulario] = useState(
        {
            id: -1,
            numero_fogo: "",
            tipoobjeto: "",
            codtipoobjeto: "",
            numero_equipamento: "",
            data: "",
            obra: "",
            responsavel: "",
            reforma: "",
            posicao: "",
            medida: "",
            marca: "",
            serie: "",
            dot: "",
            horas_trabalhadas: "",
            km_rodado: "",
            km_acumulado: "",
            km_atual: "",
            medicao_sulco_retirada: "",
            garantia: "",
            qtd_reforma: "",
            motivo_outros: "",
            tipos_motivos: ""
        }
    );

    const [listaLaudos, setListaLaudos] = useState([
    ]);

    const handleChange = (event, newValue) => {
        console.log(`You have choosen "${newValue}"`);
      };

    useEffect(() => {
        if(props.laudoReformaPneuTemp != null && props.laudoReformaPneuTemp != "" && props.laudoReformaPneuTemp != undefined){
            setFormulario(props.laudoReformaPneuTemp);
        }
    }, []);

    useEffect(() => {
        if(props.listaLaudoReformaPneu != null && props.listaLaudoReformaPneu != "" && props.listaLaudoReformaPneu != undefined){
            setListaLaudos(props.listaLaudoReformaPneu);
        }
    }, []);

    const atualizarFormulario = (campo, valor) => {
        setFormulario({
        ...formulario,      // Copia o estado atual do formulário
        [campo]: valor      // Atualiza o campo correspondente com o novo valor
        });
    };
 
    const consultaNumeroFogo = async () => {
        //TODO: trocar para consulta numero fogo
        realizaConsulta("/01.001.032/", { params: { 'PREFIXO_S': numeroFogoPesquisado }}) 
        .then(function (response) {
            console.log("Resultados consulta numero fogo:");
            console.log(response.data);
            if(Array.isArray(response.data) && response.data.length === 0){
                toast.warning("Não existem dados para o número de fogo pesquisado.");
                return;
            }
            setFormulario({
                ...formulario,
                data: response.data[0].DATA,
                km_acumulado: response.data[0].KM_ACUMULADO,
                km_atual: response.data[0].KM_ATUAL,
                obra: response.data[0].OBRA,
                serie: response.data[0].SERIE,
                medida: response.data[0].MEDIDA_PNEU,
                dot: response.data[0].DOT,
                numero_fogo: numeroFogoPesquisado,
                tipoobjeto: response.data[0].NOME_TIPOOBJ,
                codtipoobjeto: response.data[0].IDTIPOOBJ,
                marca: response.data[0].MARCA,
                serie: response.data[0].SERIE,
                reforma: response.data[0].REFORMA,
            });
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        // add previews data and new form here:
        props.setLaudoReformaPneuTemp();
        if(formulario.reforma && formulario.id == -1 && props.desabilitaAprovadores){
            if (!(isNaN(formulario.reforma))) {
                atualizarFormulario("qtd_reforma", formulario.reforma + 1);
            }
        }
    }, [formulario]);

    // useEffect(() => {
    //     if (formulario.numero_fogo) {  // Evita chamadas desnecessárias
    //         props.novoLaudoReformaPneu(formulario);
    //     }
    // }, [formulario]); // Dispara quando `formulario` muda


    // Atualiza `qtd_reforma` apenas quando `reforma` mudar
    // useEffect(() => {
    //     if (!(isNaN(formulario.reforma))) {
    //         atualizarFormulario("qtd_reforma", formulario.reforma + 1);
    //     }
    // }, [formulario.reforma]);


    const salvar = () => {
        console.log("Formulario salvo: ", formulario);
        //se novo objeto, validar para nao repetir um item ja cadastrado:
        if(formulario.id == -1 && listaLaudos.find(item => item.numero_fogo == formulario.numero_fogo)){
            alert("Laudo com número de fogo já cadastrado!");
        }
        //addd se novo objeto
        else if (formulario.numero_fogo && formulario.id == -1) {
            props.novaListaLaudoReformaPneu([...listaLaudos, formulario]);
            setListaLaudos([...listaLaudos, formulario]);
        }
        //atualiza objeto já add.
        else if (formulario.id != -1){
            const existe = listaLaudos.some(item => item.id === formulario.id);
            if (existe){
                let novaLista = listaLaudos.map(item => item.id === formulario.id ? formulario : item);
                props.novaListaLaudoReformaPneu([...novaLista]);
                setListaLaudos([...novaLista]);
            }
        }
        setFormulario({id: -1, numero_fogo: "", numero_equipamento: "", data: "",
            obra: "", responsavel: "", reforma: "", posicao: "", medida: "",
            marca: "", serie: "", dot: "", horas_trabalhadas: "", km_rodado: "",
            km_acumulado: "", km_atual: "", medicao_sulco_retirada: "", garantia: "",
            qtd_reforma: "", tipos_motivos: "", motivo_outros: "", tipoobjeto: "", codtipoobjeto: ""      
        });
    }


    const editarLaudoReformaPneu = (id, numeroFogo) => {
        // if (mobile){
            window.scrollTo({ top: 0, behavior: "smooth" });
        // }
        let laudo = listaLaudos.filter(item => (item.id == id && id != -1) || item.numero_fogo == numeroFogo)[0];
        console.log("aqui o laudo a editar ", laudo);
        let laudoFormatado = [];
        // laudo.tipos_motivos.forEach(item => {
        //     laudoFormatado.push({value: item.value, label: item.label});
        // })
        setFormulario(laudo);
        props.setLaudoReformaPneuTemp(laudo);
    }

    const excluirLaudoReformaPneu = (id, numeroFogo) => {
        let confirmacao = window.confirm("Realmente deseja excluir o laudo?");
        
        if (confirmacao) {
            let novaLista = listaLaudos.filter(item => 
                !(item.id === id && id !== -1 || item.numero_fogo === numeroFogo) // Remove se o ID for igual OU se o número de fogo for igual
            );
    
            setListaLaudos(novaLista);
            props.novaListaLaudoReformaPneu([...novaLista]);
            setFormulario({id: -1, numero_fogo: "", numero_equipamento: "", data: "",
                obra: "", responsavel: "", reforma: "", posicao: "", medida: "",
                marca: "", serie: "", dot: "", horas_trabalhadas: "", km_rodado: "",
                km_acumulado: "", km_atual: "", medicao_sulco_retirada: "", garantia: "",
                qtd_reforma: "", tipos_motivos: "", motivo_outros: "", tipoobjeto: "", codtipoobjeto: ""        
            });
        }
    };

    const limpaForm = () => {
        setFormulario({id: -1, numero_fogo: "", numero_equipamento: "", data: "",
            obra: "", responsavel: "", reforma: "", posicao: "", medida: "",
            marca: "", serie: "", dot: "", horas_trabalhadas: "", km_rodado: "",
            km_acumulado: "", km_atual: "", medicao_sulco_retirada: "", garantia: "",
            qtd_reforma: "", tipos_motivos: "", motivo_outros: "", tipoobjeto: "", codtipoobjeto: ""     
        });
    }

    return(
        <Stack ml={mobile ? "0px" : "20px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : ""} p={mobile ? "20px" : "5px"}>
            {/* Conteúdo da Tela */}
            <Typography level="h4">Laudo Para Reformar / Sucatear Pneu</Typography>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                <Stack mt={mobile ? "20px" : ""}>
                    <FormLabel>Buscar por Nº Fogo</FormLabel>
                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Input type="text" value={numeroFogoPesquisado} onChange={(event) => setNumeroFogoPesquisado(event.target.value)} disabled={props.desabilitaAprovadores} sx={{ borderTopRightRadius: mobile? "5px":"0", borderBottomRightRadius: mobile? "5px":"0", height: "40px", width: mobile ? "100%" : "212px"}} />
                        <Button disabled={props.desabilitaAprovadores} onClick={() => consultaNumeroFogo()} size="sm" sx={{ borderTopLeftRadius: mobile? "5px":"0", borderBottomLeftRadius: mobile? "5px":"0" , height: "40px"}}><FaSearch /></Button>
                    </Stack>
                </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">

                <Stack mt={mobile ? "20px" : ""}>
                        <FormLabel>Tipo</FormLabel>
                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Input type="text" value={formulario.tipoobjeto} disabled />
                            {/* <Button disabled={props.desabilitaAprovadores} onClick={() => consultaPrefixo()} size="sm" sx={{ borderTopLeftRadius: mobile? "5px": 0, borderBottomLeftRadius: mobile? "5px": 0, height: "40px"}}><FaSearch /></Button> */}
                        </Stack>
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Nº Fogo</FormLabel>
                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Input type="text" value={formulario.numero_fogo} disabled />
                    </Stack>
                </Stack>

                {/* <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Data</FormLabel>
                    <Input type="text" value={formulario.data} disabled />
                </Stack> */}

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Obra</FormLabel>
                    <Input type="text" value={formulario.obra} disabled />
                </Stack>

                {/* <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Responsável</FormLabel>
                    <Input type="text" value={formulario.responsavel} disabled />
                </Stack> */}
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Medida</FormLabel>
                    <Input type="text" value={formulario.medida} disabled />
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Posição</FormLabel>
                    <Input type="text" value={formulario.posicao} disabled />
                </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">

                <Stack mt={mobile ? "20px" : ""}>
                    <FormLabel>Marca</FormLabel>
                    <Input type="text" value={formulario.marca} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Série</FormLabel>
                    <Input type="text" value={formulario.serie} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>DOT</FormLabel>
                    <Input type="text" value={formulario.dot} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Horas trabalhadas</FormLabel>
                    <Input type="text" value={formulario.horas_trabalhadas} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>QTD Reformas Realizadas</FormLabel>
                    <Input type="text" value={formulario.reforma} disabled />
                </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                <Stack mt={mobile ? "20px" : ""}>
                    <FormLabel>Km Acumulado</FormLabel>
                    <Input type="text" value={formulario.km_acumulado} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Medição sulco na retirada</FormLabel>
                    <Input type="text" value={formulario.medicao_sulco_retirada} disabled />
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Garantia</FormLabel>
                    <Input type="text" value={formulario.garantia} disabled />
                </Stack>
            </Stack>

            {/* <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
            </Stack> */}

            <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                <Stack minWidth={mobile ? "100%" : "250px"}  mt={mobile ? "20px" : ""}>
                    <FormLabel>Reforma Atual</FormLabel>
                    <Select disabled={props.desabilitaAprovadores} defaultValue="" value={formulario.qtd_reforma} onChange={(event, newValue) => atualizarFormulario("qtd_reforma", newValue)}>
                        <Option value={0}>1° Vida</Option>
                        <Option value={1}>1° Reforma</Option>
                        <Option value={2}>2° Reforma</Option>
                        <Option value={3}>3° Reforma</Option>
                        <Option value={4}>4° Reforma</Option>
                    </Select>
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} minWidth={mobile ? "100%" : "250px"} height={mobile ? "100%" : "30px"} maxWidth={mobile ? "100%" : "1200px"}  mt={mobile ? "20px" : ""}>
                    <FormLabel>Motivos para Substituição</FormLabel>
                    <MultipleSelect
                        isDisabled={props.desabilitaAprovadores}
                        value={formulario.tipos_motivos}
                        onChange={(e) => setFormulario(prevState => ({ ...prevState, tipos_motivos: e }))}
                        placeholder=""
                        isMulti
                        options={tiposMotivos}
                        noOptionsMessage={() => "Sem opções"}
                    />
                </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt={"30px"}>
                <Stack minWidth={mobile ? "100%" : "530px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>Outros</FormLabel>
                    <Textarea disabled={props.desabilitaAprovadores} value={formulario.motivo_outros} onChange={(event) => atualizarFormulario("motivo_outros", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "530px"}} placeholder="…" />
                </Stack>
            </Stack>

            <Stack flexDirection={"column"} mt="30px">
                <Stack mt={mobile ? "20px" : ""} flexDirection={"row"}>
                    <Button disabled={props.desabilitaAprovadores} onClick={(event) => salvar()} sx={{width: mobile ? "100%" : "150px"}}>{formulario.id !== -1 ? "Alterar" : "Adicionar"}</Button>
                    {formulario.id !== -1 || formulario.numero_fogo ? 
                        <Button disabled={props.desabilitaAprovadores} onClick={(event) => limpaForm()} sx={{width: mobile ? "100%" : "150px", marginLeft: "20px", backgroundColor: "#669bbc"}}>Limpar Formulário</Button>    
                    : null}
                </Stack>
            </Stack>


            {/* laudos cadastrados */}
            <Stack mt="30px">
                { mobile ?
                    <Stack>
                        {listaLaudos.map((item, indice) => {
                            return (
                            <div key={indice}>
                                <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                                    <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Nº Fogo: </Typography>{item.numero_fogo}</Typography>
                                </Stack>
                                {/* <Typography level="body-sm"><Typography level="title-md">Data: </Typography>{item.data}</Typography> */}
                                <Typography level="body-sm"><Typography level="title-md">Obra: </Typography>{item.obra}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Medida: </Typography>{item.medida}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Km Acumulado: </Typography>{item.km_acumulado}</Typography>
                                <Stack level="body-sm" mt="20px">
                                    <Stack flexDirection="row" alignItems="center">
                                    {/* <Button onClick={() => null} color="neutral" sx={{ width: "50%", marginRight: "10px" }}>Ver</Button> */}
                                    { item.id != -1 && (
                                        <>
                                        <div className="hover">
                                            <FaRegEdit onClick={() => editarLaudoReformaPneu(item.id, item.numero_fogo)} color="#2974ac" size={22} />
                                        </div>
                                        </>
                                    )}
                                    { !props.desabilitaAprovadores ?
                                        <div className="hover" style={{ marginLeft: "10px" }}>
                                            <FaRegTrashAlt onClick={() => excluirLaudoReformaPneu(item.id, item.numero_fogo)} color="#990000" size={22} />
                                        </div>
                                    :null
                                    }
                                    </Stack>
                                </Stack>
                                </Stack>
                            </div>
                            );
                        })}
                    </Stack>

                    :
                    
                    <Table hoverRow stripe="even" noWrap aria-label="basic table">
                        <thead>
                            <tr>
                                <th>Nº Fogo</th>
                                {/* <th>Data</th> */}
                                <th>Obra</th>
                                <th>Medida</th>
                                <th>Km Acumulado</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaLaudos.map((item, index) =>
                                <tr key={index}>
                                    <td style={{padding: "0 10px"}}>{item.numero_fogo}</td>
                                    {/* <td style={{padding: "0 10px"}}>{item.data}</td> */}
                                    <td style={{padding: "0 10px"}}>{item.obra}</td>
                                    <td style={{padding: "0 10px"}}>{item.medida}</td>
                                    <td style={{padding: "0 10px"}}>{item.km_acumulado}</td>
                                    <td style={{ padding: "0 10px", display: "flex", alignItems: "center", flexDirection: "row" }}>
                                    { item.id != -1 && (
                                        <>
                                        <div className="hover">
                                            <FaRegEdit onClick={() => editarLaudoReformaPneu(item.id, item.numero_fogo)} color="#2974ac" size={22} />
                                        </div>
                                        </>
                                    )}
                                    { !props.desabilitaAprovadores ? 
                                        <div className="hover" style={{ marginLeft: "10px" }}>
                                            <FaRegTrashAlt onClick={() => excluirLaudoReformaPneu(item.id, item.numero_fogo)} color="#990000" size={22} />
                                        </div>
                                    :null
                                    }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                }
            </Stack>
        </Stack>


    );

}