//importar componentes 
import UsuarioGrupo from '../UsuarioGrupo';
import UsuarioCentroCusto from '../UsuarioCentroCusto';
import { api } from '../../../../services/api';

import { React, useState, useEffect } from "react";

//toastify imports:
import { toast } from 'react-toastify';

// css
import "../../../../App.css";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import MultipleSelect from 'react-select'

//Icones
import { FaListUl } from "react-icons/fa";

//Mui
import {
    Button,
    Stack,
    Typography,
    Table,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Box,
    Select,
    Option,
    ToggleButtonGroup,
    Switch,
    FormHelperText,
} from "@mui/joy";

import { Pagination } from '@mui/material/';

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
let token = "";
let usuarioID = 0;
let usuarioLogin = "";

const telasPermitidasPadrao = [
    { value: "requisicoes", label: "Requisições" },
    { value: "compras", label: "Compras" },
    { value: "admin", label: "Admin" },
    { value: "contratos", label: "Contratos" },
    { value: "followups", label: "Follow Up" },
    { value: "diligenciamentos", label: "Diligenciamento" },
    { value: "almox", label: "Almox" }
];

const labelsPadraoTelas = {
    requisicoes: "Requisicoes",
    compras: "Compras",
    admin: "Admin",
    contratos: "Contratos",
    followups: "Follow Up",
    diligenciamentos: "Diligenciamento",
    almox: "Almox",
}

export default function Usuarios(props) {

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [vizualizacao, setVizualizacao] = useState("usuarios");
    //paginacao
    const [pageUsuario, setPageUsuario] = useState(1);
    const [pageGrupo, setPageGrupo] = useState(1);
    const [lastPageUsuario, setLastPageUsuario] = useState(1);
    const [lastPageGrupo, setLastPageGrupo] = useState(1);
    const handleChangePage = (event, value) => {
        //setPage(value);
        //carrega a lista de usuarios ou grupos, dependendo da visualizacao atual:
        if (vizualizacao == "usuarios") {
            listaUsuarios(value);
        } else {
            listaGrupos(value);
        }
    };
    const [modalUsuarios, setModalUsuarios] = useState(false);
    const [novoUsuario, setNovoUsuario] = useState(false);
    const [usuarioConsultado, setUsuarioConsultado] = useState(
        {
            id: '',
            nome: '',
            email: '',
            telefone: '',
            password: '',
            statusUser: 0,
            comprador: false,
            obra: '',
            login: '',
            matricula: '',
            centro_custo: '',
            telas_permitidas: ''
        });
    const [gruposUsuarioConsultado, setGruposUsuarioConsultado] = useState([]); //grupos do Usuario consultado

    //Variáveis de cadastro de Grupo:
    const [grupoObj, setGrupoObj] = useState({
        id: '',
        nome: '',
        descricao: '',
        obra: '',
        perfil: '',
        tipoPedido: '',
        valorMin: '',
        valorMax: '',
        usuarios: '',

    });
    const [modalGrupos, setModalGrupos] = useState(false);
    const [novoGrupo, setNovoGrupo] = useState(false);
    const [grupos, setGrupos] = useState([]);

    const [excluirUsuario, setExcluirUsuario] = useState(false);
    const [excluirGrupo, setExcluirGrupo] = useState(false);

    const [modaExclusaoUsuario, setModalExclusao] = useState(false);

    const [usuarios, setUsuarios] = useState([]);

    //Variáveis de cadastro de Usuario:
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [telasPermitidas, setTelasPermitidas] = useState('');
    const [password, setPassword] = useState('');
    const [statusUser, setStatusUser] = useState(1);
    const [comprador, setComprador] = useState(false);
    const [obra, setObra] = useState('');
    const [grupo, setGrupo] = useState('');
    const [login, setLogin] = useState('');
    const [matricula, setMatricula] = useState('');
    const [erroUsuario, setErroUsuario] = useState({
        nome: '', email: '', telefone: '', password: '', statusUser: '', obra: '', grupo: '', comprador:'',
        login: '', matricula: '', nomeInvalido: false, emailInvalido: false, telefoneInvalido: false, passwordInvalido: false,
        statusInvalido: false, obraInvalida: false, grupoInvalido: false, loginInvalido: false,
        matriculaInvalida: false, compradorInvalido: false
    });

    const [OpenModalGruposUsuario, setOpenModalGruposUsuario] = useState(false);

    const [erroGrupo, setErroGrupo] = useState({
        nome: '', descricao: '', obra: '', perfil: '', tipoPedido: '', valorMin: '', valorMax: '',
        nomeInvalido: false, descricaoInvalida: false, obraInvalida: false, perfilInvalido: false,
        tipoPedidoInvalido: false, valorMinInvalido: false, valorMaxInvalido: false
    });

    const [termoBuscado, setTermoBuscado] = useState();
    const [termoObraBuscado, setTermoObraBuscado] = useState();

    useEffect(() => {
        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }
        // else {
        //     navigate("/login");
        // }
        listaUsuarios();
        listaGrupos();
    }, []);

    const listaUsuarios = (page = 1) => {
        //Chamando api de listagem de usuários:
        let stringBusca = "/usuariosRelac/?paginacao=true&page=" + page;
        if (termoBuscado && termoBuscado.length > 3){
            stringBusca = stringBusca += "&pesquisa=" + termoBuscado; //se for usado filtragem por nome
        }

        api.get(stringBusca, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then((response) => {
                setPageUsuario(page);
                setLastPageUsuario(response.data.meta.last_page);
                setUsuarios(response.data.data)
                setTermoBuscado("");
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    const validaDadosUsuario = (nome, email, telefone, password, obra, login, matricula) => {
        const erros = [];
        setErroUsuario({
            nome: '', email: '', telefone: '', password: '', statusUser: '', obra: '', grupo: '', comprador: '',
            login: '', matricula: '', nomeInvalido: false, emailInvalido: false, telefoneInvalido: false, passwordInvalido: false,
            statusInvalido: false, obraInvalida: false, grupoInvalido: false, loginInvalido: false,
            matriculaInvalida: false, compradorInvalido: false
        });

        if (nome.trim() == '') {
            erros.nome = 'Informe o nome.';
            erros.nomeInvalido = true;
        }
        if (email.trim() == '') {
            erros.email = 'Informe o e-mail.';
            erros.emailInvalido = true;
        }
        // if (telefone.trim() == '') {
        //     erros.telefone = 'Informe o telefone do usuário.';
        //     erros.telefoneInvalido = true;
        // }
        if (obra.trim() == '') {
            erros.obra = 'Informe a obra.';
            erros.obraInvalida = true;
        }

        if (login.trim() == '') {
            erros.login = 'Informe o login deste usuário.';
            erros.loginInvalido = true;
        }
        if (matricula.trim() == '') {
            erros.matricula = 'Informe a matrícula.';
            erros.matriculaInvalida = true;
        }

        if ((novoUsuario) && (password.trim() == '')) {
            console.log("Cade passw?");
            erros.password = 'Informe a senha deste usuário.';
            erros.passwordInvalido = true;
        }

        if (Object.keys(erros).length > 0) {
            setErroUsuario(erros);
            return false;
        } else {
            return true;
        }
    }

    const handleSubmitUsuario = () => {
        /* Validação dos dados de cadastro de usuário */

        let retornoValidacao = null;

        if (novoUsuario) {
            retornoValidacao = validaDadosUsuario(nome, email, telefone, password, obra, login, matricula);
        } else {
            retornoValidacao = validaDadosUsuario(usuarioConsultado.nome, usuarioConsultado.email, usuarioConsultado.telefone,
                usuarioConsultado.password, usuarioConsultado.obra,
                usuarioConsultado.login, usuarioConsultado.matricula);
        }

        //Se passou na validacao, chamar Api para salvar dados:
        if (retornoValidacao) {
            //MODO CREATE, encaminhar para POST
            if (novoUsuario) {
                criaUsuario();
            } else {
                //MODO EDIT, encaminhar para PUT
                editaUsuario();
            }
        }
        else {
            return;
        }
    }

    const criaUsuario = () => {
        //Criar um novo usuario no Banco de dados

        let telasPermitidasString = "";
        telasPermitidas.forEach((tela, indice) => {
            telasPermitidasString += tela.value;
            if ((indice + 1) != telasPermitidas.length) {
                telasPermitidasString += ",";
            }
        });

        if (telasPermitidasString == "") {
            toast.warning("Você precisa escolher pelo menos uma tela permitida!");
            return;
        }

        const dadosUsuario = {
            nome: nome,
            email: email,
            telefone: telefone,
            password: password,
            statusUser: statusUser,
            comprador: comprador,
            obra: obra,
            login: login,
            matricula: matricula,
            autenticacao: telasPermitidasString
        }

        // console.log("Criando usuário");
        // console.log(dadosUsuario);

        api.post("/usuarios/",
            dadosUsuario,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                toast.success("Usuário cadastrado com sucesso!");
                setModalUsuarios(false);
                listaUsuarios();
                limparFormUsuario();
            }).catch(error => {
                console.log("Erro no criaUsuario" + error);
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const editaUsuario = () => {
        //Editar usuário no Banco de dados

        let telasPermitidasString = "";
        usuarioConsultado.telas_permitidas.forEach((tela, indice) => {
            telasPermitidasString += tela.value;
            if ((indice + 1) != usuarioConsultado.telas_permitidas.length) {
                telasPermitidasString += ",";
            }
        });

        if (telasPermitidasString == "") {
            toast.warning("Você precisa escolher pelo menos uma tela permitida!");
            return;
        }

        const dadosUsuario = {
            id: usuarioConsultado.id,
            nome: usuarioConsultado.nome,
            email: usuarioConsultado.email,
            telefone: usuarioConsultado.telefone,
            password: usuarioConsultado.password,
            statusUser: usuarioConsultado.statusUser,
            comprador: usuarioConsultado.comprador,
            obra: usuarioConsultado.obra,
            login: usuarioConsultado.login,
            matricula: usuarioConsultado.matricula,
            autenticacao: telasPermitidasString
        }

        // console.log("Editando usuário");
        // console.log(dadosUsuario);

        api.put("/usuarios/" + usuarioConsultado.id,
            dadosUsuario,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            },
        ).
            then((response) => {
                toast.success("Usuário atualizado com sucesso!");
                setModalUsuarios(false);
                listaUsuarios();
                limparFormUsuario();
            }).catch(error => {
                console.log("Erro no editaUsuario" + error);
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const limparFormUsuario = () => {
        /* 
            Limpa o formulário de dados do usuario removendo qualquer dado carregado.
        */

        setErroUsuario({
            nome: '', email: '', telefone: '', password: '', statusUser: '', obra: '', grupo: '', comprador: '',
            login: '', matricula: '', nomeInvalido: false, emailInvalido: false, telefoneInvalido: false, passwordInvalido: false,
            statusInvalido: false, obraInvalida: false, grupoInvalido: false, loginInvalido: false,
            matriculaInvalida: false, compradorInvalido: false
        });

        setNome('');
        setEmail('');
        setTelefone('');
        setPassword('');
        setStatusUser(1);
        setComprador(0);
        setObra('');
        setGrupo('');
        setLogin('');
        setMatricula('');
    }

    //Métodos de Grupo
    const listaGrupos = (page = 1) => {
        let stringBusca = "/grupos/?page=" + page;
        if(termoObraBuscado && termoObraBuscado.length >= 3) {
            stringBusca = stringBusca += "&pesquisa=" + termoObraBuscado;
        }
        //Chamando api de listagem de grupos:
        api.get(stringBusca, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then((response) => {
                setPageGrupo(page);
                setLastPageGrupo(response.data.meta.last_page);
                setGrupos(response.data.data);
                setTermoObraBuscado("");
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro-listaGrupos : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    const criaGrupo = () => {
        //Criar um novo grupo no Banco de dados
        const dadosGrupo = {
            nome: grupoObj.nome,
            descricao: grupoObj.descricao,
            obra: grupoObj.obra,
            perfil: "",
            tipoPedido: "",
            valorMin: "",
            valorMax: ""
        }


        api.post("/grupos/",
            dadosGrupo,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then((response) => {

                toast.success("Grupo cadastrado com sucesso!");
                setModalGrupos(false);
                limpaFormGrupo();
                listaGrupos();
            }).catch(error => {
                console.log("Erro no criaGrupo" + error);
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const editaGrupo = () => {
        //Criar um novo grupo no Banco de dados
        const dadosGrupo = {
            id: grupoObj.id,
            nome: grupoObj.nome,
            descricao: grupoObj.descricao,
            obra: grupoObj.obra,
            perfil: grupoObj.perfil,
            tipoPedido: grupoObj.tipoPedido,
            valorMin: grupoObj.valorMin,
            valorMax: grupoObj.valorMax
        }

        api.put("/grupos/" + grupoObj.id,
            dadosGrupo,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then((response) => {

                toast.success("Grupo alterado com sucesso!");
                setModalGrupos(false);
                limpaFormGrupo();
                listaGrupos();
            }).catch(error => {
                console.log("Erro no editaGrupo" + error);
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const validaDadosGrupo = (nome, descricao, obra, perfil, tipoPedido, valorMin, valorMax) => {
        const erros = [];
        setErroGrupo({
            nome: '', descricao: '', obra: '', perfil: '', tipoPedido: '', valorMin: '', valorMax: '',
            nomeInvalido: false, descricaoInvalida: false, obraInvalida: false, perfilInvalido: false,
            tipoPedidoInvalido: false, valorMinInvalido: false, valorMaxInvalido: false
        });

        if (nome.trim() == '') {
            erros.nome = 'Informe o nome da obra';
            erros.nomeInvalido = true;
        }
        if (descricao.trim() == '') {
            erros.descricao = 'Informe a descricao.';
            erros.descricaoInvalida = true;
        }
        if (obra.trim() == '') {
            erros.obra = 'Informe a obra.';
            erros.obraInvalida = true;
        }
        // if (perfil == '') {
        //     erros.perfil = 'Informe o perfil.';
        //     erros.perfilInvalido = true;
        // }
        // if (tipoPedido == null) {
        //     erros.tipoPedido = 'Informe o tipo de pedido.';
        //     erros.tipoPedidoInvalido = true;
        // }
        // if (valorMin.trim() == '') {
        //     erros.valorMin = 'Informe o valor mínimo.';
        //     erros.valorMinInvalido = true;
        // }
        // if (valorMax.trim() == '') {
        //     erros.valorMax = 'Informe o valor máximo.';
        //     erros.valorMaxInvalido = true;
        // }

        if (Object.keys(erros).length > 0) {
            setErroGrupo(erros);
            return false;
        } else {
            return true;
        }
    }

    const handleSubmitGrupo = () => {

        let sucessoValidacao = validaDadosGrupo(grupoObj.nome, grupoObj.descricao, grupoObj.obra, grupoObj.perfil, grupoObj.tipoPedido, grupoObj.valorMin, grupoObj.valorMax);

        if (sucessoValidacao) {
            if (novoGrupo) {
                criaGrupo();
            } else {
                console.log("Editar grupo...");
                editaGrupo();
            }
        } else {
            return;
        }
    }

    const limpaFormGrupo = () => {
        setErroGrupo({
            nome: '', descricao: '', obra: '', perfil: '', tipoPedido: '', valorMin: '', valorMax: '',
            nomeInvalido: false, descricaoInvalida: false, obraInvalida: false, perfilInvalido: false,
            tipoPedidoInvalido: false, valorMinInvalido: false, valorMaxInvalido: false
        });
        setGrupoObj({ id: '', nome: '', descricao: '', obra: '', perfil: '', tipoPedido: '', valorMin: '', valorMax: '', usuarios: '' });
    }

    const [mostrarCompUsuariosGrupo, setMostrarCompUsuariosGrupo] = useState(false);

    const removeUsuario = (usuario_id) => {
        // url delete ordem de /grupo/usuario
        api.delete("/usuarios/" + usuario_id + "/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                toast.info("Usuário removido com sucesso!");
                //recarrega para refazer a lista de usuários:
                listaUsuarios();
                setUsuarioConsultado({});
                setExcluirUsuario(false);
            })
            .catch(error => {
                toast.error("Não foi possível remover este usuário. Verifique se ele possui relação com centro de custo e grupo antes de removê-lo.");
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const removeGrupo = (grupo_id) => {
        // url delete ordem de /grupo/usuario
        api.delete("/grupos/" + grupo_id + "/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                toast.info("Grupo removido com sucesso!");
                //recarrega para refazer a lista de usuários:
                listaGrupos();
                setGrupoObj({});
                setExcluirGrupo(false);
            })
            .catch(error => {
                toast.error("Não foi possível remover este Grupo. Verifique se ele possui relação com usuário e perfil antes de removê-lo.");
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const realizaExclusao = () => {
        /*
         Este método verifica qual objeto será deletado (usuário ou grupo) e envia para o método de exclusão correto.
         */
        if (excluirUsuario) {
            removeUsuario(usuarioConsultado.id);
        }
        else if (excluirGrupo) {
            removeGrupo(grupoObj.id);
        }
    }

    // Função para formatar o número de telefone no padrão brasileiro
    const formatarTelefone = (input) => {
        const telefoneFormatado = input.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        return telefoneFormatado;
    };

    // Manipulador do evento onChange
    const handleTelefoneChange = (e) => {
        const input = e.target.value;
        const telefoneFormatado = formatarTelefone(input);
        // setTelefone(telefoneFormatado);
        novoUsuario ? setTelefone(telefoneFormatado) : setUsuarioConsultado({ ...usuarioConsultado, telefone: telefoneFormatado })
    };

    const validarObra = (obra) => {
        if (obra.length != 6 || obra[3] != "." || obra.replace(/\D/g, "").length != 5) {
            setErroGrupo(prevState => ({
                ...prevState,
                obraInvalida: true,
            }));
        }
        else {
            setErroGrupo(prevState => ({
                ...prevState,
                obraInvalida: false,
            }));
        }
    }

    const prepararDadosUsuario = (params) => {

        let arrayTelasPermitidas = [];
        if (params.telas_permitidas.length > 0) {
            params.telas_permitidas.split(",").forEach(tela => {
                arrayTelasPermitidas.push({
                    value: tela,
                    label: labelsPadraoTelas[tela]
                });
            });
        }

        setUsuarioConsultado({
            id: params.id,
            nome: params.nome,
            email: params.email,
            telefone: params.telefone,
            password: params.password,
            statusUser: params.statusUser,
            comprador: params.comprador,
            obra: params.obra,
            login: params.login,
            matricula: params.matricula,
            centro_custo: params.centro_custo,
            telas_permitidas: arrayTelasPermitidas
        });

        setModalUsuarios(true);
        setNovoUsuario(false);
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100vh">
            <Header mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">{vizualizacao == "usuarios" ? "Usuários" : "Grupos"}</Typography>
                <Stack flexDirection="row" justifyContent={"space-between"}>
                    <ToggleButtonGroup
                        value={vizualizacao}
                        onChange={(event, newValue) => {
                            setVizualizacao(newValue != null ? newValue : vizualizacao);
                        }}
                    >
                        <Button value="usuarios">Usuários</Button>
                        <Button value="grupos">Grupos</Button>
                    </ToggleButtonGroup>
                    <Stack flexDirection={"row"}>
                        <Button
                            sx={mobile ? { fontSize: "12px" } : { fontSize: "14px" }}
                            onClick={() => vizualizacao == "usuarios" ? [setModalUsuarios(true), setNovoUsuario(true), setUsuarioConsultado({})]
                                : [setModalGrupos(true), setNovoGrupo(true)]
                            }
                        >
                            {vizualizacao == "usuarios" ? "NOVO USUÁRIO" : "NOVO GRUPO"}
                        </Button>
                    </Stack>
                </Stack>
                {mobile ?
                    <Stack mt="30px">
                        {vizualizacao == "usuarios" ?
                            <Stack>
                            <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} width="300px" ml="30px" mt="15px" mb="15px">
                                <Stack display={"flex"} flexDirection={"column"} width="300px" mr="10px">
                                    <Input placeholder="Filtrar por nome" value={termoBuscado} onChange={(e) => setTermoBuscado(e.target.value)} />
                                </Stack>
                                <Button sx={{height:"40px"}}  onClick={() => listaUsuarios()} color="neutral">Filtrar</Button>
                            </Stack>
                                {usuarios.map(function (item, indice) {
                                    return (
                                        <div key={indice} className="hover" onClick={() => [setModalUsuarios(true), setNovoUsuario(false), setUsuarioConsultado({ matricula: item.matricula, nome: item.nome, login: item.login, email: item.email, telefone: item.telefone, grupo: item.grupo, statusUser: item.statusUser, obra: item.obra, id: item.id, centro_custo: item.centro_custo, comprador: item.comprador })]}>
                                            <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação:</Typography> 46434</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Matrícula:</Typography>{item.matricula}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Nome de completo:</Typography>{item.nome}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Nome Usuário:</Typography>{item.login}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">E-mail:</Typography>{item.email}</Typography>
                                                {/* <Typography level="body-sm"><Typography level="title-md">Grupo:</Typography>{item.grupo}</Typography> */}
                                            </Stack>
                                        </div>
                                    )
                                })}
                            </Stack>
                            :
                            <Stack>
                                <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} width="300px" ml="30px" mt="15px" mb="15px">
                                    <Stack display={"flex"} flexDirection={"column"} width="300px" mr="10px">
                                        <Input placeholder="Filtrar por obra" value={termoObraBuscado} onChange={(e) => setTermoObraBuscado(e.target.value)} />
                                    </Stack>
                                    <Button sx={{height:"40px"}}  onClick={() => listaGrupos()} color="neutral">Filtrar</Button>
                                </Stack>
                                {grupos.map(function (grupo, indice) {
                                    return (
                                        <div key={indice} className="hover" onClick={() => [setModalGrupos(true), setNovoGrupo(false), setGrupoObj({ id: grupo.id, nome: grupo.nome, descricao: grupo.descricao, obra: grupo.obra, perfil: grupo.perfil, tipoPedido: grupo.tipoPedido, valorMin: grupo.valorMin, valorMax: grupo.valorMax, usuarios: grupo.usuarios })]}>
                                            <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Nome: </Typography>{grupo.nome}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Descrição: </Typography>{grupo.descricao}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Obra: </Typography>{grupo.obra}</Typography>
                                                {/* <Typography level="body-sm"><Typography level="title-md">Perfil:</Typography>{grupo.perfil}</Typography> */}
                                            </Stack>
                                        </div>
                                    )
                                })}
                            </Stack>
                        }
                    </Stack>
                    :
                    <Stack mt="50px">
                        {/* Visualizacao desktop de usuarios */}
                        {vizualizacao == "usuarios" ?
                            <>
                            <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} width="300px" ml="40px" mt="15px" mb="15px">
                                <Stack display={"flex"} flexDirection={"column"} width="300px" mr="10px">
                                    <Input placeholder="Filtrar por nome" value={termoBuscado} onChange={(e) => setTermoBuscado(e.target.value)} />
                                </Stack>
                                <Button sx={{height:"40px"}}  onClick={() => listaUsuarios()} color="neutral">Filtrar</Button>
                            </Stack>
                            <Table hoverRow stripe="even" noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "80px" }}>Matrícula</th>
                                        <th style={{ height: "25px" }}>Nome Completo</th>
                                        <th style={{ height: "25px", width: "200px" }}>Nome de Usuário</th>
                                        <th style={{ height: "25px", width: "300px" }}>E-mail</th>
                                        {/* <th style={{ height: "25px", width: "80px", textAlign: "center" }}>Grupo</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {usuarios.map(function (item, indice) {
                                        return (
                                            <tr key={indice} className="hover" onClick={() => prepararDadosUsuario({ matricula: item.matricula, nome: item.nome, login: item.login, email: item.email, telefone: item.telefone, grupo: item.grupo, statusUser: item.statusUser, obra: item.obra, id: item.id, centro_custo: item.centro_custo, telas_permitidas: item.autenticacao, comprador: item.comprador })}>
                                                <td style={{ padding: "0 10px" }}>
                                                    {item.matricula}</td>
                                                <td style={{ padding: "0 10px" }}>
                                                    {item.nome}</td>
                                                <td style={{ padding: "0 10px" }}>
                                                    {item.login}</td>
                                                <td style={{ padding: "0 10px" }}>
                                                    {item.email}</td>
                                                {/* <td style={{ padding: "0 10px", textAlign: "center" }} onClick={() => [setOpenModalGruposUsuario(true), setGruposUsuarioConsultado(item.grupo)]}>
                                                    <FaListUl></FaListUl>
                                                </td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </>
                            :
                            <>
                            <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} width="300px" ml="40px" mt="15px" mb="15px">
                                <Stack display={"flex"} flexDirection={"column"} width="300px" mr="10px">
                                    <Input placeholder="Filtrar por obra" value={termoObraBuscado} onChange={(e) => setTermoObraBuscado(e.target.value)} />
                                </Stack>
                                <Button sx={{height:"40px"}}  onClick={() => listaGrupos()} color="neutral">Filtrar</Button>
                            </Stack>
                            <Table hoverRow stripe="even" noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "200px" }}>Nome</th>
                                        <th style={{ height: "25px" }}>Descrição</th>
                                        <th style={{ height: "25px", width: "100px" }}>Obra</th>
                                        {/* <th style={{ height: "25px", width: "250px" }}>Perfil</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {grupos.map(function (grupo, indice) {
                                        // console.log(grupo);
                                        return (
                                            <tr key={indice} className="hover" onClick={() => [setModalGrupos(true), setNovoGrupo(false), setGrupoObj({ id: grupo.id, nome: grupo.nome, descricao: grupo.descricao, obra: grupo.obra, perfil: grupo.perfil, tipoPedido: grupo.tipoPedido, valorMin: grupo.valorMin, valorMax: grupo.valorMax, usuarios: grupo.usuarios })]}>
                                                <td style={{ padding: "0 10px" }}>{grupo.nome}</td>
                                                <td style={{ padding: "0 10px" }}>{grupo.descricao}</td>
                                                {/* <td style={{ padding: "0 10px" }}>{listaTiposServico[grupo.tipoPedido]}</td> */}
                                                <td style={{ padding: "0 10px" }}>{grupo.obra}</td>
                                                {/* <td style={{ padding: "0 10px" }}>{grupo.perfil}</td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </>
                        }
                    </Stack>
                }
                <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                    {vizualizacao == "usuarios" ?
                        <>
                            <Pagination count={lastPageUsuario} page={pageUsuario} onChange={handleChangePage} /></>
                        :
                        <>
                            <Pagination count={lastPageGrupo} page={pageGrupo} onChange={handleChangePage} /></>
                    }

                </Stack>
            </Stack>

            <Modal
                open={modalUsuarios}
                onClose={() => [setModalUsuarios(false), limparFormUsuario()]}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '80%',
                        maxWidth: 600,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Dados do Usuário
                    </Typography>
                    <Stack mt="30px" height="60vh" overflow={"auto"}>
                        <Stack>
                            {/* Campo id para o modo Edicao */}
                            {novoUsuario == false && (<Input value={usuarioConsultado.id} onChange={(e) => setUsuarioConsultado({ ...usuarioConsultado, id: e.target.value })} style={{ display: 'none' }} />)}

                            <FormControl error={erroUsuario.matriculaInvalida}>
                                <FormLabel required>Matrícula</FormLabel>
                                <Input value={novoUsuario ? matricula : usuarioConsultado.matricula} onChange={(e) => novoUsuario ? setMatricula(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, matricula: e.target.value })} />

                                {erroUsuario.matriculaInvalida && (
                                    <FormHelperText error={erroUsuario.matriculaInvalida}>
                                        {erroUsuario.matricula}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.nomeInvalido}>
                                <FormLabel required>Nome Completo</FormLabel>
                                <Input value={novoUsuario ? nome : usuarioConsultado.nome} onChange={(e) => novoUsuario ? setNome(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, nome: e.target.value })} />

                                {erroUsuario.nomeInvalido && (
                                    <FormHelperText error={erroUsuario.nomeInvalido}>
                                        {erroUsuario.nome}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.loginInvalido}>
                                <FormLabel required>Nome de Usuário</FormLabel>
                                <Input value={novoUsuario ? login : usuarioConsultado.login} onChange={(e) => novoUsuario ? setLogin(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, login: e.target.value })} />

                                {erroUsuario.loginInvalido && (
                                    <FormHelperText error={erroUsuario.loginInvalido}>
                                        {erroUsuario.login}
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.passwordInvalido}>
                                <FormLabel required>Senha</FormLabel>
                                <Input type="password" value={novoUsuario ? password : usuarioConsultado.password} onChange={(e) => novoUsuario ? setPassword(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, password: e.target.value })} />

                                {erroUsuario.passwordInvalido && (
                                    <FormHelperText error={erroUsuario.passwordInvalido}>
                                        {erroUsuario.password}
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.obraInvalida}>
                                <FormLabel required>Obra</FormLabel>
                                <Input value={novoUsuario ? obra : usuarioConsultado.obra} onChange={(e) => novoUsuario ? setObra(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, obra: e.target.value })} />

                                {erroUsuario.obraInvalida && (
                                    <FormHelperText error={erroUsuario.obraInvalida}>
                                        {erroUsuario.obra}
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.emailInvalido}>
                                <FormLabel required>E-mail</FormLabel>
                                <Input value={novoUsuario ? email : usuarioConsultado.email} onChange={(e) => novoUsuario ? setEmail(e.target.value) : setUsuarioConsultado({ ...usuarioConsultado, email: e.target.value })} />

                                {erroUsuario.emailInvalido && (
                                    <FormHelperText error={erroUsuario.emailInvalido}>
                                        {erroUsuario.email}
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroUsuario.telefoneInvalido}>
                                <FormLabel required>Telefone</FormLabel>
                                <Input
                                    value={novoUsuario ? telefone : usuarioConsultado.telefone}
                                    onChange={handleTelefoneChange} placeholder='(00)00000-0000'
                                />
                                {erroUsuario.telefoneInvalido && (
                                    <FormHelperText error={erroUsuario.telefoneInvalido}>
                                        {erroUsuario.telefone}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>

                        <Stack mt="20px">
                            <Typography fontSize="14px">Telas Permitidas</Typography>
                            <MultipleSelect
                                value={novoUsuario ? telasPermitidas : usuarioConsultado.telas_permitidas}
                                onChange={(e) => novoUsuario ? setTelasPermitidas(e) : setUsuarioConsultado({ ...usuarioConsultado, telas_permitidas: e })}
                                placeholder=""
                                isMulti
                                options={telasPermitidasPadrao}
                                noOptionsMessage={() => "Sem opções"}
                            />
                        </Stack>

                        {/* <Stack mt="15px">
                            <FormControl>
                                <FormLabel required>Grupo</FormLabel>
                                <Select>
                                    <Option value="Aprovadores Técnicos (Sede)">Aprovadores Técnicos (Sede)</Option>
                                    <Option value="Aprovadores Técnicos (290.00)">Aprovadores Técnicos (290.00)</Option>
                                    <Option value="Aprovadores Técnicos (300.00)">Aprovadores Técnicos (300.00)</Option>
                                    <Option value="Aprovadores Gerenciais (Sede)">Aprovadores Gerenciais (Sede)</Option>
                                    <Option value="Aprovadores Gerenciais (290.00)">Aprovadores Gerenciais (290.00)</Option>
                                    <Option value="Aprovadores Gerenciais (300.00)">Aprovadores Gerenciais (300.00)</Option>
                                </Select>
                            </FormControl>
                        </Stack> */}
                        <Stack flexDirection={"row"} mt="15px">
                            <FormControl>
                                <FormLabel required>Ativo?</FormLabel>
                                <Switch
                                    checked={novoUsuario ? statusUser == 1 : usuarioConsultado.statusUser == 1}
                                    onChange={(event) => novoUsuario ? setStatusUser(event.target.checked ? 1 : 0) : setUsuarioConsultado({ ...usuarioConsultado, statusUser: event.target.checked ? 1 : 0 })}
                                />
                            </FormControl>


                            <FormControl sx={{ marginLeft: "20px" }}>
                                <FormLabel required>Comprador?</FormLabel>
                                <Switch
                                    checked={novoUsuario ? comprador == 1 : usuarioConsultado.comprador == 1}
                                    onChange={(event) => novoUsuario ? setComprador(event.target.checked ? 1 : 0) : setUsuarioConsultado({ ...usuarioConsultado, comprador: event.target.checked ? 1 : 0 })}
                                />
                            </FormControl>
                        </Stack>
                        {/* <Checkbox checked={formularioEtapa.bloqueado == 1} onChange={(e) => handleChangeFormularioEtapa(e, 'bloqueado')} /> */}

                        {/* Na edição do Usuario, ele pode ser adicionado ao centro-custo */}
                        {!novoUsuario ?
                            <Stack alignItems="center" mt="30px">
                                <UsuarioCentroCusto usuarioConsultado={{ id: usuarioConsultado.id, nome: usuarioConsultado.nome, centro_custo: usuarioConsultado.centro_custo }} mobile={mobile}
                                    abrirModal={true} centrosCustos={usuarioConsultado.centro_custo}
                                />
                                {/* TODO: centrosCustos precisa ser carregado da api */}
                            </Stack>
                            : null}

                    </Stack>
                    <Stack mt="50px" flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                        <Button disabled={novoUsuario} onClick={() => [setModalExclusao(true), setExcluirUsuario(true), setExcluirGrupo(false)]} color="danger">EXCLUIR</Button>
                        <Button onClick={handleSubmitUsuario}>Salvar</Button>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                open={modaExclusaoUsuario}
                onClose={() => setModalExclusao(false)}
            >
                <ModalDialog
                    variant="outlined"
                    role="alertdialog"
                    aria-labelledby="alert-dialog-modal-title"
                    aria-describedby="alert-dialog-modal-description"
                >
                    <Typography
                        id="alert-dialog-modal-title"
                        level="h2"
                    // startDecorator={<WarningRoundedIcon />}
                    >
                        Confirmação
                    </Typography>
                    <Divider />
                    <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                        Tem certeza que deseja excluir o registro?
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                        <Button variant="plain" color="neutral" onClick={() => [setModalExclusao(false), setExcluirGrupo(false), setExcluirUsuario(false)]}>
                            Cancelar
                        </Button>
                        <Button variant="solid" color="danger" onClick={() => [setModalExclusao(false), setModalUsuarios(false), setModalGrupos(false), realizaExclusao()]}>
                            Confirmar
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>

            <Modal
                open={modalGrupos}
                onClose={() => [setModalGrupos(false), limpaFormGrupo()]}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '80%',
                        maxWidth: 600,
                        // height: "50%",
                        // height: "430px",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        outline: 'none'
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Dados do Grupo
                    </Typography>
                    <Stack mt="30px" overflow={"auto"}>
                        <Stack>
                            {/* Campo id para o modo Edicao */}
                            {novoGrupo == false && (<Input value={grupoObj.id} onChange={(e) => setGrupoObj({ ...grupoObj, id: e.target.value })} style={{ display: 'none' }} />)}

                            <FormControl error={erroGrupo.nomeInvalido}>
                                <FormLabel required>Nome</FormLabel>
                                <Input value={grupoObj.nome} onChange={(e) => setGrupoObj({ ...grupoObj, nome: e.target.value })} placeholder="" />

                                {erroGrupo.nomeInvalido && (
                                    <FormHelperText error={erroGrupo.nomeInvalido}>
                                        {erroGrupo.nome}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroGrupo.descricaoInvalida}>
                                <FormLabel required>Descrição</FormLabel>
                                <Input value={grupoObj.descricao} onChange={(e) => setGrupoObj({ ...grupoObj, descricao: e.target.value })} placeholder="" />

                                {erroGrupo.descricaoInvalida && (
                                    <FormHelperText error={erroGrupo.descricaoInvalida}>
                                        {erroGrupo.descricao}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        <Stack mt="15px">
                            <FormControl error={erroGrupo.obraInvalida}>
                                <FormLabel required>Obra</FormLabel>
                                <Input value={grupoObj.obra} onChange={(e) => setGrupoObj({ ...grupoObj, obra: e.target.value })} onBlur={(e) => validarObra(e.target.value)} placeholder="" />

                                {erroGrupo.obraInvalida && (
                                    <FormHelperText error={erroGrupo.obraInvalida}>
                                        Obra Inválida!
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        {/* <Stack mt="15px">
                            <FormControl error={erroGrupo.perfilInvalido}>
                                <FormLabel required>Perfil</FormLabel>
                                <Select placeholder="Escolha um perfil"
                                    value={grupoObj.perfil}
                                    onChange={(e, newValue) => setGrupoObj({ ...grupoObj, perfil: newValue })} >
                                    <Option value="Solicitante">Solicitante</Option>
                                    <Option value="Aprov. Técnico">Aprovador Técnico</Option>
                                    <Option value="Aprov. Gerencial">Aprovador Gerencial</Option>
                                    <Option value="Aprov. Institucional">Aprovador Institucional</Option>
                                    <Option value="Aprov. mcp1">Aprovador MCP1</Option>
                                    <Option value="Aprov. mcp2">Aprovador MCP2</Option>
                                    <Option value="Aprov. mcpf">Aprovador MCPF</Option>
                                    <Option value="Aprov. gestor adm suprimentos">Aprovador Gestor ADM Suprimentos</Option>
                                    <Option value="Aprov. gestor contrato/equipamentos">Aprovador Gestor Contrato/Equipamentos</Option>
                                    <Option value="Aprov. gestor corporativo equipamentos">Aprovador Gestor Corporativo Equipamentos</Option>
                                    <Option value="Aprov. diretoria suporte operacional">Aprovador Diretoria Suporte Operacional</Option>
                                    <Option value="Aprov. diretoria equipamentos">Aprovador Diretoria Equipamentos</Option>
                                    <Option value="Aprov. diretoria operações">Aprovador Diretoria Operações</Option>
                                    <Option value="Aprov. diretoria geral">Aprovador Diretoria Geral</Option>


                                </Select>

                                {erroGrupo.perfilInvalido && (
                                    <FormHelperText error={erroGrupo.perfilInvalido}>
                                        {erroGrupo.perfil}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>

                        <Stack mt="15px">
                            <FormControl error={erroGrupo.tipoPedidoInvalido}>
                                <FormLabel required>Tipo Pedido</FormLabel>
                                <Select placeholder="Escolha um tipo Pedido"
                                    value={grupoObj.tipoPedido}
                                    onChange={(e, newValue) => setGrupoObj({ ...grupoObj, tipoPedido: newValue })}
                                >
                                    <Option value="001">Materiais de Consumo</Option>
                                    <Option value="002">Prestadores de Serviços</Option>
                                    <Option value="003">Materiais de Aplicação</Option>
                                    <Option value="004">Materiais Engenheirados</Option>
                                    <Option value="005">Materiais de Partes e Peças</Option>
                                    <Option value="006">Serviços em Equipamentos</Option>
                                    <Option value="007">Locações de Equipamentos</Option>
                                    <Option value="008">Serviços Subcontratados</Option>
                                </Select>

                                {erroGrupo.tipoPedidoInvalido && (
                                    <FormHelperText error={erroGrupo.tipoPedidoInvalido}>
                                        {erroGrupo.tipoPedido}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>

                        <Stack mt="15px">
                            <FormControl error={erroGrupo.valorMinInvalido}>
                                <FormLabel required>Valo Mínimo</FormLabel>
                                <Input value={grupoObj.valorMin} onChange={(e) => setGrupoObj({ ...grupoObj, valorMin: e.target.value })} placeholder="" />

                                {erroGrupo.valorMinInvalido && (
                                    <FormHelperText error={erroGrupo.valorMinInvalido}>
                                        {erroGrupo.valorMin}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>

                        <Stack mt="15px">
                            <FormControl error={erroGrupo.valorMaxInvalido}>
                                <FormLabel required>Valor Máximo</FormLabel>
                                <Input value={grupoObj.valorMax} onChange={(e) => setGrupoObj({ ...grupoObj, valorMax: e.target.value })} placeholder="" />

                                {erroGrupo.valorMaxInvalido && (
                                    <FormHelperText error={erroGrupo.valorMaxInvalido}>
                                        {erroGrupo.valorMax}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack> */}

                        {!novoGrupo ?
                            <Stack alignItems="center" mt="30px">
                                {/* <Button onClick={handleMostrarCompUsuariosGrupo} variant="plain">Ver usuários</Button> */}
                                <UsuarioGrupo grupoConsultado={{ id: grupoObj.id, nome: grupoObj.nome, usuarios: grupoObj.usuarios }} mobile={mobile}
                                    abrirModal={mostrarCompUsuariosGrupo} usuarios={usuarios} />
                            </Stack>
                            : null}

                    </Stack>
                    <Stack mt="30px" flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                        <Button disabled={novoGrupo} onClick={() => [setModalExclusao(true), setExcluirGrupo(true), setExcluirUsuario(false)]} color="danger">EXCLUIR</Button>
                        <Button disabled={erroGrupo.obraInvalida} onClick={handleSubmitGrupo}>Salvar</Button>
                    </Stack>
                </Sheet>
            </Modal>

            {/* {mostrarCompUsuariosGrupo && (
                <UsuarioGrupo grupoConsultado={{ id: grupoObj.id, nome: grupoObj.nome, usuarios: grupoObj.usuarios }} mobile={mobile}
                    abrirModal={mostrarCompUsuariosGrupo} usuarios={usuarios} />
            )} */}


            {/* Modal que mostra os grupos de um usuario da lista de usuarios */}
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={OpenModalGruposUsuario}
                onClose={() => setOpenModalGruposUsuario(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Grupos do Usuário
                    </Typography>

                    {gruposUsuarioConsultado.map((objeto, index) => (
                        // <span key={index}></span>
                        <Typography
                            key={index}
                            component="h2"
                            id="modal-title"
                            level="h7"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            {objeto.nome}
                        </Typography>
                    ))}

                </Sheet>
            </Modal>
        </Stack>
    );
}