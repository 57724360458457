import { useState, useEffect, useRef } from "react";

import axios from "axios";
import { formatarDataHoraBrasil } from "../../../../../utils/datas";
import { toast } from 'react-toastify';

// Icones
import { FaSearch } from "react-icons/fa";
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen } from "react-icons/fa";
// import { IoChevronBack, IoArrowForwardSharp, IoCheckmarkSharp, IoWarning, IoDocumentText, IoArrowDownOutline, IoCubeOutline, IoInformationCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
// import { HiOutlineMinus } from "react-icons/hi";
// import { FaLongArrowAltDown, FaLongArrowAltRight } from "react-icons/fa";
// import { AiFillMessage } from "react-icons/ai";
// import { TfiSplitH } from "react-icons/tfi";

//componentes
import Header from "../../../../../components/Header";
import Sidebar from "../../../../../components/Sidebar";

//Mui
import { Stack, Typography, Button, Modal, Link, Sheet, ModalClose, Select,
         Option, Avatar, Card, Dropdown, MenuButton, Menu, MenuItem, Switch,
         Radio, List, ListItem, ListItemButton, ListItemContent,
         ListItemDecorator, FormHelperText, RadioGroup, FormLabel, Input, Textarea, Table } from "@mui/joy";

//Roteamento
import { useNavigate, useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api, RM } from "../../../../../services/api";
import realizaConsulta from "../../../../../services/rm";

//Largura da tela
const larguraTela = window.innerWidth;

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let permissoes = [];

export default function InspecaoMaterialRodante(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const mobile = useMediaQuery('(max-width:1200px)');

    const [prefixoPesquisado, setPrefixoPesquisado] = useState("");
    const [formulario, setFormulario] = useState(
    {
        id: -1,
        prefixo: "",
        tipoobjeto: "",
        codtipoobjeto: "",
        data: "",
        horimetro: "",
        modelo: "",
        fabricante: "",
        serie: "",
        obra: "",
        numeroOS: "",
        responsavel: "",
        umidade: "",
        acumulo: "",
        abrasao: "",
        impacto: "",
        girados: "",
        lubrificada: "",
        bucha_ressalto: "",
        parte_rodante_ld: "",
        parte_rodante_le: "",
        observacoes: "",
        componentes: ""
    }
    );

    const [listaInspecoes, setListaInspecoes] = useState([
        {
            id: -1,
            prefixo: "128",
            data: "20/02/2025",
            horimetro: "200",
            modelo: "WP",
            fabricante: "Marcopolo",
            serie: "",
            obra: "",
            numeroOS: "",
            responsavel: "",
            umidade: "",
            acumulo: "",
            abrasao: "",
            impacto: "",
            girados: "",
            lubrificada: "",
            bucha_ressalto: "",
            parte_rodante_ld: "",
            parte_rodante_le: "",
            observacoes: ""
        },
        {
            id: -1,
            prefixo: "256",
            data: "25/02/2025",
            horimetro: "785",
            modelo: "XP",
            fabricante: "Ericsson",
            serie: "",
            obra: "",
            numeroOS: "",
            responsavel: "",
            umidade: "",
            acumulo: "",
            abrasao: "",
            impacto: "",
            girados: "",
            lubrificada: "",
            bucha_ressalto: "",
            parte_rodante_ld: "",
            parte_rodante_le: "",
            observacoes: ""
        }
    ]);

    const [listaComponentes, setListaComponentes] = useState([]);
    
    // const atualizarComponente = (index, campo, valor) => {
    //     const novosDados = [...listaComponentes]; // Copia o array
    //     novosDados[index][campo] = valor; // Atualiza o campo específico
    //     setListaComponentes(novosDados); // Atualiza o estado
    // };
    const atualizarComponente = (index, campo, valor) => {
        const novosDados = [...listaComponentes]; // Copia o array
        novosDados[index][campo] = valor; // Atualiza o campo específico
    
        //checagem para calcular porcentagem desgaste
        const { novo, limite } = novosDados[index]; // Pega os valores necessários
        // Verifica se o campo alterado é 'medicao_le' ou 'medicao_ld'
        if (campo === "medicao_le" || campo === "medicao_ld") {
            const desgasteCampo = campo === "medicao_le" ? "desgaste_le" : "desgaste_ld";
    
            if (!valor || isNaN(valor)) {
                // Se o valor for vazio ou inválido, zera o desgaste correspondente
                novosDados[index][desgasteCampo] = "";
            } else if (novo !== undefined && limite !== undefined) {
                // Faz o cálculo normalmente
                novosDados[index][desgasteCampo] = +(((valor - novo) / (limite - novo)) * 100).toFixed(0);
            }
        }
        setListaComponentes(novosDados); // Atualiza o estado
    };


    useEffect(() => {
        props.setInspecaoMaterialRodanteTemp(formulario); // guarda o form caso ele mude de aba, para nao perder dados
        if (formulario.prefixo) {  //guarda o form para salvar junto a solicitação:
            props.novaListaInspecaoMaterialRodante(formulario);
        }
    }, [formulario]);

    useEffect(() => {
        formulario.componentes = listaComponentes;
        props.setComponentesMaterialRodanteTemp(listaComponentes);
    }, [listaComponentes]);
    
    useEffect(() => {
        //carrega registros de inspecao material rodante, vindo do componente Solicitacao
        if(props.listaInspecaoMaterialRodante != null && props.listaInspecaoMaterialRodante != "" && props.listaInspecaoMaterialRodante != undefined){
            console.log("newvolvo ", props.listaInspecaoMaterialRodante);
            setFormulario(props.listaInspecaoMaterialRodante);
            setListaComponentes(props.listaInspecaoMaterialRodante?.componentes);
        }
        //carrega o formulario temporareo, quando o usuario mudar de abas ele se perde, mas essa prop mantem ele:
        else if(props.inspecaoMaterialRodanteTemp != null && props.inspecaoMaterialRodanteTemp != "" && props.inspecaoMaterialRodanteTemp != undefined){
            setFormulario(props.inspecaoMaterialRodanteTemp);
            setListaComponentes(props.inspecaoMaterialRodanteTemp?.componentes);
        }
    }, []);

    //guarda laudo de inspecao para ser salvo pelo componente pai (solicitacao)
    useEffect(() => {
        formulario.componentes = listaComponentes;
        if (formulario.prefixo) {
            props.novaListaInspecaoMaterialRodante(formulario);
        }
        props.setInspecaoMaterialRodanteTemp(formulario)
    }, [formulario]);



    const atualizarFormulario = (campo, valor) => {
        setFormulario({
        ...formulario,     
        [campo]: valor     
        });
    };

    const consultaPrefixo = async () => {
        if(formulario.id != -1){
            let confirmacao = window.confirm("Ao realizar uma nova busca por prefixo, os dados do Laudo atual serão APAGADOS. Deseja continuar?");
            if (!confirmacao){
                return;
            }
        }
        realizaConsulta("/01.001.031", { params: {'PREFIXO_S': prefixoPesquisado}})
        .then(function (response) {

            if(Array.isArray(response.data) && response.data.length === 0){
                toast.warning("Não existem dados para o prefixo pesquisado.");
                return;
            }
            const data = response.data[0];
            setFormulario({
                ...formulario,
                prefixo: prefixoPesquisado,
                tipoobjeto: data.NOME_TIPOOBJ,
                codtipoobjeto: data.IDTIPOOBJ,
                modelo: data.MODELO,
                horimetro: data.HORIMETRO_ATUAL,
                fabricante: data.FABRICANTE,
                serie: data.SERIE,
                obra: data.OBRA,
                responsavel: data.RESPONSAVEL,
                data: data.DATA
            });
            if (data.IDTIPOOBJ && data.CODFAB && data.CODMODELO){
                consultaComponentes(data.IDTIPOOBJ, data.CODFAB, data.CODMODELO);
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    const consultaComponentes = async (idtipoobj, codfab, codmodelo) => {
        axios.get(`https://pelicanoconstrucoes127067.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/01.001.033/1/G/?parameters=IDTIPOOBJ=${idtipoobj};CODFAB=${codfab};CODMODELO=${codmodelo}`
            ,{
                headers: {
                    'Authorization': "Basic " + btoa("fluig" + ":" + "Centrium505050@@")
                }
            }
        )
        .then(function (response) {
            console.log("consultaComponentes: ", response.data);
            setListaComponentes(response.data);
            // setComponentes(response.data);
        }).catch(function (error) {
            console.error(error);
        });
    }


    
    return(
        <Stack ml={mobile ? "0px" : "20px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : ""} p={mobile ? "20px" : "5px"}>
            {/* Conteúdo da Tela */}
            <Typography level="h4">Inspeção de Material Rodante</Typography>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
                <Stack mt={mobile ? "20px" : ""}>
                    <FormLabel>Buscar Prefixo</FormLabel>
                    <Stack flexDirection={mobile ? "column" : "row"}>
                        <Input type="text" value={prefixoPesquisado} disabled={props.desabilitaAprovadores} onChange={(event) => setPrefixoPesquisado(event.target.value)} sx={{ borderTopRightRadius: mobile? "5px":"0", borderBottomRightRadius: mobile? "5px":"0", height: "40px", width: mobile ? "100%" : "212px"}} />
                        <Button onClick={() => consultaPrefixo()} disabled={props.desabilitaAprovadores} size="sm" sx={{ borderTopLeftRadius: mobile? "5px":"0", borderBottomLeftRadius: mobile? "5px":"0" , height: "40px"}}><FaSearch /></Button>
                    </Stack>
                </Stack>
                
                {/* <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Marca</FormLabel>
                    <Input type="text" value={formulario.marca} />
                </Stack> */}
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Tipo</FormLabel>
                    <Input type="text" value={formulario.tipoobjeto}  disabled/>
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Prefixo</FormLabel>
                    <Input type="text" value={formulario.prefixo}  disabled/>
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Modelo</FormLabel>
                    <Input type="text" value={formulario.modelo}  disabled/>
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>Fabricante</FormLabel>
                    <Input type="text" value={formulario.fabricante} disabled/> 
                </Stack>

            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">

                <Stack mt={mobile ? "20px" : ""}> 
                    <FormLabel>Horímetro</FormLabel>
                    <Input type="text" value={formulario.horimetro} disabled/> 
                </Stack>
                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}>
                    <FormLabel>Série</FormLabel>
                    <Input type="text" value={formulario.serie} disabled/> 
                </Stack>

                <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""} width={mobile ? "100%" : "525px"}> 
                    <FormLabel>Obra</FormLabel>
                    <Input type="text" value={formulario.obra} disabled/> 
                </Stack>

                {/* <Stack ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>O.S</FormLabel>
                    <Input type="text" value={formulario.numeroOS} disabled/> 
                </Stack> */}
            </Stack>

            <Typography level="h4" mt="30px">Situação da Máquina no Local de Trabalho</Typography>
            <Stack flexDirection={mobile ? "column" : "row"} mt="10px">
            
                <Stack minWidth={mobile ? "100%" : "250px"}  mt={mobile ? "20px" : ""}> 
                    <FormLabel>Umidade</FormLabel>
                        <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.umidade}  onChange={(event, newValue) => atualizarFormulario("umidade", newValue)}>
                            <Option value="baixo">Baixo</Option>
                            <Option value="moderado">Moderado</Option>
                            <Option value="alto">Alto</Option>
                        </Select>
                </Stack>

                <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>Acúmulo</FormLabel>
                        <Select disabled={props.desabilitaAprovadores} defaultValue="" value={formulario.acumulo} onChange={(event, newValue) => atualizarFormulario("acumulo", newValue)}>
                            <Option value="baixo">Baixo</Option>
                            <Option value="moderado">Moderado</Option>
                            <Option value="alto">Alto</Option>
                        </Select>
                </Stack>

                <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>Abrasão</FormLabel>
                        <Select disabled={props.desabilitaAprovadores} defaultValue="" value={formulario.abrasao} onChange={(event, newValue) => atualizarFormulario("abrasao", newValue)}>
                            <Option value="baixo">Baixo</Option>
                            <Option value="moderado">Moderado</Option>
                            <Option value="alto">Alto</Option>
                        </Select> 
                </Stack>

                <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                    <FormLabel>Impacto</FormLabel>
                        <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.impacto} onChange={(event, newValue) => atualizarFormulario("impacto", newValue)}>
                            <Option value="baixo">Baixo</Option>
                            <Option value="moderado">Moderado</Option>
                            <Option value="alto">Alto</Option>
                        </Select>
                </Stack>
            </Stack>

            <Stack mt="30px">
                <Typography level="h4">Componentes</Typography>
                <Stack mt="30px">
                    { mobile ?
                        <Stack>
                            {listaComponentes.map((item, index) => {
                                return (
                                <div key={index}>
                                    <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                    <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                                        <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Descrição: </Typography>{item.nome}</Typography>
                                    </Stack>
                                    <Typography level="body-sm"><Typography level="title-md">Novo/Original: </Typography>
                                        <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px",backgroundColor: "transparent"}} type="text" value={item.novo} />
                                    </Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Limite / mm: </Typography>
                                        <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.limite} />
                                    </Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Medição L.E: </Typography>
                                        <Input disabled={props.desabilitaAprovadores} style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" 
                                            value={item.medicao_le} onChange={(e) => atualizarComponente(index, "medicao_le", e.target.value)} />
                                    </Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Medição L.D: </Typography>
                                        <Input disabled={props.desabilitaAprovadores} style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" 
                                            value={item.medicao_ld} onChange={(e) => atualizarComponente(index, "medicao_ld", e.target.value)}/>
                                    </Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Desgaste /L.E: </Typography>
                                        <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.desgaste_le} onChange={(e) => atualizarComponente(index, "desgaste_le", e.target.value)} />
                                    </Typography>
                                    <Typography level="body-sm"><Typography level="title-md">Desgaste / L.D: </Typography>
                                        <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.desgaste_ld} onChange={(e) => atualizarComponente(index, "desgaste_ld", e.target.value)}/>
                                    </Typography>
                                    </Stack>
                                </div>
                                );
                            })}
                        </Stack>

                        :
                        
                        <Table noWrap aria-label="basic table">
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Novo / Original</th>
                                    <th>Limite / MM</th>
                                    <th>Medições / L.E</th>
                                    <th>Medições / L.D</th>
                                    <th>Desgaste / L.E(%)</th>
                                    <th>Desgaste / L.D(%)</th>
                                    {/* <th>Ações</th> */}
                                </tr>
                            </thead>
                            <tbody style={{backgroundColor: "#fff"}}>
                                {listaComponentes.map((item, index) =>
                                
                                    <tr key={index}>
                                        <td style={{borderRight: "1px", padding: "0 10px"}}><strong>{item.nome}</strong> </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0", borderRight: "0", padding: "0" }}>
                                            <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px",backgroundColor: "transparent"}} type="text" value={item.novo} />
                                        </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0",borderRight: "0",padding: "0"}}>
                                            <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.limite} />
                                        </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0",borderRight: "0",padding: "0"}}>
                                            <Input disabled={props.desabilitaAprovadores} style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" 
                                                value={item.medicao_le} onChange={(e) => atualizarComponente(index, "medicao_le", e.target.value)} />
                                        </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0",borderRight: "0",padding: "0"}}>
                                            <Input disabled={props.desabilitaAprovadores} style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" 
                                                value={item.medicao_ld} onChange={(e) => atualizarComponente(index, "medicao_ld", e.target.value)}/>
                                        </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0",borderRight: "0",padding: "0"}}>
                                            <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.desgaste_le} onChange={(e) => atualizarComponente(index, "desgaste_le", e.target.value)} />
                                            </td>
                                        <td style={{border:"1px solid var(--TableCell-borderColor)", borderTop: "0",borderRight: "0",padding: "0"}}>
                                            <Input disabled style={{padding: "0 10px", borderRadius: "0", border: "1px", backgroundColor: "transparent"}} type="text" value={item.desgaste_ld} onChange={(e) => atualizarComponente(index, "desgaste_ld", e.target.value)}/>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </Stack>
            </Stack>

            <Stack mt="30px">
                <Typography level="h4">Situação dos Pinos/Buchas</Typography>

                <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                    <Stack minWidth={mobile ? "100%" : "250px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Girados</FormLabel>
                            <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.girados} onChange={(event, newValue) => atualizarFormulario("girados", newValue)}>
                                <Option value="sim">Sim</Option>
                                <Option value="nao">Não</Option>
                            </Select>
                    </Stack>
                    <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Lubrificada</FormLabel>
                            <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.lubrificada} onChange={(event, newValue) => atualizarFormulario("lubrificada", newValue)}>
                                <Option value="sim">Sim</Option>
                                <Option value="nao">Não</Option>
                            </Select>
                    </Stack>
                    <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Bucha com ressalto</FormLabel>
                            <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.bucha_ressalto} onChange={(event, newValue) => atualizarFormulario("bucha_ressalto", newValue)}>
                                <Option value="sim">Sim</Option>
                                <Option value="nao">Não</Option>
                            </Select>
                    </Stack> 
                </Stack>       
            </Stack>


            <Stack mt="30px">
                <Typography level="h4">Condições da Parte Rodante</Typography>

                <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                    <Stack minWidth={mobile ? "100%" : "250px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Lado Direito</FormLabel>
                            <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.parte_rodante_ld} onChange={(event, newValue) => atualizarFormulario("parte_rodante_ld", newValue)}>
                                <Option value="bom">Bom</Option>
                                <Option value="otimo">Ótimo</Option>
                                <Option value="regular">Regular</Option>
                                <Option value="ruim">Ruim</Option>
                            </Select>
                    </Stack>
            
                    <Stack minWidth={mobile ? "100%" : "250px"} ml={mobile ? "0" : "30px"} mt={mobile ? "20px" : ""}> 
                        <FormLabel>Lado Esquerdo</FormLabel>
                            <Select disabled={props.desabilitaAprovadores} defaultValue="NO" value={formulario.parte_rodante_le} onChange={(event, newValue) => atualizarFormulario("parte_rodante_le", newValue)}>
                                <Option value="bom">Bom</Option>
                                <Option value="otimo">Ótimo</Option>
                                <Option value="regular">Regular</Option>
                                <Option value="ruim">Ruim</Option>
                            </Select>
                    </Stack>
                </Stack>
            </Stack>

            <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                <Stack minWidth={mobile ? "100%" : "250px"} mt={mobile ? "20px" : ""}> 
                    <Typography level="h4">Observações</Typography>
                    <Textarea disabled={props.desabilitaAprovadores} value={formulario.observacoes} onChange={(event) => atualizarFormulario("observacoes", event.target.value)} minRows={3} sx={{width: mobile ? "100%" : "600px"}} placeholder="…" />
                </Stack>
            </Stack>

            {/* <Stack flexDirection={"column"} mt="30px">
                <Stack mt={mobile ? "20px" : ""}>
                    <Button onClick={()=> salvarInspecao()} disabled={props.desabilitaAprovadores} sx={{width: mobile ? "100%" : "200px"}}>Salvar</Button>
                </Stack>
            </Stack> */}


            {/* Inspeções Cadastradas
            <Stack mt="30px">
                { mobile ?
                    <Stack>
                        {listaInspecoes.map((item, indice) => {
                            return (
                            <div>
                                <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                                    <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Prefixo: </Typography>{item.prefixo}</Typography>
                                </Stack>
                                <Typography level="body-sm"><Typography level="title-md">Data: </Typography>{item.data}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Modelo: </Typography>{item.modelo}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Fabricante: </Typography>{item.fabricante}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Horímetro: </Typography>{item.horimetro}</Typography>
                                <Stack level="body-sm" mt="20px">
                                    <Stack flexDirection="row" alignItems="center">
                                    <Button onClick={() => null} color="neutral" sx={{ width: "50%", marginRight: "10px" }}>Ver</Button>
                                    </Stack>
                                </Stack>
                                </Stack>
                            </div>
                            );
                        })}
                    </Stack>

                    :
                    
                    <Table hoverRow stripe="even" noWrap aria-label="basic table">
                        <thead>
                            <tr>
                                <th>Prefixo</th>
                                <th>Data</th>
                                <th>Modelo</th>
                                <th>Fabricante</th>
                                <th>Horímetro</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaInspecoes.map((item, index) =>
                                <tr key={index}>
                                    <td style={{padding: "0 10px"}}>{item.prefixo}</td>
                                    <td style={{padding: "0 10px"}}>{item.data}</td>
                                    <td style={{padding: "0 10px"}}>{item.modelo}</td>
                                    <td style={{padding: "0 10px"}}>{item.fabricante}</td>
                                    <td style={{padding: "0 10px"}}>{item.horimetro}</td>
                                    <td style={{ padding: "0 10px", display: "flex", alignItems: "center", flexDirection: "row" }}>
                                    <div className="hover">
                                        <FaRegEdit onClick={() => alert("1")} color="#2974ac" size={22} />
                                    </div>
                                    <div className="hover" style={{ marginLeft: "10px" }}>
                                        <FaRegTrashAlt onClick={() => alert("2")} color="#990000" size={22} />
                                    </div>
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                }
            </Stack>
             */}
        </Stack>

    );

}